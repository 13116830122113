/**
*
* Join Form - Extras select component
* 2 column component with dropdowns for extra selection tools
*
**/
.extras-select {
	@include clearfix-micro();
	margin-bottom: 15px;

	.title {
		@extend %font-primary;
		@include fontSize(20px);
		margin: 0;
		padding: 0;
		padding-bottom: 10px;
		color: $primary_2;
	}

	.read-more {
		@extend %font-secondary;
		@include fontSize(12px);
		color: $primary_2;
		text-decoration: underline;
	}

	.selectors {
		position: relative;

		.and-symbol {
			position: absolute;
			top: 51%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: $neutral_6;
			padding: 10px 20px;

			svg.icon.icon-and-symbol {
				width: 24px;
				height: 24px;
				color: $neutral_2;
			}
		}


		//Extras blocks
		.extras-type-block {
			position: relative;
			background-color: $neutral_6;
			width: 100%;

			//Left column
			&.hospital {
				border-top: 5px solid $primary_1;

				.inner-wrap {
					padding: 20px;
					border-style: solid;
					border-color: $neutral_5;
					border-width: 1px;
					height: 168px;
				}
			}

			//Right column
			&.extras {
				border-top: 5px solid $primary_3;

				.inner-wrap {
					padding: 20px;
					border-style: solid;
					border-color: $neutral_5;
					border-width: 1px;
					height: 168px;
				}
			}

			.field-wrap {
				padding-bottom: 10px;
			}
		}
	}


	//Tertiary row
	.tertiary-info {
		padding: 20px;
		border-style: solid;
		border-color: $neutral_5;
		border-width: 1px;

		.field-wrap {
			padding-bottom: 0;
		}

		.emergency-ambulance-note{
			font-size: 0.75rem;
    		color: #061D45;
			font-weight:700;
		}
	}

	//Desktop
	@media #{$sm} {
		margin-bottom: 30px;

		.read-more {
			@include fontSize(14px);
		}
		.selectors {
			position: relative;
			.extras-type-block {
				width: 50%;
				float: left;

				//Left column
				&.hospital {

					.inner-wrap {
						padding: 30px 40px 20px 20px;
						border-width: 0 1px 0 1px;
					}

					//Use psuedo element to form '&' symbol
					&:after {
						left: 96%;
						top: 50%;
						transform: translateY(-50%);
					}
				}

				//Right column
				&.extras {

					.inner-wrap {
						padding: 30px 20px 20px 40px;
						border-width: 0 1px 0 1px;
					}
				}
			}
			.and-symbol {
				top: 92px;
			}
		}

		&.no-emergency-ambulance {
			.extras-type-block {
				&.hospital,
				&.extras {
					.inner-wrap {
						border-width: 0 1px 1px 1px;
					}
				}
			}
		}
	}
}
