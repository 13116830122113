/**
*
* Article Image Module
*
**/
.article-module-article-image {
    .img-wrap {
        width: 100%;
        border-radius: 20px;
        overflow: hidden;
    }

    picture,
    img {
        display: block;
        width: 100%;
    }
}
