/*
Re-usable semantic statement option

mode: default / thin
 */

@mixin SSOption($size: 'half', $mode: 'default') {
    @include fontSize(24px);
    @include lineHeight(30px);
    font-family: $fontPrimary;
    display: flex;
    flex: 0 0 50%;
    justify-content: center;
    align-items: center;
    color: $primary_2;
    color: $primary_2;
    text-decoration: none;
    
    @if($mode == 'default') {
        border-radius: 5px;
        height: 60px;
        padding: 0 5px;
        margin-bottom: 10px;    
        
    }
    @if($mode == 'thin') {
        border-radius: 5px;
        height: 44px;
        padding: 0 5px;
        margin-bottom: 10px;
    }
    

    .inner {
        @include animate-custom(#{'background'}, 250ms);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        background: #F0F0F0;
        border-radius: 5px;
        padding: 0 5px;
    }


    &:hover {
        .inner {
            background: darken(#F0F0F0, 5%);
        }
    }

    @if ($size == 'quarter') {
        flex: 0 0 25%;
    }

    @if ($size == 'third') {
        flex: 0 0 33.3333%;
    }

    @if ($size == 'auto') {
        flex: 1 1 0;
    }

    @if ($size == 'full') {
        flex: 1 1 100%;
    }

    //Tablet
    @if($mode == 'default') {
        @media #{"only screen and (min-width : "$screen-sm")"} {
            height: 115px;
        }

        /*
        992px +
         */
        @media #{"only screen and (min-width : "$screen-md")"} {
            .inner {
                padding: 0 10px;
            }
        }
    }
    @if($mode == 'thin') {
        @media #{"only screen and (min-width : "$screen-sm")"} {
            height: 60px;
        }

        /*
        992px +
         */
        @media #{"only screen and (min-width : "$screen-md")"} {
            height: 78px;
            .inner {
                padding: 0 10px;
            }
        }
    }
}

@mixin btnReset(){
    background: none;
    border: none;
    margin: 0;
    padding: 0;

    &:focus,
    &:active {
        outline: none;
    }
}

/*
Health Hub bordered button style
 */
@mixin btnBordered($defaultColour: $primary_2, $hoverBgColour: white){
    @include fontSize(20px);
    @include lineHeight(27px);
    @extend %font-primary;
    @include animate-custom(#{'color, background-color'});
    display: flex;
    align-items: center;
    width: 100%;
    padding: 7px 20px 11px 20px;
    color: $defaultColour;
    border: solid 2px $defaultColour;
    border-radius: 5px;

    .icon {
        margin-left: auto;
    }

    &:hover {
        background-color: $defaultColour;
        color: $hoverBgColour;
    }
}

@mixin btnFilled($defaultColour: $primary_2, $hoverBgColour: white){
    @include fontSize(20px);
    @include lineHeight(27px);
    @extend %font-primary;
    @include animate-custom(#{'color, background-color'});
    display: flex;
    align-items: center;
    width: 100%;
    padding: 7px 20px 11px 20px;
    background-color: $defaultColour;
    color: white;
    border: solid 2px $defaultColour;
    border-radius: 5px;

    .icon {
        margin-left: auto;
    }

    &:hover {
        background-color: $hoverBgColour;
        color: $defaultColour;
    }
}