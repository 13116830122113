/**
*
* Small, general content block - h1 and p tag only, used between modules
*
**/

.general-content {
    margin-bottom: 40px;
    text-align: left;

    h1 {
        @extend %font-primary;
        @include fontSizeFluid(26px);
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
        color: $primary_2;
    }

    h2 {
        @extend %font-primary;
        @include fontSizeFluid(18px);
        margin-top: 25px;
        margin-bottom: 25px;
        padding: 0;
        color: $neutral_2;
    }

    p {
        line-height: 2;
    }


    ul, ol {
        padding: 0 20px;

        li {
            padding: 6px 0;
            border-top: 1px solid $neutral_4;
            width: 90%;

            &:first-child {
                border-top: none;
            }

            ul, ol {
                li {
                    width: 100%;
                    border-top: none;
                    border-bottom: none;
                }
            }
        }
    }

    ul {
        > li {
            border-top: 1px solid $neutral_4;
            list-style: none;
            margin-left: 20px;

            &:first-child {
                border-top: none;
            }

            &:before {
                content: "• ";
                color: $neutral_1;
                padding-right: 5px;
                margin-left: -16px;
            }


            ul {
                > li {
                    width: 100%;
                    border-top: none;
                    border-bottom: none;
                }
            }
        }
    }

    ol {
        li {
        }
    }

    //Desktop
    @media #{$sm} {
        //By default, module is center aligned for desktop
        text-align: center;

        h1 {
            @include fontSizeFluid(32px);
        }


        h2 {
            @include fontSizeFluid(24px);
            margin-top: 50px;
            margin-bottom: 35px;
        }

        p {
            @include fontSizeFluid(16px);
        }
    }
}

/**
 * Premium Content Overrides for centered content styling.
 * BB: I don't like doing this but it needs to be done for certain content types.
 */
.premium-content {
    @media #{$sm} {
        .general-content {
            ul, ol {
                li {
                    width: 100%;
                }
            }
            // 1: Ensure list indicator sticks to content.
            ol {
                color: $neutral_2;
                list-style-position: inside; // 1
                padding-left: 20px; // 1
            }

            ul {
                li:before {
                    margin-left: -46px;
                }
            }
        }
    }
}



/**
*
* On Two column basic content page, text is left aligned for all viewports
*
**/
.right-col {
    .general-content {
        text-align: left;
        //Desktop
        @media #{$sm} {
            text-align: left;
        }
    }
}

/*.sitemap {
    a {
        @extend %font-secondary;
        color: $neutral_2;
    }

    a:hover {
        color: $primary_2;
        text-decoration: underline;
    }
}*/
