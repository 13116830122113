.breadcrumbs {
    padding-bottom: 20px;

    svg {
        color: $neutral_3;
        font-size: 0.8rem;
    }

    a {
        color: $neutral_3;
    }
}
