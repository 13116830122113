/**
tldr; The button styles on mobile are intentionally left slightly broken from a style
      point of view as the font does not align correctly in mobile browsers.

Dear Developer,

Grab a log and sit by the fire with me as I take you on a journey,
this journey is filled with excitement and harrowing tales of
heroism and heartache.

It all starts on a bright sunny day in the month of April when the
birds are chirping and the sitting by the fonts drinking their
fair share of watery goodness.

One day a the little birds noticed that on their favourite website
the button text would not vertically align correctly in the mobile
browsers on their portable computing devices.

Because this only applied to a single viewport that applied to their
portable computing devices they decided that it was OK to have the
style not looking optimal in this viewport for their devices.

The birdies then wrote a note to their loving peers to let
them know that this is the case.

And there was much rejoicing.

YAY...
*/

form button,
input[type='submit'],
.sg-btn,
.sg-btn-large,
.sg-btn-small {
    @extend %font-primary;
    @include fontSize(20px);
    @include lineHeight(20px);
    position: relative;
    display: inline-block;
    background-color: $primary_4;
    color: $neutral_white;
    text-align: center;
    border-radius: 5px;
    border: 2px solid $primary_4;

    svg.icon {
        height: 0.75em;
        width: 0.75em;
        font-size: inherit;
    }
    svg.icon:nth-child(1) {
        fill: currentColor;
        margin-right: 7px;
    }

    svg.icon:nth-last-child(1) {
        fill: currentColor;
        margin-left: 7px;
    }

    transition: background-color 250ms linear, border-color 250ms linear, color 250ms linear;

    &:visited {
        background-color: $primary_4;
        border-color: $primary_4;
        color: $neutral_white;
        outline: none;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($primary_4, 10);
        border-color: darken($primary_4, 10);
        color: $neutral_white;
        outline: none;
    }

    &:active {
        background-color: darken($primary_4, 20);
        border-color: darken($primary_4, 20);
        color: $neutral_white;
    }

    &:disabled {
        background-color: $neutral_4;
        border-color: $neutral_4;
        color: $neutral_white;
        outline: none;
    }
}

.sg-btn-primary3 {
    background-color: $primary_3;
    color: $neutral_white;
    border: 2px solid $primary_3;

    &:visited {
        background-color: $primary_3;
        border-color: $primary_3;
        color: $neutral_white;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($primary_3, 10);
        border-color: darken($primary_3, 10);
        color: $neutral_white;
    }

    &:active {
        background-color: darken($primary_3, 20);
        border-color: darken($primary_3, 20);
        color: $neutral_white;
    }
}

.sg-btn-secondary {
    background-color: transparent;
    color: $primary_4;
    border: 2px solid $primary_4;

    &:visited {
        background-color: transparent;
        border-color: $primary_4;
        color: $primary_4;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
        border-color: darken($primary_4, 10);
        color: darken($primary_4, 10);
    }

    &:active {
        background-color: transparent;
        border-color: darken($primary_4, 20);
        color: darken($primary_4, 20);
    }

    &:disabled {
        background-color: transparent;
        border-color: $neutral_4;
        color: $neutral_4;
    }
}

.sg-btn-secondary-white {
    background-color: white;
    color: $primary_4;
    border: 2px solid white;

    &:visited {
        background-color: white;
        color: $primary_4;
        border-color: white;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: $neutral_4;
        color: darken($primary_4, 10);
        border-color: $neutral_4;
    }

    &:active {
        background-color: white;
        color: darken($primary_4, 20);
        border-color: white;
    }

    &:disabled {
        background-color: white;
        color: $neutral_4;
        border-color: white;
    }
}



.sg-btn-large {
    padding: 18px 20px;
    padding-top: 20px; /** Correction for Mobile **/
    padding-bottom: 16px; /** Correction for Mobile **/
}

input[type='submit'],
form button,
.sg-btn {
    padding: 18px 20px;
    padding-top: 20px; /** Correction for Mobile **/
    padding-bottom: 16px; /** Correction for Mobile **/
    @include fontSize(14px);
    @include lineHeight(14px);
}

.sg-btn-small {
    padding: 13px 8px;
    padding-top: 15px; /** Correction for Mobile **/
    padding-bottom: 11px; /** Correction for Mobile **/
    @include fontSize(14px);
    @include lineHeight(14px);

    svg.icon:nth-child(1) {
        fill: currentColor;
        margin-right: 2px;
    }

    svg.icon:nth-last-child(1) {
        fill: currentColor;
        margin-left: 2px;
    }
}

.sg-btn-solid {
    border-radius: 0;
    border: 0;
    background: transparent;
    color: $primary_4;
    &:visited {
        background: transparent;
        color: $primary_4;
    }
    &:hover,
    &:focus,
    &:active {
        background: transparent;
        color: darken($primary_4, 10);
    }

    &:active {
        background: transparent;
        color: darken($primary_4, 20);
    }

    &:disabled {
        background: transparent;
        color: $neutral_4;
    }
}

.sg-btn-solid-transparent {
    border-radius: 0;
    border: 0;
    background: transparent;
    color: $primary_4;
    &:hover,
    &:focus,
    &:active {
        background: transparent;
        color: darken($primary_4, 10);
    }

    &:active {
        background: transparent;
        color: darken($primary_4, 20);
    }

    &:disabled {
        background: transparent;
        color: $neutral_4;
    }
}

.sg-btn-text {
    @include fontSize(10px);
    @include lineHeight(16px);
    text-transform: uppercase;
    color: $primary_4;
    font-weight: bold;

    &:hover,
    &:focus,
    &:active {
        color: darken($primary_4, 10);
    }

    &:active {
        color: darken($primary_4, 20);
    }

    &:disabled {
        color: $neutral_4;
    }
}

.sg-btn-members {
    background-color: $primary_2;
    border: 2px solid $primary_2;
    color: white;

    &:visited {
        background-color: $primary_2;
        border-color: $primary_2;
        color: white;
        outline: none;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($primary_2, 10);
        border-color: darken($primary_2, 10);
        color: white;
        outline: none;
    }

    &:active {
        background-color: darken($primary_2, 10);
        border-color: darken($primary_2, 10);
        color: white;
    }

    &:disabled {
        background-color: $neutral_4;
        border-color: $neutral_4;
        color: $neutral_white;
        outline: none;
    }
}

.sg-btn-phone {
    background-color: transparent;
    color: $primary_2;
    border-color: $primary_2;

    &:visited {
        background-color: transparent;
        border-color: $primary_2;
        color: $primary_2;
        outline: none;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
        border-color: darken($primary_2, 10);
        color: darken($primary_2, 10);
        outline: none;
    }

    &:active {
        background-color: transparent;
        border-color: darken($primary_2, 20);
        color: darken($primary_2, 20);
    }

    &:disabled {
        background-color: transparent;
        border-color: $neutral_4;
        color: $neutral_4;
    }
}

.sg-btn-quote {
    background-color: $primary_3;
    color: $neutral_white;
    border-color: $primary_3;

    &:visited {
        background-color: $primary_3;
        border-color: $primary_3;
        color: $neutral_white;
        outline: none;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($primary_3, 10);
        border-color: darken($primary_3, 10);
        color: $neutral_white;
        outline: none;
    }

    &:active {
        background-color: darken($primary_3, 20);
        border-color: darken($primary_3, 20);
        color: $neutral_white;
    }

    &:disabled {
        background-color: $neutral_4;
        border-color: $neutral_4;
        color: $neutral_white;
        outline: none;
    }
}

.sg-btn-white {
    background-color: $neutral_white;
    border-color: $neutral_white;
    color: $primary_2;

    &:visited {
        background-color: $neutral_white;
        border-color: $neutral_white;
        color: $primary_2;
        outline: none;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($neutral_white, 10);
        border-color: darken($neutral_white, 10);
        color: $primary_2;
        outline: none;
    }

    &:active {
        background-color: darken($neutral_white, 20);
        border-color: darken($neutral_white, 20);
        color: $primary_2;
    }

    &:disabled {
        background-color: $neutral_4;
        border-color: $neutral_4;
        color: $neutral_white;
        outline: none;
    }
}

.sg-btn-primary5 {
    background-color: $primary_5;
    color: $primary_2;
    border: 2px solid $primary_5;

    &:visited {
        background-color: $primary_5;
        border-color: $primary_5;
        color: $primary_2;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($primary_5, 10);
        border-color: darken($primary_5, 10);
        color: $primary_2;
    }

    &:active {
        background-color: darken($primary_5, 20);
        border-color: darken($primary_5, 20);
        color: $primary_2;
    }
}


.sg-btn-primary4 {
    background-color: $primary_4;
    color: $neutral_white;
    border: 2px solid $primary_4;

    &:visited {
        background-color: $primary_4;
        border-color: $primary_4;
        color: $neutral_white;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($primary_4, 10);
        border-color: darken($primary_4, 10);
        color: $neutral_white;
    }

    &:active {
        background-color: darken($primary_4, 20);
        border-color: darken($primary_4, 20);
        color: $neutral_white;
    }
}


.sg-btn-primary3 {
    background-color: $primary_3;
    color: $primary_2;
    border: 2px solid $primary_3;

    &:visited {
        background-color: $primary_3;
        border-color: $primary_3;
        color: $primary_2;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($primary_3, 10);
        border-color: darken($primary_3, 10);
        color: $primary_2;
    }

    &:active {
        background-color: darken($primary_3, 20);
        border-color: darken($primary_3, 20);
        color: $primary_2;
    }
}

.sg-btn-primary2 {
    background-color: $primary_2;
    color: $neutral_white;
    border: 2px solid $neutral_white;

    &:visited {
        background-color: $primary_3;
        border-color: $primary_3;
        color: $neutral_white;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($primary_3, 10);
        border-color: darken($primary_3, 10);
        color: $neutral_white;
    }

    &:active {
        background-color: darken($primary_3, 20);
        border-color: darken($primary_3, 20);
        color: $neutral_white;
    }
}

.sg-btn-primary1 {
    background-color: $primary_1;
    color: $neutral_white;
    border: 2px solid $primary_1;

    &:visited {
        background-color: $primary_1;
        border-color: $primary_1;
        color: $neutral_white;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($primary_1, 10);
        border-color: darken($primary_1, 10);
        color: $neutral_white;
    }

    &:active {
        background-color: darken($primary_1, 20);
        border-color: darken($primary_1, 20);
        color: $neutral_white;
    }
}
.sg-btn-full-width {
    width: 100%;
}

.sg-btn-wide {
    padding: 18px 44px;
}

@media #{$sm} {
    .sg-btn-large {
        padding: 25px 20px;
    }

    input[type='submit'],
    form button,
    .sg-btn {
        padding: 18px 20px;
        @include fontSize(20px);
        @include lineHeight(20px);
    }

    .sg-btn-small {
        padding: 13px 8px;
    }

    .sg-btn-text {
        @include fontSize(12px);
        @include lineHeight(24px);
    }

    .sg-btn-wide {
        padding: 22px 54px;
    }
}