// Also see src/compontents/_icon-cta.scss
.module-icon-cta-list {
    .icon-cta {
        margin-bottom: 66px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media #{$xs} {
        .icon-ctas {
            display: flex;
        }

        .icon-cta {
            width: 50%;

            &:first-child {
                margin-right: 15px;
            }

            &:last-child {
                margin-left: 15px;
            }
        }
    }

}