.module-member-stories {
    width: 100%;
    overflow: hidden;

    .title {
        text-align: center;
    }

    .description {
        margin-bottom: 24px;
        text-align: center;
    }

    .member-stories {
        overflow: hidden;
        position: relative;

        .tier-pill {
            position: relative;
            bottom: 4px;
            margin-left: 4px;
        }

        &:after {
            content: ' ';
            position: absolute;
            width: 20px;
            top: 0;
            bottom: 0;
            right: 0;

            background: linear-gradient(270deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
            z-index: 2;
        }

        .swiper-container {
            width: calc(100% - 40px);
            margin: 0;
            overflow: visible;
        }
    }

    .image-height-ratio {
        @include aspect-ratio(962, 440);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none;
        background: none;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;
        color: $neutral_white;
        text-align: center;
        svg.icon {
            height: 36px;
            width: 12px;
        }
    }
    .swiper-button-prev {
        left: 10px;
    }

    .swiper-button-next {
        right: 10px;
    }

    .member-story {
        background: $neutral_6;
        height: auto;
        text-align: center;
        .member-image {
            position: relative;
        }
        .image-block {
            &:after {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                background: linear-gradient(180deg, rgba(0,0,0,0) 20%, rgba(0,0,0,0.5) 75.13%);
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        .testimonial {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 12px;
            color: $neutral_white;
            text-align: left;

            .member-name {
                @extend %font-primary;
                @include fontSize(18px);
                @include lineHeight(22px);
            }
            .member-quote {
                @include fontSize(14px);
                @include lineHeight(21px);
                margin-top: 4px;
            }
        }

        .member-info {
            padding: 14px 14px 94px;

            .health-cover {
                @include fontSize(14px);
                @include lineHeight(21px);
            }
            .insurance-type {
                margin-top: 4px;
                color: $primary_2;

                @extend %font-primary;
                @include fontSize(18px);
                @include lineHeight(22px);
            }
            .quote-button {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 26px;
            }
            .full-story-link {
                // margin-top: 13px;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding-bottom: 20px;
                @include fontSize(10px);
                @include lineHeight(16px);
                font-weight: bold;
                color: $primary_2;
                text-transform: uppercase;

                a {
                    color: $primary_2;
                }
            }
        }
    }

    /*
    Primary 4 brand overrides
    */
    .sg-btn.bg-primary2 {
        background-color: $primary-2;
        border-color: $primary-2;

        &:hover,
        &:focus,
        &:active {
            background-color: darken($primary-2, 10);
            border-color: darken($primary-2, 10);
        }
    }

    /*
    Primary 4 brand overrides
     */
    .sg-btn.bg-primary4 {
        background-color: $primary-4;
        border-color: $primary-4;

        &:hover,
        &:focus,
        &:active {
            background-color: darken($primary-4, 10);
            border-color: darken($primary-4, 10);
        }
    }

    @media #{$sm} {
        .member-stories {
            &:after {
                display: none;
            }

            .swiper-button-prev {
                display: block;
            }

            .swiper-button-next {
                display: block;
            }

            .swiper-container {
                width: auto;

                .member-story {
                    max-width: 468px;
                }
            }
        }

        .member-story {

            .testimonial {
                padding: 20px 70px 24px 20px;

                .member-name {
                    @include fontSize(26px);
                    @include lineHeight(32px);
                }
                .member-quote {
                    @include fontSize(16px);
                    @include lineHeight(24px);
                    margin-top: 0;
                }
            }

            .member-info {
                padding: 22px 22px 132px;
                .insurance-type {
                    margin-top: 10px;
                    @include fontSize(26px);
                    @include lineHeight(32px);

                }
                .quote-button {
                    bottom: 41px;
                }
                .full-story-link {
                    // margin-top: 43px;
                    padding-bottom: 24px;
                    @include fontSize(12px);
                    @include lineHeight(24px);
                    text-transform: uppercase;
                }
            }
        }
    }
}
