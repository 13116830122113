.module-benefits-tiles {
    margin: 0;
    margin: 40px 0;
    background: #ffffff;
    /*padding-top: 40px;*/ // No paddings on the modules

    .flex-wrap {
        display: flex;
        flex-wrap: wrap;
    }

    .tile {
        text-align: center;
        flex: 1 1 100%;


        &:only-child {
            .text {
                @include fontSize(20px);
                @include lineHeight(27px);
            }
        }

        .tile-wrap {
            padding: 0 25px;
            padding-bottom: 40px;
        }

        &:last-child {
            .tile-wrap {
                padding-bottom: 0;
            }
        }
    }

    .icon-wrap {
        margin-bottom: 10px;

        .icon {
            width: 60px;
            height: 45px;
        }
    }

    .text {
        @include fontSize(16px);
        @include lineHeight(24px);
        font-family: $fontSecondary;
        font-weight: 600;
        color: $primary_2;
    }
    //Tablet
    @media #{"only screen and (min-width: "$screen-sm")"} {

        .tile {
            flex: 1 1 auto;

            &:first-child:nth-last-child(1),
            &:first-child:nth-last-child(1) ~ .tile {
                flex: 1 0 100%;
            }

            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ .tile {
                flex: 1 0 50%;
            }
            /** If the item has 3 items then we want to style this at 33% for all items. */
            &:first-child:nth-last-child(3),
            &:first-child:nth-last-child(3) ~ .tile {
                flex: 1 0 33%;
            }
            /** If the item has 4 items then we want to style this at 50% for all items. */
            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ .tile {
                flex: 1 0 50%;

                // Last row no bottom padding.
                &:nth-child(n+3) {
                    .tile-wrap {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
    //Desktop
    @media #{$sm} {
        margin: 64px 0;

        .tile {
            // No need for the padding on the bottom as these are always on one row.
            .tile-wrap {
                padding: 0 20px;
                padding-bottom: 0;
            }
            /** If the item has 3 items then we want to style this at 33% for all items. */
            &:first-child:nth-last-child(3),
            &:first-child:nth-last-child(3) ~ .tile {
                flex: 0 1 33%;
            }
            /** If the item has 4 items then we want to style this at 50% for all items. */
            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ .tile {
                flex: 1 0 25%;
            }
            // padding: 0 20px 40px 20px;
            &:only-child {
                .text {
                    @include fontSize(25px);
                    @include lineHeight(35px);
                }
            }
        }

        .icon-wrap {
            margin-bottom: 30px;

            .icon {
                height: 70px;
                width: 100%;
            }
        }
    }
}
