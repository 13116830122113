/**
*
* Common accordion content
*
**/
.accordion-content {

	.accordion-item {
		margin-bottom: 1px;
	}

	.header {
		background-color: $neutral_4;
		height: 40px;
		padding-left: 20px;

		p {
			@extend %font-primary;
			@include fontSize(14px);
			display: inline-block;
			margin: 0;
			line-height: 40px;
			color: $neutral_2;
		}
	}

	.trigger {
		position: relative;
		display: block;
		float: right;
		width: 45px;
		height: 100%;
		border-left: 1px solid $neutral_5;

		svg.icon {
			@include centre('true','true');
			display: block;
			position: absolute;
			fill: $neutral_2;
			width: 16px;
			height: 16px;
		}

		svg.icon-opened {
			display: none;
		}


		&.collapsed {
			svg.icon-closed {
				display: none;
			}

			svg.icon-opened {
				display: block;
			}
		}
	}

	.content {
		@extend %font-secondary;
		width: 100%;
		color: $neutral_2;
		margin-bottom: 10px;

		a {
			color: $primary_2;
			text-decoration: underline;

			&:hover {
				color: $primary_4;
			}
		}
	}

	//General text content
	.content-wrap {
		width: 100%;
		padding: 20px;
		border: 1px solid $neutral_5;
		border-top: 0;

		p {
			@include fontSize(16px);
		}
	}

	//Table content
	table {
		@include fontSize(16px);
		width: 100%;

		td {
			width: 100%;
			padding: 10px;
			vertical-align: center;
			border: 1px solid $neutral_5;
		}

		tr:first-child {
			td {
				border-top: none;
			}
		}

		.label {
			width: 70%;

		}

		.value {
			width: 30%;
			text-align: center;
		}
	}

	//Desktop
	@media #{$sm} {

		.header {
			height: 60px;

			p {
				@include fontSize(20px);
				line-height: 60px;
			}
		}

		.trigger {
			width: 103px;
		}

		//Table content
		table {
			td {
				padding: 20px;
			}
		}
	}
}
