/**
*
* Coloured Text module
*
**/
.module-coloured-text-block {

    h2 {
        margin-bottom: 11px;
        @include fontSize(36px);
        @include lineHeight(48px);
    }
    /* BB - I have removed this because its different to the styles defined in the design system
                  - This cannot be the case as it then conflicts with other modules sitting beside it.
    .body-copy {
        @include fontSize(14px);
        @include lineHeight(21px);
        color: $primary_2;
    }
    */

    h3 {
        @include fontSize(24px);
        @include lineHeight(34px);
    }
}
