﻿$map-height: 40rem;
$search-height: 10rem;
$filter-height: 2.9rem;
$list-height: $map-height - $search-height - $filter-height;

%directions {
    @include fontSize(12px);
    font-weight: 600;
    color: $primary_4;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: $primary_4;
    }
}

@keyframes sk-scaleout {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1.0);
        opacity: 0;
    }
}

.provider-network {
    position: relative;

    .map-search__address-form {
        @include clearfix();

        border: 1px solid $border-colour;
        padding: 40px 10px;
        padding-top: 20px;

        h2 {
            margin: 0 10px;
            margin-bottom: 20px;
            @include fontSize(30px);

            @media #{$sm} {
                @include fontSize(24px);
            }

            @media #{$md} {
                @include fontSize(30px);
            }
        }

        .search-box {
            @include clearfix-micro();
        }

        .map-search__button {
            margin-bottom: 0;
            margin-top: 10px;
        }

        .left, .right {
            display: block;
            width: 100%;
        }

        @media #{$sm} {
            .map-search__button {
                margin-top: 0;
                float: initial;
                padding: 13px 24px;
            }
        }
    }
    .filter {
        @include clearfix();
        border: 1px solid $border-colour;
        border-top: 0;
        padding: 20px;

        text-align: left;
        .left, .right {
            float: left;
            width: 100%;
        }

        @media #{$sm} {
            .left {
                width: 25%;
            }
            .right {
                width: 75%;
            }
        }

        label {
            text-align: left;
            text-transform: none;
            @include fontSize(18px);
            margin-top: 15px;
        }
    }
    .google-map {
        position: relative;
        width: 100%;
        height: 0;
        min-height: 340px;
        padding-top: 56.25%;
        -webkit-user-select: none;
        -moz-user-select: none;
        border-left: 1px solid $border-colour;
        border-right: 1px solid $border-colour;

        .map {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
        }

    }
    .google-map__loading {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($neutral_black, 0.8);
        z-index: 20;
        color: #FFF;
        text-align: center;
        line-height: 20em;
        opacity: 0;
        visibility: hidden;
        transition: opacity .4s, visibility .4s;

        &.is-visible {
            opacity: 1;
            visibility: visible;
        }

        .map-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            margin: -2em 0 0 -1em;
        }

        .google-map__loading-label {
            position: absolute;
            top: 50%;
            left: 0;
            padding-top: 2.25em;
            font-size: .75em;
            width: 100%;
            text-align: center;
            transform: translateY(-50%);
        }
    }
    .google-map__info-window {
        @extend %font-primary;
        padding: .5em;
        max-width: 16em;

        h3 {
            font-size: 1.3em;
            margin: 0.3em 0;
            line-height: 1.4em;
        }

        p {
            font-size: 1em;
            margin-bottom: .5em;

            &:last-child {
                margin-bottom: 0;
            }
        }

        span {
            margin: 0.5em 0;
            display: block;

            svg.icon {
                color: $primary_1;
                height: 0.8em;
                width: 0.8em;
                margin-right: 0.5em;
                vertical-align: middle;
            }
        }

        .directions a {
            @extend %directions;
        }
    }
    .location-results {
        p {
            text-align: left;
            margin-bottom: 10px;
        }
        .state,
        .item {
            @include font-secondary();
            padding: 20px 30px;
            text-align: left;

            border: 1px solid $border-colour;
            border-top: 0;
            color: $neutral_3;

            .directions {
                a {
                    color: $primary_4;
                    line-height: 1.4;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                        span {
                            text-decoration: underline;
                        }
                    }
                }

                .text {
                    display: inline-block;
                }
                .icon {
                    position: relative;
                    display: inline-block;
                    top: 2px;
                }
            }

            a {
                color: $neutral_3;
            }

            h4 {
                margin: 0;
                padding-bottom: 10px;
                @include font-secondary();
                @include fontSize(18px);
                text-align: left;
                a {
                    color: $neutral_2;
                }
            }
        }

        .state {
            border-top: 1px solid $border-colour;
            @include fontSize(20px);
        }
        .item {

        }
    }
    .map-loader {
        width: 2em;
        height: 2em;

        .b1, .b2 {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: $border-colour;
            opacity: 0.6;
            position: absolute;
            top: 0;
            left: 0;
            animation: sk-bounce 2.0s infinite ease-in-out;
        }

        .b2 {
            animation-delay: -1.0s;
        }

        @keyframes sk-bounce {
            0%, 100% {
                transform: scale(0.0);
            }

            50% {
                transform: scale(1.0);
            }
        }
    }
    .show-more {
        display: block;
        border: 1px solid $border-colour;
        background: transparent;
        border-top: 0;
        padding: 10px;
        width: 100%;
        @include font-primary();
        @include fontSize(24px);
    }
}
