.upper-section {
    background: $neutral_6;
    border: 0;
    text-align: center;
    padding: 50px 15px;
    
    .us-textbox {
        margin:0 auto;
    }
    
    @media #{sm} {
        .us-textbox {
            width: auto;
        }
    }
}