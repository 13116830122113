.module-video-teaser {
    background-color: brown;


    .video-wrapper {
        height: 0;
        overflow: hidden;
        padding-top: 591.44px / 1127.34px * 100%;
        background: white;
        position: relative;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .text-block {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            h1, p {
                color: white;
            }

            @each $class, $colour in $brandColoursMap {
                &.overlay-#{$class} {
                    background-color: $colour;
                    opacity: 0.3;
                }
            }

            /*&.overlay {
                background-color: rgba(255, 0, 0, 0.3);
            }*/
        }
    }
}
