$module-feature-max-height: 500px;

.module-feature {
    position: relative;
    margin: 0;
    height: 300px;

    &.reduce-height {
        height: 200px;
        .text-block {
            display: none;
        }
    }

    .text-block {
        position: absolute;
        float: left;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        .text {
            color: $neutral_white;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            font-weight: 400;
            font-family: 'Open Sans', sans-serif;
        }
    }

    .image-block {
        display: block;
        width: 100%;
        height: 100%;
        max-height: $module-feature-max-height;
        overflow: hidden;
        position: relative;

        picture {
            display: block;
            width: 100%;
        }

        img {
            @include centre('true', 'false', 'absolute');
            width: 102%; //Not an error - image aspect ratio doesn't match new design dimensions
        }

        &.darken:after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: black;
        }
        /*
        CMS controlled darkening options
         */
        $darkenSteps: 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;

        @each $step in $darkenSteps {
            &.darken-#{$step} {
                &:after {
                    opacity: $step / 100;
                }
            }
        }
    }

    .caption {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .copy {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 550px;
            text-align: center;
            width: 100%;
            padding: 0 20px;
        }

        .subtitle,
        .intro-title {
            @extend %font-primary;
            font-size: 16px;
            line-height: 18px;
            color: $neutral_white;
        }

        .title {
            color: $neutral_white;
            font-size: 35px;
            line-height: 40px;
            margin: 0;
            text-align: center;
        }

        .btn-video-play {
            svg.icon {
                margin-top: 14px;
                width: 58px;
                height: 58px;
                fill: $neutral_white;
            }
        }
    }

    .video {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: 10000;
        background: rgba(0,0,0,0.85);
        padding: 0 12px;

        .video-player-container {
            @include aspect-ratio(1920, 1080);
            width: 100%;
            height: auto;
            top: 50%;
            transform: translateY(-50%);
        }

        .video-player {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;

            iframe {
                width: 100%;
                height: 100%;
            }
        }

        .btn-close {
            position: fixed;
            top: 10px;
            right: 10px;

            svg.icon {
                width: 24px;
                height: 24px;
                color: $neutral_white;
            }
        }
    }
    /*Tablet*/
    @media #{"only screen and (min-width : "$screen-sm")"} {
        height: 429px;

        &.reduce-height {
            height: 340px;
            .text-block {
                display: none;
            }
        }

        .video {
            padding: 0 140px;

            .btn-close {
                top: 100px;
                right: 70px;

                svg.icon {
                    width: 48px;
                    height: 48px;
                }
            }
        }

        .caption {
            .subtitle,
            .intro-title {
                display: block;
                font-size: 20px;
                line-height: 30px;
            }
        }
    }

    @media #{$md} {
        .caption {
            /*max-width: 550px;
            margin: auto;*/

            .title {
                font-size: 52px;
                line-height: 60px;
            }
        }
    }
}
