/**********************************
GLOBAL ANIMATION CLASSES
**********************************/
/**
*
* Global animation classes
*
**/

//VARS
$defaultAnimationTime: 250ms;


@mixin animate-opacity($animationTime: $defaultAnimationTime) {
    transition: opacity  $animationTime linear;
}

@mixin animate-background($animationTime: $defaultAnimationTime) {
    transition: background $animationTime linear;
}

/*
@include animate-custom(#{'border, color'}, 1500ms);
 */
@mixin animate-custom($properties, $animationTime: $defaultAnimationTime, $delay: 0ms) {
    transition-property: $properties;
    transition-duration: $animationTime;
    transition-delay:  $delay;
}


/**********************************
MISC KEYFRAME ANIMATIONS
**********************************/

//Slide element down
@keyframes slideDown{
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes skydiveFall{
    0% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(110%);
    }
    51% {
        transform: translateY(-110%);
    }

    100% {
        transform: translateY(0%);
    }
}
