/** Default Select Styles **/
.bootstrap-select,
.bootstrap-select.btn-group {
    width: 100%;
    position: relative;
    scroll-margin-top: 50px;
    
    select {
        position: absolute;
        top: -10000px;
        left: -10000px;
    }

    .dropdown-header {
        @extend %font-secondary;
        @include fontSize(11px);
        @include lineHeight(15px);
        font-weight: 600;
        color: $lqt-colour-quote-panel-labels;
        text-transform: uppercase;
    }

    //Trigger
    .dropdown-toggle {
        background-color: white;
        padding: 0px 20px 0px 20px;
        margin-bottom: 0;
        border: 1px solid $neutral_5;
        height: 50px;
        border-radius: 0;
        
        //Inner text
        .filter-option {
            @extend %font-secondary;
            @include fontSize(14px);
            color: $neutral_2;
            width: auto;
            line-height: 1.5;
        }

        //Caret
        .caret {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 17px;
            svg.icon {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;

                width: 14px;
                height: 14px;
                color: $neutral_black;

                &.icon-closed {
                    display: none;
                }
            }
        }

        //Focus / active
        &:focus,
        &:active {
            outline: 0 !important;
        }

        &:focus {
            border-style: solid;
            border-color: $neutral_3;
            border-width: 1px 1px 1px 1px;
        }
    }
    
    //Dropdown menu
    .dropdown-menu {
        display: none; //Hidden by default
        position: absolute;
        left: 0;
        top: 48px;
        z-index: 50;
        width: 100%;
        padding: 10px 20px 20px 20px;
        background-color: $neutral_6;
        border-style: solid;
        border-color: $neutral_3;
        border-width: 0 1px 1px 1px;

        ul {
            display: block;
            list-style-type: none;
        }

        li {
            margin-left: 0;
            border-top: none;

            &:last-child {
                padding-bottom: 0;
            }

            &:before {
                display: none;
            }
        }

        a {
            @extend %font-secondary;
            @include fontSize(14px);
            color: $neutral_2;
            display: block;
            padding: 10px 0;

            &:focus,
            &:hover {
                color: $primary_2;
                font-weight: bold;
            }

            &:focus {
                outline: none !important;
            }
        }

    }

    .umbraco-forms-form & .dropdown-menu {
        z-index: 51;
    }

    /**
    *
    * Open State
    *
    **/
    &.open {

        //Trigger
        .dropdown-toggle {
            background-color: $neutral_6;
            border-style: solid;
            border-color: $neutral_3;

            //Caret
            .caret {
                svg.icon {
                    &.icon-closed {
                        display: inline;
                    }
                    &.icon-open {
                        display: none;
                    }
                }
            }
        }

        //Dropdown menu
        .dropdown-menu {
            display: block;
        }
    }


    &.dropup {
        .dropdown-menu {
            top: auto;
            bottom: 53px;
            border-bottom: none;
            border-top-width: 1px;
        }
    }
}

.select-alternate .bootstrap-select {
    text-align: left;


    &.center {
        text-align: center;
    }

    &.btn-group .dropdown-toggle,
    .dropdown-toggle {
        border: none;
        padding: 0;
        width: auto;
        height: auto;
        line-height: 1;
        position: relative;
        padding-right: 20px;
        margin-top: 6px;

        .filter-option {
            @extend %font-primary;
            @include fontSize(20px);
            @include lineHeight(20px);
            text-align: center;
            width: auto;
            color: $primary_2;
        }

        .bs-caret {
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            height: 12px;
        }

        .caret {
            @extend %font-primary;
            @include fontSize(16px);
            display: inline-block;
            position: static;
            top: unset;
            left: unset;
            right: unset;
            bottom: unset;
            width: auto;
            height: auto;
            vertical-align: top;
            margin: 0;

            svg.icon {
                height: 9px;
                width: 14px;
                position: static;
                top: initial;
                left: initial;
                right: initial;
                bottom: initial;
                transform: none;
            }
        }
    }
    .dropdown-menu {
        top: 30px;
        background: $lqt-colour-dropdown-options-bg;
        border: none;
        padding: 0;
        box-shadow: 0 0 20px 0 rgba(32, 32, 32, 0.2);
        width: auto;
        min-width: auto;

        ::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        ::-webkit-scrollbar:vertical {
            width: 12px;
        }

        ::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: $primary_2;
            border-radius: 10px;
            border: 2px solid #ffffff;
        }

        ::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #ffffff;
        }

        li {
            padding: 0;
            border-bottom: 1px solid $lqt-colour-dropdown-options-selected-border;

            &:last-child {
                border: none;
            }

            &:hover {
                background: $lqt-colour-dropdown-options-selected-bg;
                color: white;
                a {
                    color: white;
                }
            }
        }

        a {
            display: block;
            @include fontSize(12px);
            font-weight: bold;
            text-transform: uppercase;
            color: inherit;
            padding: 14px 20px 10px;
        }
    }



    &.dropup {
        .dropdown-menu {
            top: auto;
            bottom: 30px;
            border-bottom: none;
            border-top-width: 1px;
        }
    }

    &.open {
        .dropdown-toggle {
            background: transparent;
            border-style: none;
            border-color: transparent;
        }
    }

    .dropdown-toggle {
        &:focus {
            background: transparent;
            border-style: none;
            border-color: transparent;
        }
    }

}

.join-form .bootstrap-select.btn-group .dropdown-menu.open {
    z-index: 100;
}