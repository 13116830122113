/**
*
* Health Hub Article Info (Shared))
* Contains author info and article meta ie publish date etc
* Shared across top and bottom of article; note minor CSS and markup changes
*
**/
.article-info {
    padding: 20px 0 20px 0;

    .info-wrap {
        display: flex;
    }

    .txt-col {
        display: flex;
        flex-direction: column;
    }
    
    .avatar-col {
        width: 50px;
        height: 50px;
        flex-shrink: 0;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 12px;
    }

    .author-info {
        @include fontSize(18px);
        @include lineHeight(24px);
        color: #2D3748;
        margin-bottom: 14px;
    }

    .article-date {
        @include fontSize(18px);
        @include lineHeight(24px);
        color: #718096;
        margin-bottom: 0;
    }

    .author-detail {
        @include fontSize(18px);
        @include lineHeight(24px);
    }
    /*
    Placement variations
     */
    &.top-panel {
        border-bottom: solid 1px #C4C4C4;
    }

    &.footer-panel {
        border-top: solid 1px #C4C4C4;
        .author-col {
            
        }
        .author-wrap {
            display: flex;
            align-items: center;
        }
        .info-wrap {
            align-items: flex-start;
        }
        .author-name {
            font-weight: 700;
        }

        .author-info p {
            margin-bottom: 0;
        }
        
        .author-detail {
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
    //Desktop
    @media #{$sm} {
        max-width: $max-content-width;
        margin: auto;
        padding: 19px 0 24px 0;

        .info-wrap {
            align-items: center;
        }
        .txt-col {
            flex-direction: row;
            width: 100%;
        }

        .author-info {
            margin-bottom: 0;
        }

        .article-date {
            margin-left: auto;
        }
        
        .author-detail {
            margin-bottom: 0;
        }

        /*
        Ratings plugin
         */
        .rating-col{
            margin-left: auto;
            white-space: nowrap;

        }
    }
}
