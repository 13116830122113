/**
*
* Left hand side page subnav
*
**/

/**
*
* Visibility first
* We're forced to use TWO instances of the one view in order
* to achieve mobile layout. Hide / show each one based on
* media queries.
*
**/

.left-nav {
    display: none;

    //Desktop
    @media #{$sm} {
        display: block;
    }
}

.left-nav.mobile {
    display: block;

    //Desktop
    @media #{$sm} {
        display: none;
    }
}

/**
*
* Common nav styles
*
**/
.left-nav {
    ul,
    li {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    > ul {
        margin-bottom: 30px;
    }

    //Top level links
    a {
        @extend %font-primary;
        @include fontSize(siteSetting($leftNav, link_fontSize, 16px));
        position: relative;
        display: block;
        padding: siteSetting($leftNav, link_padding, 15px 20px 10px);
        background-color: white;
        color: $neutral_2;
        border-style: solid;
        border-color: $neutral_5;
        border-width: 1px 1px 0px 1px;

        svg.icon.arrow-right {
            display: none;
        }

        &:hover, &:active, &:focus {
            color: $primary_4;
        }
    }

    .accordion-trigger {
        display: none; //Hiden by default, display via JS if children exist
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
        width: 45px;
        height: 100%;
        text-align: center;
        padding: 10px 5px;

        svg.icon {
            height: 16px;
            width: 16px;
            color: white;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            &.arrow-up {
                display: none;
            }
            &.arrow-down {
                display: block;
            }
        }

    }

    //Common Children links
    .children {
        display: none;
        clear: both;

        li {
            display: block;
        }

        a {
            @extend %font-primary;
            display: block;
            width: 100%;
            padding: 11px 20px 10px;
            background-color: $neutral_4;
            border-color: $neutral_5;
            border-width: 0px 0px 1px 0px;

            &:hover, &:active, &:focus {
                color: $primary_4;
            }
        }

        li.active {
            a:hover, a:active, a:focus {
                color: white;
            }
        }
    }

    li {
        display: none;
        position: relative;
    }

    //Last item
    li:last-child {
        a {
            border-width: 0px 1px 1px 1px;
        }
    }

    //Current page - active state
    li.active {
        display: block;
        > a {
            background-color: $primary_4;
            color: white;
            border-color: $primary_4;
        }

        .children {
            .active {
                > a {
                    background-color: $neutral_4;
                    color: $primary_4;
                    border-color: $neutral_5;
                }
            }

            svg.icon {
                &.arrow-up {
                    display: none;
                }
                &.arrow-down {
                    display: none
                }
            }
        }
    }

    //Mobile, expanded state
    &.expanded {
        .children {
            display: block;
            border-bottom: 5px solid $neutral_1;
        }

        .accordion-trigger {
            svg.icon {
                &.arrow-up {
                    display: block;
                }
                &.arrow-down {
                    display: none;
                }
            }
        }
    }

    //Desktop
    @media #{$sm} {
        > ul {
            border-bottom: 5px solid $neutral_1;
        }

        li {
            display: block;
        }

        li:first-child {
            a {
                border-width: 1px;
            }
        }

        .children {

            padding-top: 10px;
            padding-bottom: 10px;

            border: 1px solid $neutral_5;
            border-top: 0;

            li a {
                position: relative;

                svg.icon.arrow-right {
                    display: block;
                    position: absolute;
                    left: 19px;
                    top: 10px;
                    width: 10px;
                    height: 10px;
                }

                @include fontSize(14px);
                font-family: $fontSecondary;
                font-weight: 400;
                display: block;
                width: 100%;
                padding: 5px 20px 5px 35px;
                border: 0;
                background-color: #FFFFFF;

                &:hover {
                    color: $primary_4;
                }

            }
        }

        //Current page - active state
        li.active {
            > .children {
                display: block;

                li.active {
                    > a {
                        background-color: #FFFFFF;
                    }

                    ul.children {
                        border: 0;
                        padding-left: 22px;

                        a {
                            &:hover {
                                color: $primary_4;
                            }
                        }

                    }
                }
            }
        }

        //Top level links
        a {
            display: block;
            float: none;
            width: 100%;
            border-color: $neutral_5;
            border-width: 0px 1px 1px 1px;
        }

        .accordion-trigger {
            display: none;
        }
    }
}
