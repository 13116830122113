.module-quote {
    text-align: center;
    color: $primary_4;
    svg.icon {
        fill: currentColor;
        height: 43px;
        width: 48px;
    }
    .text {
        padding-top: 24px;
        @include fontSize(20px);
        @include lineHeight(26px);
        font-style: italic;
    }
    .name {
        padding-top: 24px;
        font-weight: bold;
    }

    @media #{$sm} {
        svg.icon {
            height: 63px;
            width: 70px;
        }

        .text {
            @include fontSize(28px);
            @include lineHeight(36px);
        }

    }
}