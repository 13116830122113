.help-hub-home {
    .banner {
        background: $primary_2;
        min-height: 302px;
        padding-top: 64px;
        padding-bottom: 64px;
    }

    .banner::after {
        content: "";
        display: block;
        clear: both;
    }

    h2.title {
        color: $neutral_white;
        font-size: 3rem;
        margin-top: 0;
    }

    .search-box {
        width: 90%;
        margin: 50px auto;
        position: relative;

        .s-field {
            border-radius: 5px;
            height: 74px;
            padding: 15px 155px 15px 15px;

            @media #{$sm} {
                height: 84px;
            }
        }

        .s-btn {
            background: $primary_2;
            position: absolute;
            right: 6px;
            top: 5px;
            border: 0;
            height: calc(100% - 10px);
        }

        .s-btn .icon {
            height: 2.1em;
            width: 2.1em;

            @media #{$sm} {
                height: 0.75em;
                width: 0.75em;
            }
        }

        .s-btn span {
            display: none;

            @media #{$sm} {
                display: inline-block;
            }
        }
    }

    .most-search {
        margin: 10px 0 0 0;
        text-align: center;

        span {
            display: inline-block;
            font-size: 1.2rem;
            color: $neutral_white;
            margin: 0 10px;
        }

        span a {
            color: $neutral_white;
            text-decoration: underline;
        }

        span a:hover {
            text-decoration: none;
        }
    }

    @media #{$md} {
        .search-box {
            width: 846px;
        }
    }
}
