/**
*
* Banner CTA component
*
**/
.module-banner-cta {
    margin: 0;
    background-color: $primary_2;
    padding: 38px 0 38px 0;
    color: white;

    .text-block,
    .btn-block{
        padding: 0 $grid-gutter-width / 2;
    }

    .title {
        @include fontSize(26px);
        @include lineHeight(30px);
        margin-bottom: 13px;
        font-family: $fontPrimary;
    }
    .desc {
        @include fontSize(14px);
        @include lineHeight(19px);
        margin-bottom: 26px;
    }

    a {
        @include fontSize(20px);
        @include lineHeight(27px);
        padding-top: 5px;
        padding-bottom: 9px;
        width: 100%;
        text-align: center;

        &:nth-child(2) {
            margin-top: 20px;
        }
    }

    .sg-btn-secondary {
        color: white;
        border-color: white;

        &:hover {
            background-color: white;
            border-color: white;
            color: $primary-2;
        }
    }

    .sg-btn-quote,
    .sg-btn-join,
    .sg-btn-members,
    .sg-btn-phone {
        background-color: map_get($bannerCTA-primary2, btnColor);
        border-color: map_get($bannerCTA-primary2, btnColor);

        &:hover {
            background-color: darken(map_get($bannerCTA-primary2, btnColor), 10%);
            border-color: darken(map_get($bannerCTA-primary2, btnColor), 10%);
        }
    }


    /*
    Specific CMS managed style variants
    NOTE primary 2 is default (when no class selected in CMS)
     */
    &.style-primary-3 {
        background-color: $primary_3;
        color: map_get($bannerCTA-primary3, txtColor);

        .sg-btn-secondary {
            color: map_get($bannerCTA-primary3, btnSecondaryTxtColor);
            border-color: map_get($bannerCTA-primary3, btnSecondaryBorderColor);

            &:hover {
                background-color: map_get($bannerCTA-primary3, btnSecondaryTxtColor);
                border-color: map_get($bannerCTA-primary3, btnSecondaryTxtColor);
                color: map_get($bannerCTA-primary3, btnSecondaryTxtColor_hover);
            }
        }

        .sg-btn-quote,
        .sg-btn-join,
        .sg-btn-members,
        .sg-btn-phone {
            background-color: white;
            border-color: white;
            color: $primary_2;

            &:hover {
                background-color: darken(white, 10%);
                border-color:  darken(white, 10%);
            }
        }
    }

    &.style-primary-4 {
        background-color: $primary_4;
        color: white;

        .sg-btn-secondary {
            color: white;
            border-color: white;

            &:hover {
                background-color: white;
                color: $primary_2;
            }
        }

        .sg-btn-quote,
        .sg-btn-join,
        .sg-btn-members,
        .sg-btn-phone {
            background-color: white;
            border-color: white;
            color: $primary_2;

            &:hover {
                background-color: darken(white, 10%);
                border-color:  darken(white, 10%);
            }
        }
    }

    &.style-white-colour-button {
        background-color: white;
        color: $primary_2;

        .sg-btn-secondary {
            color: $primary_2;
            border-color: $primary_2;

            &:hover {
                background-color: $primary_2;
                color:  white;
            }
        }

        .sg-btn-quote,
        .sg-btn-join,
        .sg-btn-members,
        .sg-btn-phone {
            background-color: $primary_4;
            border-color: $primary_4;
            color: white;

            &:hover {
                background-color: darken($primary_4, 10%);
                border-color: darken($primary_4, 10%);
            }
        }
    }

    &.style-white-dark-button {
        background-color: white;
        color: $primary_2;

        .sg-btn-secondary {
            color: $primary_2;
            border-color: $primary_2;

            &:hover {
                background-color: $primary_2;
                color:  white;
            }
        }

        .sg-btn-quote,
        .sg-btn-join,
        .sg-btn-members,
        .sg-btn-phone {
            background-color: $primary_2;
            border-color: $primary_2;
            color: white;

            &:hover {
                background-color: darken($primary_2, 10%);
                border-color: darken($primary_2, 10%);
            }
        }
    }

    //Tablet
    @media #{$sm} {
        .flex-wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .text-block {
            flex-basis: 60%;
            padding-right: 0;
        }

        .btn-block {
            flex-basis: 40%;
        }

        .desc {
            margin-bottom: 0;
        }
    }

    //Desktop
    @media #{$md} {

        .title {
            @include fontSize(36px);
            @include lineHeight(40px);
        }

        .btn-block {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }

        a {
            padding-top: 17px;
            padding-bottom: 20px;
            max-width: 60%;

            &:nth-child(2) {
                margin-top: 0;
                margin-left: 20px;
            }
        }
    }
}
