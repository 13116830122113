/**
*
* Categories CTAs
*
**/
.categories-ctas {
    background-color: #F7F7F7;
    width: 100%;
    overflow: hidden;
    padding: 40px 0 40px 0;
    position: relative;

    .section-title {
        @extend %font-primary;
        @include fontSize(36px);
        @include lineHeight(48px);
        color: $primary_2;
        margin-bottom: 27px;
        margin-top: 0;
    }

    .swiper-container {
        width: calc(100% - 40px);
        margin: 0;
        overflow: visible;
    }

    .swiper-slide {
        height: auto;

        .category-tile,
        .hh-service-tile {
            margin-bottom: 0;
            height: 100%;
        }
    }
    /*
    Gradient overlay to right of wrapper
     */
    &:after {
        content: ' ';
        position: absolute;
        width: 20px;
        top: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
        z-index: 2;
    }

    //Desktop
    @media #{$sm} {
        padding: 30px 0 34px 0;

        .section-title {
            margin-bottom: 55px;
        }

        .swiper-container {
            width: 100%;
        }
    }
}
