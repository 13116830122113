$module-campaign-table-sticky-width: 162px;

.module-campaign-table {
    /**
     * Reset for this module
     */
    table {
        display: table;
        border-top: none;
        border-bottom: none;
        border-left: none;
        border-right: none;
        th, td {
            border: none;
            text-transform: none;
        }
    }

    // Styles for the table.
    .table-scroller {
        position: relative;
        display: block;
        table {
            th, td {
                border: 2px solid $neutral_white;
                padding: 20px;
            }

            tr {
                position: relative;

                th {
                    @include fontSize(18px);
                    @include lineHeight(22px);
                    vertical-align: middle;
                }

                td {
                    @include font-primary();
                    @include fontSize(20px);
                    @include lineHeight(24px);
                    color: $primary_2;
                    min-width: 162px;
                    vertical-align: middle;
                    text-align: center;
                }

                th:first-child {
                    min-width: $module-campaign-table-sticky-width;
                    width: $module-campaign-table-sticky-width;
                }
            }

            th.colour-white, td.colour-white {
                background-color: $neutral_white;
            }
            @each $class, $colour in $brandColoursMap {
                td.colour-#{$class} {
                    background-color: rgba($colour, 0.1);
                }
                th.colour-#{$class} {
                    background-color: $colour;
                    color: white;
                }
            }
        }
        table.table__column--persistent {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            min-width: auto;
            z-index: 4;
        }

        .table-wrap {
            width: 100%;
            overflow-x: auto;
            position: static;

            table {
                // width: 100%;
                //max-width: 100%;
            }
        }

    }
    @media #{$sm} {
        .table-scroller {
            position: relative;
            display: inline-block;
            left: 50%;
            transform: translateX(-50%);
        }
        .table-wrap {
            width: auto;
            display: inline-block;
        }
    }
    //    position: relative;
    //    .table-scroller-inner {
    //        overflow-x: auto;
    //        overflow-y: visible;
    //        margin-left: $module-campaign-table-sticky-width;
    //    }
    //
    //    table {
    //        th, td {
    //            border: 2px solid $neutral_white;
    //        }
    //
    //        tr {
    //            position: relative;
    //            th:first-child {
    //                position: absolute;
    //                left: 0;
    //                width: $module-campaign-table-sticky-width;
    //
    //            }
    //        }
    //
    //        @each $class, $colour in $brandColoursMap {
    //            .colour-#{$class} {
    //                background-color: rgba($colour, 0.1);
    //            }
    //        }
    //    }
    //}
}
