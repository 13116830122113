
.module-content-tiles {
    .description {
        margin-bottom: 26px;
    }
    /*
    Tile column structure
     */
    .tile-wrapper {
        @include make-row();
    }

    .tile-col-wrap {
        @include make-xs-column(12)
    }
    /*
    Shared tile design
     */
    .tile {
        box-shadow: 0 2px 3px rgba(28, 37, 44, 0.2);

        .image-block {
            width: 100%;
        }

        picture,
        img {
            display: block;
            width: 100%;
        }

        .content-block {
            padding: 15px 20px 16px 20px;
        }

        .title-copy {
            @include fontSize(26px);
            @include lineHeight(32px);
            margin-bottom: 12px;
            font-family: $fontPrimary;
            color: $primary_2;
        }

        .description {
            @include fontSize(14px);
            @include lineHeight(19px);
            margin-bottom: 16px;
        }

        .sg-btn {
            //margin-bottom: 24px;
        }
        //Override button colours
        .sg-btn-secondary {
            border-color: $primary_2;
            color: $primary_2;

            &:hover {
                background-color: $primary_2;
                color: white;
            }
        }

        .sg-btn-primary4 {
            border-color: $primary_2;
            color: white;
            background-color: $primary-2;

            &:hover {
                background-color: darken($primary_2, 20%);
            }
        }
    }
    //Two + Three tiles
    &.items-2,
    &.items-3 {
        .tile-col-wrap {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .title-copy {
            @include fontSize(20px);
            @include lineHeight(26px);
        }
    }

    @media #{$sm} {
        //Single tile
        &.items-1 {
            .tile {
                display: flex;
                box-shadow: 2px 0 4px rgba(0, 0, 0, 0.25);

                .image-block,
                .content-block {
                    flex-basis: 50%;
                }

                picture {
                    height: 100%;
                }

                img {
                    height: 100%;
                    object-fit: cover;
                }

                .content-block {
                    padding: 20px 30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .title-copy {
                    @include fontSize(26px);
                    @include lineHeight(30px);
                    margin-bottom: 16px;
                }

                .sg-btn {
                    margin-right: auto;
                }
            }
        }
        //Two + Three tiles
        &.items-2,
        &.items-3 {
            .tile-wrapper {
                display: flex;
            }

            .tile-col-wrap {
                flex-basis: 50%;
                padding: 0 $grid-gutter-width / 2;
                margin-bottom: 0;
            }

            .tile {
                height: 100%;
                display: flex;
                flex-direction: column;

                .content-block {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }

                a {
                    margin-top: auto;
                    margin-right: auto;
                }
            }
        }
    }

    @media #{$md} {
        //Single tile
        &.items-1 {
            .tile {
                .title-copy {
                    @include fontSize(36px);
                    @include lineHeight(40px);
                }

                .sg-btn {
                    max-width: 40%;
                }
            }
        }
        //Single tile + text link overrides
        &.items-1.link-style-link {
            .image-block,
            .content-block {
                max-height: 264px;
                overflow: hidden;
            }
        }
        //Two + Three tiles
        &.items-2,
        &.items-3 {
            .title-copy {
                @include fontSize(26px);
                @include lineHeight(30px);
            }
        }
    }
}
