/**
*
* Join form header tabs
*
**/
.join-form_header-tabs {

	height: 85px;

	/**
	*
	* Common tab structure and elements
	*
	**/
	.tab {
		position: relative;
		z-index: 19;
		width: 16.66%;
		height: 100%;
		padding-top: 17px;
		float: left;
		cursor: pointer;
		color: $neutral_3;
		text-align: center;

		.inner-wrap {
			position: relative;

			//Use psuedo elements for the horizontal line
			//(nth) child selectors used to hide first and last
			&:before,
			&:after {
				content: '';
				display: block;
				width: 50%;
				height: 2px;
				background-color: $neutral_3;
				top: 8px;
				position: absolute;
				z-index: 1;
			}

			&:before {
				left: 0;
			}

			&:after {
				right: 0;
			}
		}

		&:first-child {
			.inner-wrap:before { display: none;}
		}
		&:last-child {
			.inner-wrap:after { display: none;}
		}

		svg.icon-warning {
			display: none; //Hidden by default - see states below
		}


		//common text styles
		.tab-number{
			@include fontSize(9px);
			position: relative;
			z-index: 5;
			display: block;
			width: 18px;
			height: 18px;
			margin: auto;
			margin-bottom: 10px;
			border-radius: 9px;
			border: solid 2px $neutral_3;
			font-family: $fontSecondary;
			font-weight: 700;
			background-color: white;


			svg.icon {
				fill: white;
				position: relative;
				height: 8px;
				width: 8px;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
			}
			.text {
				display: block;
				padding-top: 1px;
			}
			svg.icon.icon-warning {
				width: 14px;
				height: 14px;
			}
			svg.icon.icon-tick { display: none; }

		}

		.tab-label {
			@include fontSize(9px);
			text-transform: uppercase;
			line-height: 1;
			display: block;
			margin-bottom: 6px;
			font-family: $fontSecondary;
    		font-weight: 700;
		}

		.edit-label {
			@include fontSize(9px);
			display: none; //Hidden by default
			color: $primary_2;
			text-decoration: underline;

			svg.icon {
				display: none;
			}
		}
	}

	/**
	*
	* Individual Tab states
	*
	**/
	.tab.has-data {
		.tab-number {
			position: relative;
			background-color: $neutral_3;
			.text { display: none; }
			svg.icon.icon-warning { display: none; }
			svg.icon.icon-tick { display: block; }

		}

		.edit-label {
			display: block;
		}
	}
	.tab.active.has-data {
		.tab-number {
			.text { display: block; }
			svg.icon.icon-warning { display: none; }
			svg.icon.icon-tick { display: none; }
		}
	}
	.tab.active {
		.tab-number {
			@include fontSize(12px);
			position: relative;
			top: -4px;
			width: 24px;
			height: 24px;
			border-radius: 12px;
			background-color: $primary_2;
			border-color: $primary_2;
			color: white;
			.text { display: block; }
		}

		.tab-label {
			position: relative;
			top: -6px;
			color: $primary_2;
		}
	}

	.tab.error {
		.tab-number {
			position: relative;
			background-color: $error;
			border-color: $error;
			color: $error;

			.text { display: none; }
			svg.icon.icon-warning { display: block; }
			svg.icon.icon-tick { display: none; }
		}

		.tab-label {
			color: $error;
		}
	}
	.tab.error.active {
		.tab-number {
			.text { display: block; color: #FFFFFF; }
			svg.icon.icon-warning { display: none; }
			svg.icon.icon-tick { display: none; }
		}
	} 	

	.edit-label {
		@extend %font-primary;
	}

	//Desktop
	@media #{$sm} {

		height: 90px;

		/**
		*
		* Individual Tab structure and text styles
		*
		**/
		.tab {
			padding-top: 20px;
			text-align: left;

			.inner-wrap {
				background-image:url("#{$img-url}/backgrounds/join-form/tab-gradient_bg.png?ver=#{$img-ver}");
				background-position: bottom left;
				background-repeat: repeat-x;
				width: 100%;
				height: 100%;
				padding: 15px 10px;
				background-color: $neutral_4;
				border-right: solid 1px $neutral_5;

				//Hide the psuedo elements for the horizontal line
				&:before,
				&:after {
					content: '';
					display: none;
				}
			}

			//common text styles
			.tab-number{
				@include fontSize(16px);
				display: block;
				width: auto;
				height: auto;
				margin: auto;
				padding: initial;
				border: none;
				border-radius: 0;
				font-family: $fontPrimary_light;
				line-height: 1;
				font-weight: normal;
				background: none;

				.text { display: block; }
				svg.icon.icon-warning { display: none; }
				svg.icon.icon-tick { display: none; }


			}
			.tab-label {
				@include fontSize(16px);
				text-transform: none;
				line-height: 1;
				display: block;
				margin-bottom: 0px;
				margin-right: 20px;
			    font-family: $fontPrimary;
				font-weight: normal;
			}

			svg.icon-warning {
				height: 14px;
				width: 14px;

				position: absolute;
				right: 10px;
				top: 10px;
				fill: $warning;
			}

			.edit-label {
				@include fontSize(13px);
				position: absolute;
				right: 10px;
				bottom: 10px;
				color: $primary_2;
				text-decoration: none;

				svg.icon {
					height: 10px;
					display: inline;
					fill: $primary_2;
				}
			}

			//Last tab overrides
			&:last-child {
				.inner-wrap {
					border-right: 0px;
				}
			}
		}


		/**
		*
		* Individual Tab states
		*
		**/
		.tab.has-data {
			padding-top: 0px;

			.tab-number {
				background: none;
				top: initial;

				.text { display: block; }
				svg.icon.icon-warning { display: none; }
				svg.icon.icon-tick { display: none; }
			}
			.tab-label {
				top: initial;
			}
			.edit-label {
				display: inline-block
			}

		}

		.tab.active {
			box-shadow: 0px -9px 14.25px 0.75px rgba(0, 0, 0, 0.3);
			color: $primary_2;
			padding-top: 0px;
			z-index: 20;

			.inner-wrap {
				background-color: white;
				background-image: none;
			}

			.tab-number {
				@include fontSize(16px);
				background: none;
				top: initial;
				width: auto;
				height: auto;
				margin: auto;
				padding: initial;
				color: $primary_2;

				.text { display: block; }
				svg.icon.icon-warning { display: none; }
				svg.icon.icon-tick { display: none; }
			}

			.tab-label {
				top: initial;
			}
		}

		.tab.error {
			padding-top: 0px;

			.inner-wrap {
				background-color: $errorLight;
			}

			.tab-number {
				background: none;
				border: none;
				color: $error;

				.text { display: block; color: $warning; }
				svg.icon.icon-warning { display: none; }
				svg.icon.icon-tick { display: none; }
			}

			.tab-label {
				color: $error;
			}


			svg.icon-warning {
				display: block;
			}

			.edit-label {
				svg.icon {
					fill: $error;
				}
			}

			span {
				color: $warning;
			}
		}
		.tab.error.active {
			.tab-number {
				.text {
					color: $error;
				}
			}
		}
	}
}
