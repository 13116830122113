/**
*
* Health Hub Article
* Note: Actual article content modules are managed independently,
* This file is mostly for specific article overides to existing modules, such as breadcrumbs
*
**/
.hh-article {

    .breadcrumbs-row {
        margin-top: 20px;
        margin-bottom: 17px;
    }

    //Desktop
    @media #{$sm} {
    }
}
