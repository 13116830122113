.module-horizontal-subnav {
    position: relative;
    color: $primary_2;

    .list-items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    li {
        width: 48%;
        padding: 20px 0 22px 0;
    }

    a {
        color: $primary_2;
        text-decoration: underline;
        font-weight: bold;
    }

    @media #{$sm} {

        .list-items {
            justify-content: flex-start;
        }

        li {
            width: auto;
            padding: 18px 30px 14px 0;
        }

        
    }
}
