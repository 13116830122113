.government-rebate-selector {
    @extend .clearfix;
    .checkbox {
        float:left;
        padding-top: 12px;
        padding-right: 15px;
    }
    .income-tier {
        float:left;
        width: 210px;
        padding: 0 15px;
    }
    .rebate-tier-tooltip {
        float:left;
        padding-top: 8px;
        padding-left: 0;
    }
}