/**
*
* Health Hub - Category Landing page feature
*
**/
.category-feature {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    min-height: 294px;
    height: 294px;

    &.tall {
        min-height: 500px;
        height: 500px;
    }

    .container-fluid,
    .row,
    .col-xs-12,
    .content-wrap {
        height: 100%;
    }
    
    .img-layer,
    .content-layer {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    picture,
    img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }

    .content-layer {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .content-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 30px 0;
        p:last-child {
            margin-bottom: 0;
        }
    }

    .title {
        @include fontSize(52px);
        @include lineHeight(70px);
        color: $primary_2;
        margin: 0;
        text-align: center;
    }

    .sub-title {
        @include fontSize(20px);
        @include lineHeight(27px);
        color: $primary_2;
        margin-bottom: 23px;
        text-align: center;
    }

    .desc {
        @include fontSize(16px);
        @include lineHeight(22px);
        color: #505050;
        margin: 0;
        text-align: center;
    }

    //Desktop
    @media #{$md} {
        min-height: 294px;
        height: 294px;

        img {
            position: relative;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            width: 100%;
            min-width: 1440px;
        }

        .content-wrap {
            text-align: left;
        }

        .sub-title {
            margin-bottom: 19px;
        }
    }
}