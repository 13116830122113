/**
*
* Offer Hero Module
* https://deepend.atlassian.net/browse/THFM-728
*
**/

// TODO Name this to module-<name>
.offer-module {
    margin: 0;
    $mobileYOffset: 180px;

    width: 100%;
    position: relative;
    margin-bottom: -$mobileYOffset;
    overflow: hidden;

    .img-block {
        width: 100%;
    }

    img {
        width: 100%;
    }
    /*
    Content tile and children
     */
    .content-tile {
        position: relative;
        top: -$mobileYOffset;
        width: 100%;
        background-color: white;
        padding: 13px 15px 20px 15px;
        text-align: center;
        color: $primary_2;
        margin-bottom: 30px; 
    }

    .title-txt {
        @include fontSize(50px);
        @include lineHeight(50px);
        margin-top: 0;
        margin-bottom: 10px;
        color: inherit;
    }

    .sub-txt {
        @include fontSize(16px);
        @include lineHeight(24px);
        color: inherit;
        margin-bottom: 44px;
        margin-top: 30px;
    }

    a {
        @include animate-custom(#{'color, background-color, border'}, 350ms);
        @include fontSize(20px);
        @include lineHeight(27px);
        @extend %font-primary;
        display: inline-block;
        padding: 18px 27px 17px 27px;
        color: white;
        background-color: $primary_2;
        border: solid 2px $primary_2;
        border-radius: 5px;

        &:hover {
            color: $primary_2;
            background-color: white;
        }
    }
    /*
    CMS configured 'dark' variant
     */
    &.variant--dark {
        .content-tile {
            background-color: $primary_2;
            color: white;
        }

        a {
            color: $primary_2;
            background-color: white;
            border: solid 2px white;

            &:hover {
                color: white;
                background-color: $primary_2;
            }
        }
    }
    /*
    For when no image is present for mobile.
    Note: image is ALWAYS needed, as desktop always has it
     */
    &.variant--no-image {
        margin-bottom: 0;

        .img-block {
            display: none
        }

        .content-tile {
            height: auto;
            display: block;
            top: 0;
            margin-bottom: 0;
            /*height: 315px;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 0;*/
        }

        a {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.variant--no-image.variant--dark {
        .row {
            background-color: $primary_2
        }
    }
    /*
    Outlined button variant - only applicable to MD screens
     */
    &.variant--btn-outline {
        a {
            background-color: transparent;
            color: $primary_2;
            border: solid 2px $primary_2;

            &:hover {
                color: white;
                background-color: $primary_2;
            }
        }
    }
    /*
    Dark and outlined button variant
     */
    &.variant--btn-outline.variant--dark {
        a {
            background-color: transparent;
            color: white;
            border: solid 2px white;

            &:hover {
                color: $primary_2;
                background-color: white;
            }
        }
    }
    //Desktop
    @media #{$md} {
        position: relative;
        height: 512px;
        padding-bottom: 0;
        margin-bottom: 50px;

        .img-block {
            height: 100%;
            width: 100%;
        }

        picture,
        img {
            display: block;
            width: 100%;
        }

        img {
            position: relative;
            top: 256px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%) translateY(-50%);
            height: 100%;
            width: 100%;
            min-width: 1440px;
        }

        .content-block {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            .col,
            [class*="col-"] {
                height: 100%;
            }
        }

        .container-fluid,
        .row {
            width: 100%;
            height: 100%;
            display: flex;
        }

        .content-col {
            display: flex;
            align-items: center;
        }
        /*
        Content tile and children
         */
        .content-tile {
            top: 0;
            text-align: left;
            padding: 50px 56px;
            background-color: rgba(white, 0.9);
            margin-bottom: 0;
        }

        .title-txt {
            @include fontSize(57px);
            @include lineHeight(57px);
        }

        .sub-txt {
            @include fontSize(14px);
            @include lineHeight(19px);
            max-width: 70%;
            margin-bottom: 46px;
        }
        /*
        No Image variant overrides
         */
        &.variant--no-image {
            margin-bottom: 50px;

            .row {
                background-color: transparent;
            }

            .img-block {
                display: block
            }

            .content-tile {
                height: auto;
                display: block;
                padding: 50px 56px;
                background-color: rgba(white, 0.9);
            }
        }
        /*
        No image + dark variant
         */
        &.variant--no-image.variant--dark {
            .row {
                background-color: transparent;
            }

            .content-tile {
                background-color: $primary_2;
            }
        }
    }
}
