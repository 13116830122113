/**
*
* Join Form - cover select component
* 4 column component with graphic icons for cover type
*
**/
.member-type-selector {
	margin-top: 10px;
	margin-bottom: 40px;
}
.cover-select {
	@include clearfix-micro();

	//Override default bootstrap padding for columns
	.cover-col {
		padding-left: 5px;
		padding-right: 5px;
	}

	.cover-type {
		position: relative;
		@extend %font-secondary-bold;
		height: 70px;
		display: block;
		width: 100%;
		margin: auto;
		background-color: $neutral_6;
		border-radius: 5px;
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.35);
		text-decoration: none;
	}

	.content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.inner {
		display: block;
		text-align: center;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

	svg.icon {
		color: $neutral_1;
		width: 15px;
		height: 15px;
		margin-bottom: 4px;
	}

	.copy {
		@include fontSize(10px);
		display: block;
		padding-left: 10px;
		padding-right: 10px;
		line-height: 10px;
		color: $neutral_2;
	}

	//Force text positioning of LAST item
	.cover-col:last-child {
		.copy {
			position: relative;
			top: -5px;
		}
	}

	//Active state
	.cover-type.active {
		background-color: $primary_2;
		box-shadow: none;

		.icon,
		.copy { color: white; fill: white; }
	}

	//Desktop
	@media #{$sm} {

		.cover-type {
			@include aspect-ratio(1, 1);
			height: auto;
		}

		.cover-col {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
		}

		svg.icon {
			color: $neutral_1;
			width: 30px;
			height: 30px;
			margin-bottom: 17px;
		}

		.copy {
			@include fontSize(16px);
			padding-left: 0px;
			padding-right: 0px;
		}

		//Reset text positioning of LAST item
		.cover-col:last-child {
			.copy {
				position: relative;
				top: 0;
			}
		}
	}
	/*END GUIDES*/
}


