/**
*
* Join Form - inline alert
* Full width component, $primary_4 background, generic copy
*
**/

.inline-alert {
	background-color: $inline-alert-background;
	padding: 15px 22px;
	margin-bottom: 30px;
	color: $inline-alert-color;
	text-align: center; 


	a {
		color: $inline-alert-color;
	}
	
	&.error {
		background-color: $error;
		color: $neutral_white;
	}
	
	
	

	.title {
		@extend %font-secondary-bold;
		@include fontSize(8px);
		margin-bottom: 5px;
		text-transform: uppercase;
	}

	.body {
		@include fontSize(12px);
		margin-bottom: 0;
	}


	//Desktop
	@media #{$sm} {
		padding: 20px 25px;

		.title {
			@include fontSize(11px);
			margin-bottom: 10px;
		}

		.body {
			@include fontSize(14px);
		}
	}
}
