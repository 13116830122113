/**
*
* Directory variables
*
**/
$img-ver: '1.1';
$static-url: '/static';
$img-url: '#{$static-url}/img/#{$global_brand}/';
$font-url: '#{$static-url}/fonts/global/';

/**
*
* Overriding Bootstrap config variables
*
**/

/**
*
* Structure, Media queries and breakpoints
*
**/
$max-site-width: 1680px;
$max-content-width: 986px;

$grid-columns:              12;
$grid-gutter-width:         24px;


// Extra small screen / phone
$screen-xs:                  480px;
$screen-xs-min:              $screen-xs + 1;
$screen-phone:               $screen-xs-min;

// Small screen / tablet
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm + 1;
$screen-tablet:              $screen-sm-min;

// Medium screen / desktop
$screen-md:                  992px;
$screen-md-min:              $screen-md + 1;
$screen-desktop:             $screen-md-min;

// Large screen / wide desktop
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg + 1;
$screen-lg-desktop:          $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

//Media queries based on above vars
$xs: "only screen and (min-width : "$screen-xs + 1")";
$sm: "only screen and (min-width : "$screen-sm + 1")";
$md: "only screen and (min-width : "$screen-md  + 1")";
$lg: "only screen and (min-width : "$screen-lg  + 1")";

//Custom breakpoints + queries
$uptoSM:  "only screen and (max-width : "$screen-sm")";

$link-hover-decoration: none;

//Usage eg:
/*

.elem {
	@media #{$md} {
		//Styles
	}
}

*/

// Override the default mobile menu widths
$mm_menuMinWidth	: 250px;
$mm_menuMaxWidth	: 80%;