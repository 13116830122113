.module-image-video-fullwidth {
    margin: 0;

    &.constrain-content-width {
        margin: 64px 0;
    }

    &.reduce-height {
        .bg-image {
            height: 200px;
        }
    }

    .bg-image {
        position: relative;
        overflow: hidden;
        height: 360px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;


        &::after {
            content: "";
            background: #000000;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    /*
      CMS controlled darkening options
    */
    $darkenSteps: 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;

    @each $step in $darkenSteps {
        &.darken-#{$step} {
            .bg-image:after {
                opacity: $step / 100;
            }
        }
    }

    .content-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        color: $neutral_white;
        text-align: center;
        z-index: 1;

        .title {
            display: block;
            text-align: center;
            margin-bottom: 9px;
            @extend %header-x-large;
            color: $neutral_white;
        }

        .description {
            @include font-primary();
            @include fontSize(20px);
            @include lineHeight(26px);
            margin-bottom: 20px;
        }
    }

    .sg-btn-primary1 {
        background-color: $primary-1;
        border-color: $primary-1;
    }

    .sg-btn-primary2 {
        background-color: $primary-2;
        border-color: $primary-2;
    }

    .sg-btn-primary3 {
        background-color: $primary-3;
        border-color: $primary-3;
    }

    .sg-btn-primary4 {
        background-color: $primary-4;
        border-color: $primary-4;
    }

    .sg-btn-primary5 {
        background-color: $primary-5;
        border-color: $primary-5;
    }
    /*Tablet*/
    @media #{"only screen and (min-width : "$screen-sm")"} {

        &.reduce-height {
            .bg-image {
                height: 280px;
            }
        }
    }


    @media #{$md} {
        .video-player {
            transform: translateY(-50%);
            position: relative;
            top: 50%;
            display: block;
        }
    }
}
