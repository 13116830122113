.module-semantic-statement {
    background-color: #EEEEEE;
    padding: 40px 25px;

    .parent-wrap {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: white;
    }

    .lg-line-break {
        display: none;
    }
    /*
    Panel wrapper - inc. forced heights
     */
    .panel-wrapper {
        @include animate-custom(#{'height'}, 350ms);
        position: relative;
        height: 500px;

        &[data-current-panel='0'] {
            height: 424px;
        }

        &[data-current-panel='1'] {
            height: 335px;
        }

        &[data-current-panel='2'] {
            height: 405px;
        }

        &[data-current-panel='3'] {
            height: 582px;
        }

        &[data-current-panel='4'] {
            height: 410px;
        }
    }
    /*
    Shared intro text
     */
    .intro-block {
        @include animate-custom(#{'max-height, opacity'}, 350ms);
        padding: 27px 10px 0 10px;
        text-align: center;
        overflow: hidden;
        max-height: 77px;

        .intro {
            @include animate-custom(#{'font-size, line-height'}, 350ms);
            @extend %font-primary;
            @include fontSize(25px);
            @include lineHeight(30px);
            color: $primary-2;
            margin-bottom: 6px;
        }
    }

    &.header-hidden {
        .intro-block {
            max-height: 0;
            opacity: 0;
        }
    }
    /*
    Override intro font size when on first panel
     */
    &.module-start {
        .intro {
            @include fontSize(30px);
            @include lineHeight(50px);
            margin: 0;
        }
    }
    /*
    Common panel state
    Ie, active, inactive etc
     */
    .panel {
        //display: none;
        @include animate-custom(#{'opacity'}, 200ms);
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        pointer-events: none;
    }
    /*Active panel*/
    .panel.active {
        opacity: 1;
        pointer-events: all;
    }

    .panel-question {
        text-align: center;
        padding: 0 10px;

        p {
            @extend %font-primary;
            @include fontSize(30px);
            @include lineHeight(38px);
            color: $lqt-colour-success;
            margin-bottom: 16px;

            &.question-small {
                @include fontSize(24px);
                @include lineHeight(28px);
                color: $primary-2;
            }
        }
    }
    /*
    Controls + Back button
     */
    .controls {
        .back {
            @include fontSize(20px);
            @include lineHeight(24px);
            @extend %font-primary;
            display: block;
            margin: auto;
            margin-bottom: 10px;
            color: #9A9A9A;

            .icon {
                width: 10px;
                height: 10px;
            }
        }
    }

    .panel-options {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 23px 12px 19px 12px;
    }
    /*
    Last panel - Boostrap select dropdowns
     */
    .bootstrap-select {
        width: auto !important;
        height: 21px;
        line-height: 1;
        top: 0;
        padding-left: 5px;
        padding-right: 0;
        margin-right: 5px;

        > select {
            z-index: -1;
        }


        .dropdown-toggle {
            background: transparent;
            height: 21px !important;
            padding: 0 17px 0 0;
            margin: 0;
            line-height: 1;
            border: none !important;
            background: none !important;

            .filter-option {
                @include fontSize(siteSetting($semanticStatement, dropdown_fontSize, 18px));
                font-family: $fontPrimary !important;
                line-height: 1;
                color: $primary_4;
            }

            .caret {
                top: 9px;
                right: -2px;
                z-index: 6;

                svg.icon {
                    color: $primary_4;

                    &.icon-closed,
                    &.icon-open {
                        margin: 0;
                    }
                }
            }

            &:focus {
                border: none;
            }
        }

        .dropdown-menu {
            top: 24px;
            width: auto !important;
            border: none;
            padding: 0;
            box-shadow: 0 0 20px 0 rgba(32, 32, 32, 0.2);
            line-height: 1;

            li {
                padding: 0;
            }

            a {
                @include fontSize(12px);
                display: block;
                font-weight: bold;
                text-transform: uppercase;
                color: inherit;
                padding: 14px;

                &:hover {
                    color: $primary_4;
                }
            }
        }

        &.dropup {
            .dropdown-menu {
                top: auto;
                bottom: 25px;
                border-bottom: none;
                border-top-width: 1px;
            }
        }
    }


    .cta-block {
        a {
            background-color: $primary_3;
            border-color: $primary_3;
        }
    }
    /**
    *
    *
    * Panel specific styling
    *
    *
     */
    /*
    First panel has reduced font size
     */
    .panel:first-child {
        .panel-question {
            p.question-small {
                @include fontSize(25px);
                @include lineHeight(38px);
            }
        }
    }
    /*
    Location panel
     */
    .panel.location {
        .panel-options a {
            @include SSOption()
        }
    }
    /*
    Location panel
     */
    .panel.age {
        .panel-options a {
            @include SSOption()
        }
    }
    /*
    Membership type panel
     */
    .panel.membership-type {
        .panel-options a {
            @include SSOption('full')
        }
    }
    /*
    Cover type panel
     */
    .panel.cover-type {
        .panel-options a {
            @include SSOption('full')
        }
    }
    /*
    Final, summary panel
     */
    .panel.statement-summary {
        .panel-question {
            p {
                color: $primary_2;
            }
        }

        .copy {
            @extend %font-primary;
            @include fontSize(25px);
            @include lineHeight(32px);
            color: #505050;
        }

        .form-wrap {
            display: block;
            width: 100%;
            padding: 0 8px;
            vertical-align: middle;
            text-align: center;
        }

        .cta {
            padding: 13px 20px 20px 20px
        }
    }
    
    
    /*
        403px +
    */
    @media #{"only screen and (min-width : 403px)"} {
        .panel-wrapper {
            height: 455px;

            &[data-current-panel='0'] {
                height: 424px;
            }

            &[data-current-panel='1'] {
                height: 335px;
            }

            &[data-current-panel='2'] {
                height: 405px;
            }

            &[data-current-panel='3'] {
                height: 586px;
            }

            &[data-current-panel='4'] {
                height: 410px;
            }
        }
    }
    
    
    
    /*
        403px +
    */
    @media #{"only screen and (min-width : 677px)"} {
        .panel-wrapper {
            height: 420px;

            &[data-current-panel='0'] {
                height: 420px;

                .question-small {
                    margin-bottom: 20px;
                }
            }

            &[data-current-panel='1'] {
                height: 335px;
            }

            &[data-current-panel='2'] {
                height: 405px;
            }

            &[data-current-panel='3'] {
                height: 545px;
            }

            &[data-current-panel='4'] {
                height: 410px;
            }
        }
    }
    /*
        768px +
    */
    @media #{"only screen and (min-width : "$screen-sm")"} {
        /*
        Panel wrapper - inc. forced heights
         */
        .panel-wrapper {
            height: 410px;

            &[data-current-panel='1'],
            &[data-current-panel='2'],
            &[data-current-panel='3'],
            &[data-current-panel='4'] {
                .panel-question {
                    padding: 0 70px;
                }
            }

            &[data-current-panel='0'] {
                height: 410px;
            }

            &[data-current-panel='1'] {
                height: 350px;
            }

            &[data-current-panel='2'] {
                height: 350px;
            }

            &[data-current-panel='3'] {
                height: 420px;
            }

            &[data-current-panel='4'] {
                height: 350px;
            }
        }
        /*
        Shared intro text
         */
        .intro-block {
            padding: 43px 10px 0 10px;
            max-height: 107px;

            .intro {
                @include fontSize(30px);
                @include lineHeight(38px);
                margin-bottom: 14px;
            }
        }
        /*
        Override intro font size when on first panel
        */
        &.module-start {
            .intro {
                @include fontSize(42px);
                @include lineHeight(50px);
            }
        }

        .panel-options {
            padding: 32px 45px 35px 45px;
        }

        .panel-question {
            position: relative;
            margin: 0 55px;

            p {
                @include fontSize(30px);
                @include lineHeight(38px);
                margin-bottom: 0;
            }

            .back {
                position: absolute;
                left: 0;
                top: 20px;
            }
        }
       
        /*
          Last panel - Boostrap select dropdowns
           */
        .bootstrap-select {

            .dropdown-toggle {
                height: 32px !important;

                .filter-option {
                    @include fontSize(30px);
                    @include lineHeight(40px);
                }
            }
        }
        /**
        *
        *
        * Panel specific styling
        *
        *
         */
        /*
        Location (first) panel
         */
        .panel.location {
            .panel-options a {
                @include SSOption('quarter')
            }
        }
        /*
        Location panel
         */
        .panel.age {
            .panel-options a {
                @include SSOption('third')
            }
        }
        /*
        Membership type panel
         */
        .panel.membership-type {
            .panel-options a {
                @include SSOption()
            }
        }
        /*
        Cover type panel
         */
        .panel.cover-type {
            .panel-options a {
                @include SSOption('third')
            }
        }
        /*
        Final, summary panel
         */
        .panel.statement-summary {

            .panel-question {
                p {
                    @include fontSize(30px);
                    @include lineHeight(38px);
                }

                .back {
                    top: 10px;
                }
            }

            .bootstrap-select {
                line-height: 3;

                .dropdown-toggle {
                    line-height: 3;
                }
            }

            .copy {
                @include fontSize(30px);
                @include lineHeight(40px);
            }

            .cta {
                text-align: center;

                a {
                    width: auto;
                    display: inline-block;
                    padding: 22px 14px;
                }
            }
        }
    }

    @media only screen and (min-width : 903px) {
        .panel-wrapper {
            height: 410px;

            &[data-current-panel='0'] {
                height: 410px;
            }
        }
    }
    // This is to set the height of the 4th question to be the correct height at this size.
    @media only screen and (min-width : 912px) {
        .panel-wrapper {
            &[data-current-panel='3'] {
                height: 350px;
            }
        }
    }
    /*
    992px +
     */
    @media #{"only screen and (min-width : "$screen-md")"} {

        .lg-line-break {
            display: block;
        }
        /**
        *
        *
        * Panel specific styling
        *
        *
         */
        /*
        Panel wrapper - inc. forced heights
         */
        .panel-wrapper {
            height: 280px;

            &[data-current-panel='0'] {
                height: 280px;
            }

            &[data-current-panel='1'] {
                height: 240px;
            }

            &[data-current-panel='2'] {
                height: 240px;
            }

            &[data-current-panel='3'] {
                height: 240px;
            }

            &[data-current-panel='4'] {
                height: 300px;
            }
        }
        /*
        Location (first) panel
         */
        .panel.location {
            .panel-options a {
                @include SSOption('auto')
            }
        }
        /*
        Location panel
         */
        .panel.age {
            .panel-options a {
                @include SSOption('auto')
            }
        }
        /*
        Membership type panel
        */
        .panel.membership-type {
            .panel-options a {
                @include SSOption('auto')
            }
        }
        /*
        Cover type panel
         */
        .panel.cover-type {
            .panel-options a {
                @include SSOption('auto')
            }
        }
        /*
        Final, summary panel
         */
        .panel.statement-summary {

            .panel-options {
                padding: 32px 20px 35px 20px;
            }
        }
    }





    &.type-thin {

        .panel-wrapper {
            height: 500px;

            &[data-current-panel='0'] {
                height: 287px;
            }

            &[data-current-panel='1'] {
                height: 268px;
            }

            &[data-current-panel='2'] {
                height: 322px;
            }

            &[data-current-panel='3'] {
                height: 456px;
            }

            &[data-current-panel='4'] {
                height: 334px;
            }
        }

        .intro-block {
            padding-top: 8px;
            .intro {
                @include fontSize(30px);
                @include lineHeight(37px);
                margin-bottom: 6px;
            }
        }
        .question-small {
            display: none;
        }

        .panel-question {
            p {
                @include fontSize(25px);
                @include lineHeight(30px);
                margin-bottom: 8px;
            }
        }

        /*
    Location panel
     */
        .panel.location {
            .panel-options a {
                @include SSOption('half', 'thin')
            }
        }
        /*
        Location panel
         */
        .panel.age {
            .panel-options a {
                @include SSOption('half', 'thin')
            }
        }
        /*
        Membership type panel
         */
        .panel.membership-type {
            .panel-options a {
                @include SSOption('full', 'thin')
            }
        }
        /*
        Cover type panel
         */
        .panel.cover-type {
            .panel-options a {
                @include SSOption('full', 'thin')
            }
        }
        


        @media #{"only screen and (min-width : 403px)"} {
            .panel-wrapper {
                height: 455px;

                &[data-current-panel='0'] {
                    height: 286px;
                }

                &[data-current-panel='1'] {
                    height: 268px;
                }

                &[data-current-panel='2'] {
                    height: 324px;
                }

                &[data-current-panel='3'] {
                    height: 429px;
                }

                &[data-current-panel='4'] {
                    height: 306px;
                }
            }
        }

        @media #{"only screen and (min-width : 677px)"} {
            .panel-wrapper {
                height: 455px;

                &[data-current-panel='0'] {
                    height: 286px;
                }

                &[data-current-panel='1'] {
                    height: 268px;
                }

                &[data-current-panel='2'] {
                    height: 324px;
                }

                &[data-current-panel='3'] {
                    height: 429px;
                }

                &[data-current-panel='4'] {
                    height: 306px;
                }
            }
        }

        @media #{"only screen and (min-width : "$screen-sm")"} {
            /*
            Panel wrapper - inc. forced heights
             */
            .panel-wrapper {
                height: 410px;

                &[data-current-panel='1'],
                &[data-current-panel='2'],
                &[data-current-panel='3'],
                &[data-current-panel='4'] {
                    .panel-question {
                        padding: 0 70px;
                    }
                }

                &[data-current-panel='0'] {
                    height: 240px;
                }
                
                &[data-current-panel='1'] {
                    height: 240px;
                }

                &[data-current-panel='2'] {
                    height: 240px;
                }

                &[data-current-panel='3'] {
                    height: 240px;
                }

                &[data-current-panel='4'] {
                    height: 230px;
                }
            }
            
            .intro-block {
                padding-top: 20px;
            }
            
            .panel-question {
                .back {
                    top: 5px;
                }
            }

            /*
        Location (first) panel
         */
            .panel.location {
                .panel-options a {
                    @include SSOption('quarter', 'thin');
                }
            }
            /*
            Location panel
             */
            .panel.age {
                .panel-options a {
                    @include SSOption('third', 'thin')
                }
            }
            /*
            Membership type panel
             */
            .panel.membership-type {
                .panel-options a {
                    @include SSOption('half', 'thin')
                }
            }
            /*
            Cover type panel
             */
            .panel.cover-type {
                .panel-options a {
                    @include SSOption('third', 'thin')
                }
            }


            .panel.statement-summary {

                .panel-question {
                    p {
                        @include fontSize(30px);
                        @include lineHeight(38px);
                    }

                    .back {
                        top: 10px;
                    }
                }
                
                .panel-options {
                    padding-bottom: 10px;
                    padding-top: 10px;
                }

                .bootstrap-select {
                    line-height: 3;

                    .dropdown-toggle {
                        line-height: 3;

                        height: 21px !important;

                        .filter-option {
                            @include fontSize(siteSetting($semanticStatement, dropdown_fontSize, 18px));
                            line-height: 1;
                        }
                    }
                }

                .copy {
                    @include fontSize(25px);
                    @include lineHeight(32px);
                }

                .cta {
                    text-align: center;

                    a {
                        width: auto;
                        display: inline-block;
                        padding: 14px 14px;
                    }
                }
            }

            
            
        }

        @media only screen and (min-width : 903px) {
            .panel-wrapper {
                height: 410px;

                &[data-current-panel='0'] {
                    height: 240px;
                }
            }
        }
        // This is to set the height of the 4th question to be the correct height at this size.
        @media only screen and (min-width : 912px) {
            .panel-wrapper {
                &[data-current-panel='3'] {
                    height: 240px;
                }
            }
        }
        /*
        992px +
         */
        @media #{"only screen and (min-width : "$screen-md")"} {
            .panel-wrapper {
                height: 280px;

                &[data-current-panel='0'] {
                    height: 160px;
                }

                &[data-current-panel='1'] {
                    height: 160px;
                }

                &[data-current-panel='2'] {
                    height: 160px;
                }

                &[data-current-panel='3'] {
                    height: 160px;
                }

                &[data-current-panel='4'] {
                    height: 230px;
                }
            }
            
            .panel-options {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            /*
Location (first) panel
 */
            .panel.location {
                .panel-options a {
                    @include SSOption('auto', 'thin')
                }
            }
            /*
            Location panel
             */
            .panel.age {
                .panel-options a {
                    @include SSOption('auto', 'thin')
                }
            }
            /*
            Membership type panel
            */
            .panel.membership-type {
                .panel-options a {
                    @include SSOption('auto', 'thin')
                }
            }
            /*
            Cover type panel
             */
            .panel.cover-type {
                .panel-options a {
                    @include SSOption('auto', 'thin')
                }
            }
        }
    }

    &.type-dropdown {

        .panel-wrapper {
            &[data-current-panel='0'] {
                height: 320px;
            }
        }
        @media #{"only screen and (min-width : "$screen-sm")"} {
            .panel-wrapper {
                &[data-current-panel='0'] {
                    height: 300px;
                }
            }
        }
        @media #{"only screen and (min-width: 903px)"} {
            .panel-wrapper {
                &[data-current-panel='0'] {
                    height: 300px;
                }
            }
        }

        @media #{"only screen and (min-width : "$screen-md")"} {
            .panel-wrapper {
                &[data-current-panel='0'] {
                    height: 275px;
                }
            }
        }
    }

    &.type-thin {
        &.type-dropdown {

            .panel-wrapper {
                &[data-current-panel='0'] {
                    height: 320px;
                }
            }

            @media #{"only screen and (min-width : "$screen-sm")"} {
                .panel-wrapper {
                    &[data-current-panel='0'] {
                        height: 200px;
                    }
                }
            }

            @media #{"only screen and (min-width: 903px)"} {
                .panel-wrapper {
                    &[data-current-panel='0'] {
                        height: 190px;
                    }
                }
            }
            
            @media #{"only screen and (min-width : "$screen-md")"} {
                .panel-wrapper {
                    &[data-current-panel='0'] {
                        height: 185px;
                    }
                }
            }
        }
    }
    
    
}
