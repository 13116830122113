/**
*
* CTA row
* NOTE: This file is extremely minimal. All CTA's use core global CTA styles.
* See	ui-elements/_buttons.scss
**/
.cta-row {

    padding: 50px 35px 40px;

    a {
        margin-bottom: 20px;
    }

    .row {
        :last-child {
            a {
                margin-bottom: 0;
            }
        }
    }

	@media #{$sm} {
		padding-bottom: 90px;
		padding-left: 0;
		padding-right: 0;

		a {
			margin-bottom: 0;
		}
	}
}
