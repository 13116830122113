//Calendar overlay
#ui-datepicker-div { display: none; z-index: 10001; }
.ui-datepicker {
    z-index: 10001 !important;
    width: 304px;
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35);
    padding-bottom: 10px;
    background-color: #eeeeee;

    //Header row - next / prev controls, date - year select
    .ui-datepicker-header {
        width: 100%;
        height: 50px;
        padding: 0px 50px;
        position: relative;
        background-color: #f7f7f7;


        //Controls
        .ui-datepicker-prev,
        .ui-datepicker-next {
            position: absolute;
            display: block;
            width: 50px;
            height: 50px;
            top: 0;
            cursor: pointer;

            span { display: none;}

            &:before {
                @include icon;
                color: black;
                font-size: 14px;
                position: relative;
                left: 17px;
                top: 17px;
            }
        }

        .ui-datepicker-prev {
            left: 0;

            &:before {
                content: "\62";
            }
        }

        .ui-datepicker-next {
            right: 0;

            &:before {
                content: "\63";
            }
        }

        //Title element - contains month + year selects
        .ui-datepicker-title {
            padding: 14px 20px;
            text-align: center;

            .ui-datepicker-month { margin-right: 20px;}

            select {
                @extend %font-primary;
                border: 0;
                background-color: #f7f7f7;
                padding: 0;
                border: 0;
                text-transform: uppercase;
            }
        }
    }

    //Table with calender
    .ui-datepicker-calendar {
        width: 100%;
        background-color: #eeeeee;
        border: none;

        th[scope=col] {
            width: 15%;
            padding: 10px 5px 5px 5px;
            text-align: center;
            vertical-align: middle;

            span {
                @extend %font-secondary-bold;
                @include fontSize(12px);
                text-transform: uppercase;
            }
        }

        th {
            border: none;
        }

        td {
            width: 15%;
            padding: 2px 5px;
            text-align: center;
            vertical-align: middle;
            border: none;

            span {
                @extend %font-secondary;
                @include fontSize(14px);
            }
        }

        a {
            @include fontSize(14px);
            display: inline-block;
            padding: 3px 5px;
            color: #363534;

            &:hover {
                color: $primary_2;
            }

            //Active day
            &.ui-state-active {
                background-color: $primary_2;
                color: white;
            }
        }
    }

    //Disabled options
    .ui-state-disabled {
        span {
            display: inline-block;
            padding: 3px 5px;
            color: #b5b4b0;
        }
    }
}

//Image associated with calender fields
//ONLY applicable when called via plugin
.ui-datepicker-trigger {
    display: block;
    width: 40px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
}
