.offer-popover {
  display: none;
  position: fixed;
  bottom: 80px;
  right: 0;
  z-index: 200000;

  &.show {
    display: flex !important;
    align-items: center;
  }

  .offer-details {
    box-shadow: -1px 0px 2px 0px rgba(0, 0, 0, 0.25);
    border-radius: 7px 0px 0px 7px;
    overflow: hidden;
  }

  .primary-image {
    img {
      width: 110px;
      height: auto;
    }
  }

  .secondary-image {
    display: flex;
    align-items: center;
    .separator {
      text-align: center;
      font-size: 17px;
      font-weight: 700;
      color: $primary_2;
    }
    img {
      width: 88px;
      height: auto;
    }
  }
  
  .offer-close {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $neutral_1;
    border-radius: 7px 0px 0px 7px;
    width: 34px;
    height: 36px;
    cursor: pointer;
    .close-icon {
      width: 13px;
      height: 13px;
    }
  }
  
  .offer-columns {
    display: flex;
    align-items: center;
    flex-direction: row;
    max-height: 75px;
    background: $primary_4;
    justify-content: center;
    padding: 12px;
    overflow: hidden;
    .offer-left {
      position: relative;
      cursor: pointer;
      .trigger {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 1;
      }
    }
    .offer-right {
      display: flex;
      max-width: 0px;
      max-height: unset;
      flex-direction: column;
      overflow: hidden;
      -webkit-transition: max-width 0.3s ease-in-out;
      -moz-transition: max-width 0.3s ease-in-out;
      -o-transition: max-width 0.3s ease-in-out;
    }

    .separator {
      padding: 5px;
    }
    .link {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      background: $primary_2;
      padding: 5px 17px;
      margin: 2px 0;
      border-radius: 5px;
      text-align: center;
      a {
        color: $neutral_white;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }

  .secondary-image {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .bottom-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    line-height: 14px;
    font-weight: 700;
    color: $neutral_white;
    padding: 5px;
    background: $primary_2;
    z-index: 1;
    .text-collapsed,
    .text-expanded {
      text-align: center;
      transition: opacity 0.3s ease-out;
      max-width: 95%;
      opacity: 0;
    }

    .text-collapsed {
      opacity: 1;
    }
  }

  &.expanded {
    .offer-right {
      max-width: 200px;  
      margin: 0 5px;
    }
    
    .bottom-text {
      .text-collapsed {
        opacity: 0;
      }
      .text-expanded {
        opacity: 1;
      }
    }
  }
}

// desktop
@media #{$sm} {
  .offer-popover {
    .primary-image {
      img {
        width: 164px;
        max-width: 164px;
      }
    }

    .offer-columns {
      display: flex;
      flex-direction: column;
      background: $primary_4;
      padding: 12px;
      max-height: unset;
      overflow: hidden;
      .offer-left {
        position: relative;
        cursor: pointer;
        .trigger {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: transparent;
          z-index: 1;
        }
      }
      .offer-right {
        flex-direction: column;
        max-height: 0px;
        overflow: hidden;
        -webkit-transition: max-height 0.3s ease-in-out;
        -moz-transition: max-height 0.3s ease-in-out;
        -o-transition: max-height 0.3s ease-in-out;
        transition: max-height 0.3s ease-in-out;
      }
      
      .offer-right.active {
        max-height: 120px;
      }

      .link {
        margin: 12px 12px 0 12px;
        text-align: center;
        a {
          font-size: 18px;
          color: $neutral_white;
          font-weight: 700;
        }
      }
      .separator {
        padding: 0px 12px;
      }
    }

    .secondary-image {
      flex-direction: column;
      img {
        width: 124px;
        height: auto;
      }
    }

    .bottom-text {
      padding: 5px;
      max-width: 200px;
      .text-collapsed,
      .text-expanded {
        text-align: center;
        transition: opacity 0.3s ease-out;
      }
    }
    
    &.expanded {
      .offer-right {
        max-height: 110px;
      }
    }
  }
}
