.key-terms {
    background: $neutral_4;
    padding: 15px 0 30px 0;

    a {
        color: $primary_2;
        font-size: 1.3rem;
        font-family: "TTLakesCompressed-Bold", Calibri, sans-serif;
        display: flex;
        background: $neutral_white;
        border-radius: 6px;
        box-shadow: 1px 1px 1px rgb(0 0 0 / 30%);
        padding: 10px 20px;
        margin: 0 0 15px 0;
        min-height: 80px;
        align-items: center;
    }

    h2 {
        margin-bottom: 20px;
    }
}
