.home-article-list {


    //Desktop
    @media #{$sm} {
        .col-md-4 {
            .article-tile-featured {
                .img-block {
                    @include aspect-ratio(300, 170);

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    /*END GUIDES*/
}
