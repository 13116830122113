/**
*
* Misc structure + template rules
*
**/
.container,
.container-fluid {
	max-width: $max-content-width;
}

.container-full {
	//max-width: $max-site-width;
	width: 100%;
	margin: auto;
}

#master-content {
	position: relative;
}

.feature-row {
	background-color: $neutral_6;
	position: relative;
	width: 100%;

	&.primary {
		background-color: $neutral_6;
	}
}


//Top row, used in content pages
//Usually contains breadcrumbs, sharing and other utilities
.utility-row {
	@include clearfix-micro();
	padding-top: 26px;
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: solid 1px $neutral_4;

	//Desktop
	@media #{$sm} {
		padding-top: 20px;
		padding-bottom: 15px;
		margin-bottom: 50px;
	}
}

//Two column, basic content page structure
.left-col {

	@media #{$sm} {
		padding-right: 20px;
	}

	.sg-btn, .sg-btn-large, .sg-btn-small {
		margin-bottom: 20px;
	}
}

.basic-content {

	@media #{$sm} {
		margin-top: 8px;
	}
}