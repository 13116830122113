.module-two-column-full-width {
    position: relative;
    margin: 0;

    .content {
        min-height: 392px;
        position: relative;
        text-align: center;
        padding: 32px 0;
    }

    .image {
        margin-bottom: 16px;

        img {
            max-height: 190px;
            width: 100%;
            object-fit: contain;
        }
    }

    .module-title {
        @include fontSize(26px);
        @include lineHeight(32px);
        margin-top: 0;
        margin-bottom: 28px;
        color: $neutral_white;
    }

    .sg-btn {
        background: $neutral_white;
        color: $primary_2;
        border-color: $neutral_white;

        &:visited {
            background-color: $neutral_white;
            border-color: $neutral_white;
            color: $primary_2;
            outline: none;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: darken($neutral_white, 10);
            border-color: darken($neutral_white, 10);
            color: $neutral_white;
            outline: none;
        }

        &:active {
            background-color: darken($neutral_white, 20);
            border-color: darken($neutral_white, 20);
            color: $primary_2;
        }

        &:disabled {
            background-color: $neutral_4;
            border-color: $neutral_4;
            color: $primary_2;
            outline: none;
        }
    }

    &.style-primary2 {
        background: siteSetting($backgroundTextHighlight-primary2, backgroundColor, $primary_2);

        .module-title {
            color: siteSetting($backgroundTextHighlight-primary2, txtColor, $neutral_white);
        }

        .highlight {
            color: siteSetting($backgroundTextHighlight-primary2, txtHighlightColor, $primary_4);
        }

        .sg-btn {
            background-color: $primary_4;
            color: $neutral_white;
            border: 2px solid $primary_4;

            &:visited {
                background-color: $primary_4;
                border-color: $primary_4;
                color: $neutral_white;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: darken($primary_4, 10);
                border-color: darken($primary_4, 10);
                color: $neutral_white;
            }

            &:active {
                background-color: darken($primary_4, 20);
                border-color: darken($primary_4, 20);
                color: $neutral_white;
            }
        }
    }

    &.style-primary3 {
        background: siteSetting($backgroundTextHighlight-primary3, backgroundColor, $primary_3);

        .module-title {
            color: siteSetting($backgroundTextHighlight-primary3, txtColor, $primary_2);
        }

        .highlight {
            color: siteSetting($backgroundTextHighlight-primary3, txtHighlightColor, $neutral_white);
        }
    }

    &.style-primary4 {
        background: siteSetting($backgroundTextHighlight-primary4, backgroundColor, $primary_4);

        .module-title {
            color: siteSetting($backgroundTextHighlight-primary4, txtColor, $neutral_white);
        }

        .highlight {
            color: siteSetting($backgroundTextHighlight-primary4, txtHighlightColor, $primary_2);
        }
    }

    &.style-white {
        background: siteSetting($backgroundTextHighlight-white, backgroundColor, $neutral_white);

        .module-title {
            color: siteSetting($backgroundTextHighlight-white, txtColor, $primary_2);
        }

        .highlight {
            color: siteSetting($backgroundTextHighlight-white, txtHighlightColor, $primary_4);
        }

        .sg-btn {
            background-color: $primary_4;
            color: $neutral_white;
            border: 2px solid $primary_4;

            &:visited {
                background-color: $primary_4;
                border-color: $primary_4;
                color: $neutral_white;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: darken($primary_4, 10);
                border-color: darken($primary_4, 10);
                color: $neutral_white;
            }

            &:active {
                background-color: darken($primary_4, 20);
                border-color: darken($primary_4, 20);
                color: $neutral_white;
            }
        }
    }

    &.button-style-white {
        .sg-btn {
            background: $neutral_white;
            color: $primary_2;
            border-color: $neutral_white;

            &:visited {
                background-color: $neutral_white;
                border-color: $neutral_white;
                color: $primary_2;
                outline: none;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: darken($neutral_white, 10);
                border-color: darken($neutral_white, 10);
                color: $neutral_white;
                outline: none;
            }

            &:active {
                background-color: darken($neutral_white, 20);
                border-color: darken($neutral_white, 20);
                color: $primary_2;
            }

            &:disabled {
                background-color: $neutral_4;
                border-color: $neutral_4;
                color: $primary_2;
                outline: none;
            }
        }
    }

    @media #{$sm} {

        .content {
            min-height: 360px;
            text-align: left;
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
            align-items: center;
        }

        .image,
        .caption {
            width: 50%;
        }

        .image {
            text-align: center;
            padding-right: 30px;
            margin-bottom: 0;
        }

        .caption {
            padding: 0;
            padding-left: 30px;
        }

        &.align-right {
            .content {
                flex-direction: row-reverse;
            }

            .caption {
                padding-left: 0;
                padding-right: 30px;
            }

            .image {
                padding-left: 30px;
                padding-right: 0;
            }
        }
    }
}
