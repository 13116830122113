.module-hospital-product-table {

    h2 {
        @extend %header-x-large;
        color: $primary_4;
        margin-bottom: 14px;

        .tier-pill {
            position: relative;
            top: -6px;
        }
    }

    .legend-block {
        padding-bottom: 45px;
    }

    .content {
        margin-bottom: 38px;

        p:last-child {
            margin-bottom: 0;
        }
    }

    .table-rows {
        border-top: 1px solid $neutral_5;

        .table-row {
            border: 1px solid $neutral_5;
            border-top: 0;

            &:first-child {
                border-top: 1px solid $neutral_5;
            }

            background: $neutral_white;

            &:nth-child(even) {
                background: #FBFBFB;
            }
        }

        .category {
            @include fontSize(16px);
            @include lineHeight(17px);
            text-align: center;
            padding-top: 16px;
            padding-bottom: 16px;
            font-family: $fontPrimary;
            color: $primary_2;

            a {
                color: $primary_2;
                border-bottom: 1px dashed $primary_4;
            }
        }

        .availabilities {
            margin: 0 26px;
            display: flex;
            padding-bottom: 11px;
        }

        .availability {
            flex: 1;
            text-align: center;
            border-right: 1px solid $neutral_5;

            svg {
                height: 13px;
                width: 13px;
            }

            &:last-child {
                border-right: none;
            }

            .availability-label {
                @include fontSize(10px);
                @include lineHeight(17px);
                text-transform: uppercase;
            }
        }

        .availability-notes {
            @include fontSize(12px);
            @include lineHeight(18px);
            border-top: 1px solid $neutral_5;
            padding: 25px 0;
            margin: 0 26px;
        }
    }

    /*
    Optional 'no changes' CMS toggle
     */

    @media #{$uptoSM} {

        &.no-changes {

            .table-row {
                @include clearfix();
                background: $neutral_white;
                border-top: none;
            }

            .category {
                width: calc(100% - 51px);
                float: left;
                padding: 16px 0 16px 12px;
                border-right: 1px solid $neutral_5;
                text-align: left;
            }

            .availabilities {
                display: block;
                width: 51px;
                margin: 0;
                padding: 0;
                float: left;
            }

            .availability {
                padding: 12px 0;
                display: block;
                width: 100%;

                .availability-label {
                    display: none;
                }
            }
        }
    }

    @media #{$sm} {
        .table-rows {
            display: table;
            width: 100%;
            border: 1px solid $neutral_5;
            border-collapse: collapse;

            .table-row {
                display: table-row;

                &:nth-child(even) {
                    background: transparent;
                }

                &:hover {
                    background: #FBFBFB;
                }
            }

            .category,
            .availability,
            .availability-notes {
                display: table-cell;
                border: 1px solid $neutral_5;
            }

            .availabilities {
                display: none;
            }

            .category {
                @include fontSize(18px);
                @include lineHeight(21px);
                text-align: left;
                padding-left: 19px;
                padding-right: 27px;
            }

            .availability {
                width: 9%;
                max-width: 115px;
                border: 0;
                border-right: 1px solid $neutral_5;

                svg {
                    width: 16px;
                    height: 16px;
                }

                &:last-child {
                    border-right: 0;
                }
            }

            .availability-notes {
                @include fontSize(12px);
                @include lineHeight(18px);
                width: 30%;
                padding: 25px 20px;
            }

            .table-row.header {
                .category,
                .availability,
                .availability-notes {
                    @include fontSize(12px);
                    @include lineHeight(17px);
                    text-transform: uppercase;
                    font-weight: 600;
                    color: $neutral_3;
                    padding: 10px;
                }
            }
        }
    }
}
