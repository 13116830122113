/**
*
* Health Hub - Article tile, primary
*
**/
.article-tile-primary {
    width: 100%;
    background-color: #fff;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    color: $primary_2;
    margin-bottom: 40px;

    .img-block {
        width: 100%;
        position: relative;

        img {
            width: 100%;
            border-radius: 20px;
            overflow: hidden;
        }
    }

    .label {
        @include fontSize(10px);
        @include lineHeight(14px);
        display: block;
        position: absolute;
        right: 16px;
        bottom: 16px;
        padding: 8px 10px;
        border-radius: 16px;
        background-color: $primary_2;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
    }

    .label a {
        color: white;
    }

    .content-block {
        padding: 20px 16px;
    }

    .title {
        @extend %font-primary;
        @include fontSize(30px);
        @include lineHeight(32px);
        font-weight: 700;
        margin-bottom: 20px;
    }

    .sub-title {
        @include fontSize(16px);
        @include lineHeight(22px);
        font-weight: 700;
        margin-bottom: 14px;
    }

    .desc {
        @include fontSize(13px);
        @include lineHeight(18px);
        margin-bottom: 20px;
    }

    .content-block a {
        @include btnBordered();
    }

    //Desktop
    @media #{$md} {
        display: flex;
        margin-bottom: 60px;

        .img-block,
        .content-block {
            flex-basis: 50%;
        }

        .label {
            position: absolute;
            right: 18px;
            bottom: 20px;
        }

        .content-block {
            display: flex;
            padding: 0 35px;
            align-items: center;
        }

        .title {
            @include fontSize(35px);
            @include lineHeight(38px); //I've guessed this. Line height in design is wrong
            margin-bottom: 20px;
        }

        .sub-title {
            @include fontSize(18px);
            @include lineHeight(25px);
            margin-bottom: 19px;
        }

        .desc {
            @include fontSize(14px);
            @include lineHeight(20px);
            margin-bottom: 22px;
        }

        .content-block a {
            max-width: 180px;
        }

        .image-clickable {
            all: unset;            
            cursor: pointer;
        }
    }
}
