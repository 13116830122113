
/**
*
* Health Hub - Disclaimer Text section
*
**/
.disclaimer-section {
    display: block;
    margin-top: 40px;

    .text {
        @include fontSize(14px);
        @include lineHeight(19px);
        text-align: center;
    }
}