.module-list-items {
    color: $neutral_2;

    .description {
        margin-bottom: 36px;
    }

    .list-items {
        width: 100%;
    }

    .list-item {
        display: inline-block;
        width: 100%;
        position: relative;

        padding-left: 26px;
        margin-bottom: 18px;

        svg.icon {
            fill: $primary_2;
            width: 18px;
            height: 18px;
            position: absolute;
            top: 2px;
            left: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        p {
            &:last-child{
                margin-bottom: 0;
            }
        }
        
    }

    @media #{$sm} {
        .list-items {
            display: flex;
            flex-flow: row wrap;
        }
        .list-item {
            display: block;
            width: 50%;
            padding-left: 38px;
            padding-right: 40px;

            svg.icon {
                height: 21px;
                width: 21px;
                top: 1px;
            }

            &:nth-last-of-type(-n+2) {
                margin-bottom: 0;
            }

            &:last-child:nth-child(odd) {
                width: 100%;
            }

            &:nth-last-child(2):nth-child(even) {
                margin-bottom: 18px;
            }
        }
    }
}

