/**
*
* Common styles used across comparison tables
* This is shared across
* - extras-cover-table
* - hospital-cover-table
*
**/
.comparison-table {

	.tooltip {
		display: inline;
		svg.icon {
			width: 14px;
			height: 14px;
		}
	}

	/**
	*
	* Primary Category block, inc. accordion basics
	*
	**/
	.primary-category {
		border-bottom: 1px solid white;
	}

	.category-title {
		background-color: $neutral_4;
		padding-left: 10px;
		position: relative;

		p {
			@extend %font-primary;
			@include fontSize(14px);
			display: inline-block;
			max-width: 80%;
			padding: 12px 0 10px 0;
			margin: 0;
			color: $neutral_2;
		}

		//Secondary category title
		&.secondary {
			background-color: white;
			border-style: solid;
			border-color: $neutral_5;
			border-width: 0px 0px 1px 0px;

			p {
				@include fontSize(14px);
			}
		}
	}

	.trigger {
		position: absolute;
		right: 0;
		top: 0;
		display: block;
		width: 46px;
		height: 100%;
		border-left: 1px solid $neutral_5;

		svg.icon {
			@include centre('true','true');
			display: block;
			position: absolute;
			fill: $neutral_2;
			width: 16px;
			height: 16px;
		}

		//State based styles
		&[aria-expanded="true"] svg.icon-open {
			display: none;
		}


		&[aria-expanded="false"] {
			svg.icon-close {
				display: none;
			}

			svg.icon-open {
				display: block;
			}
		}
	}

	//Secondary category triggers
	.category-title.secondary {
		.trigger {
			border-left: none;
		}
	}

	.content {
		border-style: solid;
		border-color: $neutral_5;
		border-width: 0px 1px 0px 1px;
	}


	/**
	*
	* Secondary content block styles
	*
	**/
	.sub-category {
		.content {
			border-style: solid;
			border-color: $neutral_5;
			border-width: 0px 0px 1px 0px;
		}
	}


	//Desktop
	@media #{$sm} {

		.category-title {

			padding-left: 20px;

			p {
				@include fontSize(24px);
			}

			//Secondary category title
			&.secondary {
				p {
					@include fontSize(20px);
				}
			}
		}

		.trigger {
			width: 100px;
		}
	}
}