﻿.form-message {
    padding: 20px;
    background: $neutral_6;
    border: 2px solid $primary_4;
    border-radius: 10px;
    
    .form-message-title {
        margin-bottom: 26px;
        @include font-primary();
        @include fontSize(26px);
        @include lineHeight(28px);
        color: $primary_2;
    }
    
    .form-message-body {
        color: $neutral_black;
    }
}

.daterange .form-message {
    margin-top: 30px;
}