.iframe {

    iframe {
        display: block;
        border: 0;
        width: 100%;
    }

    &.ios {
    	overflow-y: auto;
    	-webkit-overflow-scrolling:touch !important;

    	iframe {
    		min-width: 100%;
		    width: 100px;
    	}
    }
}
