.module-a-feature-slider {
    position: relative;
    background: $neutral_6;
    margin: 0;

    .row {
        position: relative;
    }

    .text-block {
        position: absolute;
        left: 24px;
        bottom: 24px;
        max-width: 304px;
        width: 304px;
        .caption {
            @include fontSize(12px);
            @include lineHeight(16px);
            color: $neutral_white;
        }
    }

    &.reduce-height {
        height: 200px;
        overflow: hidden;

        .slide {
            max-height: 200px;
        }

        .flex-viewport {
            height: 200px;
            overflow: hidden;
        }

        .flex-control-nav {
            bottom: 20px;
        }
        /*Tablet*/
        @media #{"only screen and (min-width : "$screen-sm")"} {
            height: 340px;

            .slide {
                max-height: 340px;
            }

            .flex-viewport {
                height: 340px;
            }

            .flex-control-nav {
                bottom: 70px;
            }

            p {
                @include fontSize(34px);
            }
        }
    }

    @media #{$sm} {
        .semantic-statement {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 20px;
        }
        .text-block {
            position: absolute;
            left: 0;
            bottom: 24px;
            max-width: 304px;
            width: 304px;
            .caption {
                @include fontSize(12px);
                @include lineHeight(16px);
                color: $primary_2;
            }
        }
    }
}
