.popular-searches {
	//Default, mobile first
	display: none;

	p {
		@extend %font-primary;
	}

	.search-item {
		@extend %font-secondary;
	}

	//Desktop
	@media #{$sm} {
		display: block;
		padding: 20px;

		p {
			@include fontSize(16px);
			display: inline-block;
			padding-right: 20px;
			color: $primary_2;
		}

		.search-item {
			@include fontSize(14px);
			display: inline-block;
			padding: 7px;
			color: $neutral_2;
			background-color: $neutral_4;
			margin-right: 2px;
		}
	}
}

