.module-service-tiles {
    margin-left: 24px;
    margin-right: 24px;
    
    
    .tile {
        display: block;
        color: $primary_2;
        position: relative;
        margin: 12px 0;

        svg.icon {
            fill: currentColor;
            width: 44px;
            height: 44px;

            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        .name {
            @extend %font-primary;
            @include fontSize(20px);
            @include lineHeight(26px);
            padding-top: 9px;
            padding-bottom: 9px;
            padding-left: 64px;
        }
    }

    //.row {
    //    &:first-child {
    //        .tile-col:first-child .tile {
    //            margin-top: 0;
    //        }
    //    }
    //
    //    &:last-child {
    //        .tile-col:nth-last-child(-n+1) .tile {
    //            margin-bottom: 0;
    //        }
    //    }
    //}

    @media #{$sm} {
        margin-left: 0;
        margin-right: 0;
        .tile {
            text-align: center;
            padding: 0;
            margin: 24px 0;

            svg.icon {
                position: static;
                top: auto;
                transform: none;
                width: 92px;
                height: 92px;
            }

            .name {
                display: block;
                @include fontSize(26px);
                @include lineHeight(32px);
                padding: 12px 0 0 0;
            }
        }
        .tiles {
            display: flex;
            justify-content: center;
            padding-left: 13%;
            padding-right: 13%;
            .tile-col {
                width: 33%;
            }
            
            &:first-child {
                .tile {
                    margin-top: 0;
                }
            }

            &:last-child {
                .tile {
                    margin-bottom: 0;
                }

                //.tile-col:first-child:nth-last-child(2) {
                //    &, & ~ .tile-col {
                //        &:nth-child(1) {
                //            // @include make-sm-column-offset(2);
                //        }
                //    }
                //}
                //
                //.tile-col:first-child:nth-last-child(1) {
                //    &, & ~ .tile-col {
                //        &:nth-child(1) {
                //            // @include make-sm-column-offset(4);
                //        }
                //    }
                //}
            }
        }
    }
}