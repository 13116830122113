.module-four-column {

    .description {
        margin-bottom: 26px;
    }
    
    @media #{$sm} {
        
    }
}
