/**
*
* Brand name variables
* Used primary for image + asset file pathing
*
**/

$body-bg:               white;

$primary-logo: url('#{$img-url}/logos/logo-primary.svg?ver=#{$img-ver}');

/**
*
* Primary Brand colours
*
**/
$primary_1: #95B3D7;    //ex $citrus
$primary_2: #061D45;    //ex $teal
$primary_3: #793D52;
$primary_4: #2F86C6;
$primary_5: #DA0E1B;    //ex $zest
$primary_6: #cb9dad;    //NEW (Whats this used for?)

/**
*
* Secondary, neutral colours
*
**/
$neutral_white: #FFFFFF;
$neutral_black: #000000;
$neutral_1: #b9b9b9;		//ex $grey
$neutral_2: #505050;        //ex $darkGrey
$neutral_3: #969696;        //ex $secondaryLightGrey
$neutral_4: #eeeeee;        //New
$neutral_5: #dcdcdc;        //New
$neutral_6: #f7f7f7;        //New

//Global textual link color.
$link-color: $primary_4;
$link-decoration: underline;
$link-hover-color: $primary_4;
$link-hover-decoration: none;

//Misc
$error: #ef3e42;
$errorLight: #fdebec;
$border-colour: #dcdcdc;
$warning: #ef3e42;      //ex $red
$coverTableHighlight: #e5f7f5;

/**
 * Inline Alert Variables
 */
$inline-alert-background: $primary_1;
$inline-alert-color: $neutral_2;

/**
*
* Live quote branding
*
**/
$lqt-colour-black: #505050;
$lqt-colour-quote-panel-labels: #969696;
$lqt-colour-quote-panel-values: $lqt-colour-black;
$lqt-colour-quote-panel-divider: #dcdcdc;
$lqt-colour-btn-join: $primary_4;
$lqt-colour-dropdown-options-bg: #F7F7F7;
$lqt-colour-dropdown-options-selected-border: #EEEEEE;
$lqt-colour-hide-cover-details: #1f2a44;
$lqt-colour-border: #EEEEEE;
$lqt-colour-tab-inactive: #DCDCDC;
$lqt-colour-tab-active: #FFFFFF;
$lqt-colour-product-selector-heading: #020202;
$lqt-colour-modal-icon: #1f2a44;
$lqt-colour-tab-border: $lqt-colour-tab-inactive;
$lqt-colour-dropdown-options-selected-bg: $lqt-colour-quote-panel-values;
$lqt-colour-compare-btn-border: $lqt-colour-tab-inactive;
$lqt-colour-compare-group-bg: $lqt-colour-dropdown-options-selected-border;
$lqt-colour-pagination-inactive-bg: #eeeeee;
$lqt-colour-pagination-inactive: #dcdcdc;
$lqt-colour-success: #2F86C6;


/**
*
* Typography
*
**/
//Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:  'Open Sans', Calibri, sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;

//Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

$font-size-base:          16px;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~12px

$font-size-h1:            floor(($font-size-base * 2.6)); // ~36px
$font-size-h2:            floor(($font-size-base * 2.15)); // ~30px
$font-size-h3:            ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil(($font-size-base * 0.85)); // ~12px

//Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429; // 20/14

//Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~20px

//By default, this inherits from the `<body>`.
$headings-font-family:    inherit;
$headings-font-weight:    500;
$headings-line-height:    1.1;
$headings-color:          inherit;

/**
*
* Icon Font
*
**/
@font-face {
    font-family: 'THF-icon';
    src: url($static-url + '/fonts/THF-icon.eot');
    src: url($static-url + '/fonts/THF-icon.eot?#iefix') format("embedded-opentype"),
    url($static-url + '/fonts/THF-icon.woff') format("woff"),
    url($static-url + '/fonts/THF-icon.ttf') format("truetype"),
    url($static-url + '/fonts/THF-icon.svg#THF-icon') format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/**
*
* Brand fonts
*
**/
@font-face {
    font-family: 'tt_lakesmedium';
    src: url($font-url + 'tt_lakes_medium-webfont.eot');
    src: url($font-url + 'tt_lakes_medium-webfont.eot?#iefix') format('embedded-opentype'),
    url($font-url + 'tt_lakes_medium-webfont.woff2') format('woff2'),
    url($font-url + 'tt_lakes_medium-webfont.woff') format('woff'),
    url($font-url + 'tt_lakes_medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@import url("//hello.myfonts.net/count/327e09");

@font-face {
    font-family: 'TTLakesCompressed-Bold';
    src: url($font-url + 'TTLakesCompressed-bold.eot');
    src: url($font-url + 'TTLakesCompressed-bold.eot?#iefix') format('embedded-opentype'),
    url($font-url + 'TTLakesCompressed-bold.woff2') format('woff2'),
    url($font-url + 'TTLakesCompressed-bold.woff') format('woff'),
    url($font-url + 'TTLakesCompressed-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
        font-family: 'TTLakesCompressed-Regular';
        src: url($font-url + 'TTLakesCompressed-Regular.eot');
        src: url($font-url + 'TTLakesCompressed-Regular.eot?#iefix') format('embedded-opentype'),
        url($font-url + 'TTLakesCompressed-Regular.woff2') format('woff2'),
        url($font-url + 'TTLakesCompressed-Regular.woff') format('woff'),
        url($font-url + 'TTLakesCompressed-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
}
/**
*
* Brand font variables
*
**/
$fontPrimary: 'TTLakesCompressed-Bold', Calibri, sans-serif;
$fontPrimary_regular: 'TTLakesCompressed-Regular', Calibri, sans-serif;
$fontPrimary_light: 'tt_lakesmedium', Calibri, sans-serif;
$fontSecondary: 'Open Sans', sans-serif;


/**
*
* Component specific variables
*
**/

$globalBtns: (
        iconPosY: -1px
);

$globalSearch: (
        triggerPos_Y: 95px
);

$primaryNav: (
        fontSize: 24px
);

$aFeature: (
        fontSize: 52px
);

$userStories_intro: (
        header_fontSize: 36px,
);

$userStories_slider: (
        userDetails_fontSize: 36px,
        coverTypeBlock_fontSize: 22px,
        getQuoteCTA_fontSize: 22px,
        getQuoteCTA_lineHeight: 79px
);

$serviceTile: (
        title_fontSize: 36px,
        cta_fontSize: 22px
);

$semanticStatement: (
    //Messaging font, eg 'Get started', and 'I live in'
        msg_fontSize_mobile: 24px,
        msg_padding_top: 0px,

    //Get Started button / CTA
        getStartedBtn_padding: 22px 0 23px 20px,
        getStartedBtn_caret_posY: 3px,

    //Get Quote button / CTA
        getQuoteBtn_padding: 19px 0 20px,

    //Default, initial options
        option_fontSize_mobile: 18px,
        option_fontSize_default: 25px,
        option_paddingTop: 0px,

    //Summary screen, dropdowns
        dropdown_fontSize: 23px,
        dropdown_posY: 8px,
        dropdown_caret_posY: 8px,
        summary_fontSize: 23px
);

$header: (
        logoWidth_mobile: 88px,
        logoHeight_mobile: 54px,

        logoWidth_desktop: 100%,
        logoHeight_desktop: 94px,

        logoColumnWidth_mobile: 25%,
        logoColumnWidth_desktop: 20%,

        logoMarginTop_mobile: 13px,
        logoMarginTop_desktop: 23px,
        
        navColumnWidth_desktop: 80%
);

$footer: (
        logoMaxWidth_mobile: 148px,
        logoMaxHeight_mobile: 59px,
        logoWidth_mobile: 134px,
        logoHeight_mobile: 70px,
        
        logoMaxWidth_desktop: 148px,
        logoMaxHeight_desktop: 59px,
        logoWidth_desktop: 134px,
        logoHeight_desktop: 70px,
        
        logoMembersOwnWidth_mobile: 52px,
        logoMembersOwnHeight_mobile: 59px,
        
        logoMembersOwnWidth_desktop: 52px,
        logoMembersOwnHeight_desktop: 59px,
);

$quotePanel: (
        value_fontSize_mobile: 14px,
        value_fontSize_desktop: 19px
);

$leftNav: (
        link_fontSize: 20px,
        link_padding: 13px 20px 15px 10px,
);

$splitPathwayModule: (
        nonmember_bg: $primary_2,
        nonmember_txt: #fff,
        member_bg: $primary_3,
        member_txt: #fff
);

$backgroundTextHighlight-primary2: (
        backgroundColor: $primary_2,
        txtColor: $neutral_white,
        txtHighlightColor: $primary_4
);

$backgroundTextHighlight-primary3: (
        backgroundColor: $primary_3,
        txtColor: $neutral_white,
        txtHighlightColor: $primary_2
);

$backgroundTextHighlight-primary4: (
        backgroundColor: $primary_4,
        txtColor: $neutral_white,
        txtHighlightColor: $primary_2
);


$backgroundTextHighlight-white: (
        backgroundColor: $neutral_white,
        txtColor: $primary_2,
        txtHighlightColor: $primary_5
);

/*
CTA banner mapping
https://deepend.atlassian.net/browse/THFM-829
Note: I've only mapped those colours that need to be overriden
These maps don't contain ALL module variations
 */
$bannerCTA-primary2: (
        btnColor: $primary_4
);

$bannerCTA-primary3: (
        txtColor: $neutral_white,
        btnSecondaryTxtColor: $neutral_white,
        btnSecondaryTxtColor_hover: $primary_2,
        btnSecondaryBorderColor: $neutral_white
);