
/*
NEW search dropdown
 */

.search-desktop {
    @include animate-custom(#{'opacity'},350ms);
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 199;
    pointer-events: none;
    opacity: 0;

    .btn-close-search {
        @include fontSize(18px);
        @extend %font-primary;
        display: block;
        float: right;
        height: 49px;
        width: 120px;
        padding: 12px 10px;
        margin-left: 4px;
        position: relative;
        z-index: 199;
        background: white;
        border: none;
        text-decoration: none;
        color: black;
        text-align: center;
        outline: none;

        span {
            display: inline-block;
            position: relative;
            top: -2px;
        }

        svg.icon {
            height: 14px;
            width: 14px;
            position: relative;
            top: -1px;
            margin-right: 4px;
            color: black;
        }
    }

    .search-tools {
        padding: 110px 110px 82px 110px;
        background: white;
        position: relative;
        clear: both;
        z-index: 199;
    }

    .title {
        @extend %font-primary;
        @include fontSize(36px);
        @include lineHeight(48px);
        margin-bottom: 80px;
        color: $primary_2;
        text-align: center;
    }

    .input-wrap {
        height: 50px;
        width: 100%;
        position: relative;
        border-bottom: solid 2px $primary_4;

        input {
            @include fontSize(16px);
            @include lineHeight(24px);
            width: 80%;
            height: 100%;
            border: 0;
            padding: 0;

            color: $neutral_2;

            &::-webkit-input-placeholder {
                color: $neutral_2;
                font-style: normal;
            }

            &:-moz-placeholder { /* Firefox 18- */
                color: $neutral_2;
                font-style: normal;
            }

            &::-moz-placeholder {  /* Firefox 19+ */
                color: $neutral_2;
                font-style: normal;
            }

            &:-ms-input-placeholder {
                color: $neutral_2;
                font-style: normal;
            }

            &:focus {
                background: none;
                border: none;
            }
        }
    }

    .btn-search-submit {
        display: block;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 19px;
        top: 2px;
        background-color: transparent;
        padding: 0;
        border: none;
        color: black;

        .icon {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
        }

        &:hover {
            color: $primary_4;
        }
    }

    .search-tint {
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 99;
        left: 0;
        top: 0;
        background-color: rgba(0,0,0,0.2);
    }

    &.search-open {
        opacity: 1;
        pointer-events: all;
    }
}