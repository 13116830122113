$module-feature-max-height: 500px;
.module-feature-cta {
    position: relative;
    margin: 0;

    .sg-btn,
    .sg-btn:visited {
        white-space: nowrap;
        color: $primary_2;
    }

    .background {
        display: block;
        width: 100%;
        max-height: $module-feature-max-height;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
        }

        &.darken:after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.65;
        }

        &.darken.primary1:after {
            background: $primary_1;
        }

        &.darken.primary2:after {
            background: $primary_2;
        }

        &.darken.primary3:after {
            background: $primary_3;
        }
    }

    .caption {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .copy {
            @include centre('true', 'true', 'absolute');
            text-align: center;
        }

        .title {
            color: $neutral_white;
            font-size: 30px;
            line-height: 32px;
            margin: 0;
            margin-bottom: 15px;
            text-align: center;
        }
    }

    @media #{$sm} {
        .caption {
            .title {
                font-size: 68px;
                line-height: 80px;
            }
        }
    }
}
