.module-bullet-list {
    color: $neutral_2;

    h2.title {
        margin-bottom: 8px;
    }

    .description {
        margin-bottom: 16px;
    }

    ul {
        padding-left: 20px;
        margin-bottom: 0;
        li {
            margin-bottom: 8px;

            p {
                &:last-child{
                    margin-bottom: 0;
                }
            }
            
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

