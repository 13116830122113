.module {
    @include fontSize(16px);
    @include lineHeight(24px);

    h2 {
        @extend %header-x-large
    }

    margin: 64px 0;
    
    //border: 1px dashed deeppink;

    .title {
        text-align: left;
        margin-bottom: 26px;
    }

    //&.attach {
    //    margin-top: -64px;
    //    &:first-child {
    //        margin-top: 0;
    //    }
    //}

    .modular-campaign-pages & {
        margin: 32px 0;
    }

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
    
    //CMS margin stylings
    &.margin-top {
        margin-top: 64px;
        margin-bottom: 0;
    }

    &.margin-bottom {
        margin-top: 0;
        margin-bottom: 64px;
    }

    &.no-margin {
        margin: 0 auto;
    }
}