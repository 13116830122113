.module-image-promo-with-caption {
    .tile {
        @include make-xs-column(12, 24px);
        display: block;
        position: relative;
        margin-top: 16px;
        overflow: hidden;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .tile-image-content {
            .image-block {
                display: block;
                position: relative;
                height: 100%;
    
                picture {
                    display: block;
                    height: 100%;
                }
    
                img {
                    width: 100%;
                    height: auto;
                }
    
                &:after {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
    
                    transition: opacity 250ms;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0.85%, rgba(0, 0, 0, 1) 100%);
                    opacity: 0.3;
                }
            }
    
            &:hover {
                .image-block:after {
                    opacity: 0.5;
                }
            }
    
            .content-block {
                display: block;
                position: relative;
                color: $neutral_white;
    
                .title-copy {
                    display: block;
                    position: absolute;
                    bottom: 16px;
                    left: 24px;
                    width: 75%;
                    @extend %header-medium;
                }
    
                svg.icon {
                    position: absolute;
                    bottom: 16px;
                    right: 28px;
                    height: 18px;
                    width: 10px;
                }
            }
        }
        .caption-block {
            display: block;
            padding: 16px 27px;
            padding-bottom: 0;
            background: $neutral_white;
            .text {
                color: $neutral_1;
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                font-family: 'Open Sans', sans-serif;
            }
        }
    }

    @media #{$sm} {

        //.tile-container {
        //    .text-block {
        //        padding: 16px 39px;
        //    }
        //}

        .tiles {
            @include clearfix-micro;
        }
        
        .tile {
            display: block;
            float: left;
            margin: 24px 0;
            @include make-sm-column(12, 24px);

            .image-block {
                display: block;
            }

            &:nth-last-of-type(n+1) {
                margin-top: 0;
            }

            &:nth-last-of-type(-n+1) {
                margin-bottom: 0;
            }
        }
    }
}