/**
*
* User story module
* In use on user stories page
* NOTE: This is different from user-stories, which
* lists multpile stories, in synopsis form, in a slider
*
**/
.user-story {
    //Overview section
    .user-story-overview {
        margin-bottom: 50px;
        @include clearfix-micro();
    }

    h1 {
        @extend %font-primary;
        @include fontSizeFluid(36px);
        margin: 0;
        padding: 0;
        color: $primary_2;
    }

    p {
        @extend %font-secondary;
        @include fontSizeFluid(18px);
        margin: 0;
        padding: 0;
        color: $neutral_2;
    }

    .user-cover-details {
        @include clearfix-micro();
        text-align: center;
        margin-bottom: 40px;

        background-color: $neutral_6;
        padding-top: 45px;
        padding-bottom: 45px;

        .cover-name {
            @extend %header-x-large;
            margin-bottom: 20px;
        }

        .cover-separator {
            margin-bottom: 10px;
            svg.icon {
                height: 20px;
                width: 20px;
            }
        }
        @media #{$sm} {
            display: flex;
            align-items: center;
            justify-content: center;

            .cover-name {
                margin-bottom: 0;
                padding: 10px 40px;
            }

            .cover-separator {
                margin-bottom: 0;
                svg.icon {
                    height: 25px;
                    width: 25px;
                }
            }
            .buttons {
                width: 30%;
            }
        }

    }

    //Desktop
    @media #{$sm} {
        //Overview section
        .user-story-overview {
            margin-bottom: 78px;
        }
    }
}
