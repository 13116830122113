/**
*
* Split Pathway module
*
**/
.module-split-pathways {
    width: 100%;
    overflow: hidden;
    margin: 0;

    > .container-fluid {
        height: 100%;
        padding: 0;
    }

    .pathway-tile {
        width: 100%;
        height: 300px;
        position: relative;
    }

    .inner {
        width: 100%;
        height: 100%;
        padding: 60px 30px 40px 30px;
        position: relative;
        z-index: 5;
        text-align: center;
    }

    h2 {
        @include fontSize(35px);
        @include lineHeight(40px);
        position: relative;
        z-index: 10;
        color: inherit;
        margin-bottom: 23px;


        span {
            @include fontSize(26px);
            @include lineHeight(30px);
            display: block;
            margin-top: 5px;
        }
    }

    .sg-btn {
        position: relative;
        z-index: 10;

        &:hover {
            background-color: $neutral_4;
        }
    }
    /*
    Default tile bg and button colours
     */
    .tile-member {
        background-color: (siteSetting($splitPathwayModule, member_bg, $primary_4));
        color: (siteSetting($splitPathwayModule, member_txt, 'white'));

        .sg-btn {
            color: $primary_2;
        }

        &.background-primary1 {
            background-color: $primary_1;
        }

        &.background-primary2 {
            background-color: $primary_2;
        }

        &.background-primary3 {
            background-color: $primary_3;
        }

        &.background-primary4 {
            background-color: $primary_4;
        }

        &.background-primary5 {
            background-color: $primary_5;
        }
    }

    .tile-non-member {
        background-color: (siteSetting($splitPathwayModule, nonmember_bg, $primary_2));
        color: (siteSetting($splitPathwayModule, nonmember_txt, 'white'));

        .sg-btn {
            color: $primary_2;
        }
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            width: 14px;
            height: 14px;
            background: white;

            &.swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }
    //Tablet
    @media #{"only screen and (min-width : "$screen-sm")"} {

        > .container-fluid {
            padding-left: $grid-gutter-width / 2;
            padding-right: $grid-gutter-width / 2;
        }

        .swiper-container {
            overflow: visible;
        }

        .pathway-tile {
            width: 50%;
            height: 430px;
            float: left;

            .inner {
                padding: 130px 50px 0 50px;
                text-align: left;
            }
            /*
            Psuedo elements to extend brand colours outside module container
             */
            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                width: 2000%;
                height: 100%;
            }
            /*
            Tile position specific positioning
             */
            &:first-child {

                &:before {
                    left: -1000%;
                    top: 0;
                }
            }

            &:last-child {

                &:before {
                    left: 0;
                    top: 0;
                }
            }
            /*
            Tile 'type' specific bg colours -- DEFAULT
             */
            &.tile-member {
                &:before {
                    background-color: (siteSetting($splitPathwayModule, member_bg, $primary_4));
                }

                &.background-primary1 {
                    &:before {
                        background-color: $primary_1;
                    }
                }

                &.background-primary2 {
                    &:before {
                        background-color: $primary_2;
                    }
                }

                &.background-primary3 {
                    &:before {
                        background-color: $primary_3;
                    }
                }

                &.background-primary4 {
                    &:before {
                        background-color: $primary_4;
                    }
                }

                &.background-primary5 {
                    &:before {
                        background-color: $primary_5;
                    }
                }
            }

            &.tile-non-member {
                &:before {
                    background-color: (siteSetting($splitPathwayModule, nonmember_bg, $primary_2));
                }
            }
        }


        h2 {
            @include fontSize(35px);
            @include lineHeight(40px);
            color: inherit;
            margin-bottom: 23px;

            span {
                @include fontSize(26px);
                @include lineHeight(30px);
                display: block;
            }
        }
    }

    @media #{$md} {
        .pathway-tile {
            height: 360px;

            &:first-child {
                .inner {
                    padding: 77px 60px 0 0;
                }
            }

            &:last-child {
                .inner {
                    padding: 77px 0 0 60px;
                }
            }
        }

        h2 {
            @include fontSize(52px);
            @include lineHeight(52px);
            margin-bottom: 32px;

            span {
                @include fontSize(36px);
                @include lineHeight(40px);
                margin-top: 14px;
            }
        }
    }
}