.icon-cta {
    h2.title {
        margin-bottom: 14px;
    }

    .icon-wrap {
        margin-bottom: 14px;

        svg {
            width: 38px;
            height: 38px;
        }
    }

    @media #{$sm} {
        h2.title {
            @include fontSize(26px);
            @include lineHeight(32px);
            margin-top: 16px;
            margin-bottom: 16px;
        }
        .icon-wrap {
            svg {
                width: 64px;
                height: 64px;
            }
        }

        .link {
            margin-top: 20px;
        }
    }
}