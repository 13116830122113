/**
*
* Global Alert
*
**/
.global-alert {

	position: relative;
	display: none; // Hidden by default
	overflow: hidden;
	border-bottom: solid 1px white;

	.inner {
		position: relative;
		padding-top: 18px;
		padding-bottom: 18px;
		padding-left: 50px;
        padding-right: 10px;
	}

	.message p {
		@include fontSize(14px);
        @include lineHeight(18px);
        margin-bottom: 10px;

        &:first-child {
            @include lineHeight(30px);
            margin-bottom: 3px;
        }

        &:last-child {
            margin-bottom: 0;
        }
	}

    .message a {
        color: $neutral_white;
        text-decoration: underline;

    }

    .warning-indicator {
        position: absolute;
        left: 13px;
        top: 25px;

        .icon {
            width: 18px;
            height: 18px;
            color: $neutral_white;
        }
    }

	.btn-close {
        @extend %font-primary-light;
        @include fontSize(14px);
        @include lineHeight(18px);
        position: relative;
        color: $neutral_white;

        .icon {
            position: relative;
            top: 4px;
            left: 0;
            width: 18px;
            height: 18px;
            margin-right: 4px;
        }

        .txt {
            text-decoration: underline;
        }
	}


	//Variants
	&.standard {
		background-color: $primary_4;

		.message {
			color: white;
		}
	}

	&.warning {
		background-color: $warning;

		.message {
			color: white;
		}
	}

    //Desktop
    @media #{$sm} {

        .inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 20px;
            padding-left: 0;
            padding-right: 0;
        }

        .warning-indicator {
            position: absolute;
            left: 0;
            top: 24px;
        }

        .message {
            flex-basis: 80%;

            p {
                margin: 0;
            }

            p:first-child {
                padding-left: 28px;
            }
            
            p:last-child {
                margin-bottom: 0;
            }
        }

        .btn-close {
            margin-left: auto;
            // margin-top: 28px;
        }
    }
}
