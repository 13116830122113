.module-job-adder {
    .ja-jobs-widget {
        .ja-job-search {
            background: $primary_4;
            padding: 30px;

            .ja-field-container {
                margin-top: 10px;

                &:first-child {
                    margin-top: 0;
                }

                label {
                    color: $neutral_white;
                }

                select {
                    width: 100%;
                    padding: 10px;

                    & option[value="0"] {
                        font-weight: bold;
                        color: $neutral_1;
                    }
                }
            }
        }

        .ja-submit {
            margin-top: 30px;
            text-align: center;

            input[type="button"] {
                @extend .sg-btn;
                @extend .sg-btn-white;
                width: 50%;
            }
        }


        .ja-job-list-container {
            margin-top: 30px;

            .job {
                background: $neutral_6;
                padding: 20px;
                margin-top: 20px;

                &:first-child {
                    margin-top: 0;
                }

                h2 {
                    @include fontSize(26px);
                    @include lineHeight(32px);
                    margin-bottom: 12px;
                    margin-top: 0;
                    font-family: $fontPrimary;
                    color: $primary_2;
                }

                .meta {
                    display: flex;
                    justify-content: space-between;
                    font-weight: bold;
                    margin-bottom: 10px;

                    .classifications {
                        width: 80%;
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        li {
                            margin: 0;
                            padding: 0;
                            display: inline-block;

                            &::after {
                                content: '|';
                            }

                            &:last-child::after {
                                content: '';
                            }
                        }
                    }

                    p.date-posted {
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }

        .ja-job-details {
            .meta {
                display: flex;
                justify-content: space-between;
                font-weight: bold;
                margin-bottom: 10px;

                .classifications {
                    width: 80%;
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        margin: 0;
                        padding: 0;
                        display: inline-block;

                        &::after {
                            content: '|';
                        }

                        &:last-child::after {
                            content: '';
                        }
                    }
                }

                p.date-posted {
                    padding: 0;
                    margin: 0;
                }
            }

            .apply {
                text-align: center;
                margin-top: 30px;
                padding: 30px;
                background: $neutral_6;

                .back-link {
                    display: inline-block;
                    margin-bottom: 20px;

                }

                input[type="button"] {
                    @extend .sg-btn;
                }
            }
        }
    }

    /* Powered by JobAdder */
    .powered-by-jobadder {
        color: #bbb;
        font-size: 0.9em;
        text-align: center;
        margin-top: 2em;
    }

    .powered-by-jobadder a {
        color: #bbb;
    }

    .powered-by-jobadder a:hover {
        color: #aaa;
    }

    /* End powered by JobAdder */

    @media #{$sm} {
        
        .ja-jobs-widget {
            display: flex;
            flex-wrap: wrap;

            .ja-job-search {
                width: 30%;
            }

            .ja-submit {
                input[type="button"] {
                    width: 100%;
                }
            }

            .ja-job-list-container {
                width: 70%;
                padding-left: 20px;

                margin-top: 0;
            }

            br {
                flex-basis: 100%;
                height: 0;
            }

            .powered-by-jobadder {
                margin-top: 5px;
                width: 100%;
            }

            .ja-job-details {
                .apply {
                    .back-link {
                        margin-right: 20px;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
