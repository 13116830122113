/**
*
* Breadcrumb navigation
*
**/
.breadcrumbs {
	padding-top: 8px;

	ul,li {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	li {
		display: inline-block;
		position: relative;
		padding-right: 12px;

		svg.icon {
			right: -5px;
			position: relative;
		}

		&:last-child {
			svg.icon {
				display: none;
			}
		}
	}

	a {
		@extend %font-secondary;
		@include fontSize(14px);
		color: $neutral_2;
		text-decoration: none;

		&.current-page {
			color: $primary_4;
		}

		&:hover {
			color: $primary_4;
			text-decoration: underline;
		}
	}
}
