.module-hospital-feature {
    @extend .module-feature;

    h1 {
        .tier-pill {
            @include fontSize(10px);
            position: relative;
            top: -4px;
        }
    }

    @media #{$sm} {

        h1 {
            .tier-pill {
                @include fontSize(14px);
                
                padding: 3px 5px;
                position: relative;
                top: -15px;
                border-radius: 20px;
            }
        }

    }
}
