/**
*
* Updated 2017 SVG icon updates
*
**/
.icon {
    fill: currentColor;
    height: 0.75em;
    width: 0.75em;
}

svg.icon {
    &.blue {
        color: $primary_2;
    }

    &.grey {
        color: $neutral_3;
    }

    &.teal {
        color: $primary_4;
    }
}

//TODO: Delete all the following AFTER icons have been updated sitewide

/**
*
* Icon font base styles and character mapping
*
**/

//Icon to include the following font snippets
@mixin icon() {
    font-family: "THF-icon" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[data-icon]:before {
    font-family: "THF-icon" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "THF-icon" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*
    We are using fontastic font to generate the icon. Read Fontastic Icon.docx file added in the Solutions Items folder.
*/
.icon-instagram:before {
    content: "\38";
}

.icon-insta:before {
    content: "\39";
}

.icon-youtube2:before {
    content: "\""
}

.icon-arrow-down:before {
    content: "\61";
}

.icon-arrow-left:before {
    content: "\62";
}

.icon-arrow-right:before {
    content: "\63";
}

.icon-arrow-up:before {
    content: "\64";
}

.icon-tick:before {
    content: "\65";
}

.icon-call:before {
    content: "\66";
}

.icon-car:before {
    content: "\67";
}

.icon-centre:before {
    content: "\68";
}

.icon-claim:before {
    content: "\69";
}

.icon-close:before {
    content: "\6a";
}

.icon-couple:before {
    content: "\6b";
}

.icon-exit:before {
    content: "\6c";
}

.icon-date:before {
    content: "\6d";
}

.icon-detail:before {
    content: "\6e";
}

.icon-email:before {
    content: "\6f";
}

.icon-facebook:before {
    content: "\71";
}

.icon-family:before {
    content: "\72";
}

.icon-home:before {
    content: "\73";
}

.icon-hospital:before {
    content: "\74";
}

.icon-international:before {
    content: "\75";
}

.icon-join:before {
    content: "\76";
}

.icon-linkedin:before {
    content: "\77";
}

.icon-mail:before {
    content: "\78";
}

.icon-member:before {
    content: "\79";
}

.icon-menu:before {
    content: "\7a";
}

.icon-news:before {
    content: "\41";
}

.icon-offer:before {
    content: "\42";
}

.icon-open:before {
    content: "\43";
}

.icon-payment:before {
    content: "\44";
}

.icon-print:before {
    content: "\45";
}

.icon-quote:before {
    content: "\46";
}

.icon-search:before {
    content: "\47";
}

.icon-share:before {
    content: "\48";
}

.icon-single:before {
    content: "\49";
}

.icon-singleparent:before {
    content: "\4a";
}

.icon-switch:before {
    content: "\4b";
}

.icon-time:before {
    content: "\4d";
}

.icon-travel:before {
    content: "\4f";
}

.icon-website:before {
    content: "\52";
}

.icon-youtube:before {
    content: "\53";
}

.icon-add:before {
    content: "\54";
}

.icon-add-alternate:before {
    content: "\55";
}

.icon-added:before {
    content: "\56";
}

.icon-caret-down:before {
    content: "\40";
}

.icon-caret-left:before {
    content: "\57";
}

.icon-caret-right:before {
    content: "\58";
}

.icon-caret-up:before {
    content: "\59";
}

.icon-checkbox:before {
    content: "\5a";
}

.icon-checkbox-active:before {
    content: "\30";
}

.icon-marker:before {
    content: "\33";
}

.icon-radiobutton:before {
    content: "\34";
}

.icon-radiobutton-active:before {
    content: "\35";
}

.icon-restricted:before {
    content: "\36";
}

.icon-edit:before {
    content: "\70";
}

.icon-warning:before {
    content: "\4c";
}

.icon-and:before {
    content: "\21";
}

.icon-tooltip:before {
    content: "\4e";
}

.icon-cooling-off:before {
    content: "\31";
}

.icon-tooltipincircle:before {
    content: "\38";
}

.icon-viewminus:before {
    content: "\50";
}

.icon-viewplus:before {
    content: "\51";
}

.icon-info:before {
    content: "\32";
}


