@mixin loading-panel() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20000;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    opacity: 0.95;
    z-index: 20001;
  }

  .content {
    @include centre( 'true', 'true', 'absolute' );
    z-index: 20002;
    color: white;
  }
}

.loader {
  font-size: 20px;
  position: relative;
  width: 4em;
  height: 1em;
  margin: auto;
  @include centre( 'true', 'true', 'absolute' );

  .dot {
    width: 1em;
    height: 1em;
    border-radius: 0.5em;

    position: absolute;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    background: $neutral_3;
  }

  .dot1, .dot2 {
    left: 0;
  }

  .dot3 {
    left: 1.5em;
  }

  .dot4 {
    left: 3em;
  }

  @keyframes reveal {
    from {
      transform: scale(0.001);
    }

    to {
      transform: scale(1);
    }
  }

  @keyframes slide {
    to {
      transform: translateX(1.5em);
    }
  }


  .dot1 {
    animation-name: reveal;
  }

  .dot2, .dot3 {
    animation-name: slide;
  }

  .dot4 {
    animation-name: reveal;
    animation-direction: reverse; /* thx @HugoGiraudel */
  }
}

.loader-field {
  font-size: 6px;
  position: relative;
  width: 4em;
  height: 1em;
  margin: auto;
  @include centre( 'true', 'true', 'absolute' );

  .dot {
    width: 1em;
    height: 1em;
    border-radius: 0.5em;

    position: absolute;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    background: $neutral_3;
  }

  .dot1, .dot2 {
    left: 0;
  }

  .dot3 {
    left: 1.5em;
  }

  .dot4 {
    left: 3em;
  }

  @keyframes reveal {
    from {
      transform: scale(0.001);
    }

    to {
      transform: scale(1);
    }
  }

  @keyframes slide {
    to {
      transform: translateX(1.5em);
    }
  }


  .dot1 {
    animation-name: reveal;
  }

  .dot2, .dot3 {
    animation-name: slide;
  }

  .dot4 {
    animation-name: reveal;
    animation-direction: reverse; /* thx @HugoGiraudel */
  }
}
