.live-quote-tool {

    .print-email {
        display: none;
    }

    .loading-panel.global {
        @include loading-panel();
        position: fixed;

        .content {
            .copy {
                top: 0;
                @include fontSize(31px);
                @include font-primary();
                color: $primary_4;
                text-align: center;
            }

            .loader {
                top: 151px;
            }
        }
    }

    .top-section {
        background: $neutral_6;
    }

    .lower-modules {
        padding-top: 20px;
    }

    .section-title {
        text-align: center;
        padding: 0;

        h1 {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        h1, h3 {
            @extend %font-primary;
            @include fontSize(26px);
            @include lineHeight(30px);
            position: relative;
            color: $primary_4;
        }
    }
    /** Sticky Quote Panel **/
    #quote-panel {
        top: -1000px;

        .loading-panel {
            @include loading-panel();
        }
    }

    .quote-panel {
        position: relative;
        background: white;
        border-radius: 4px;
        box-shadow: 0 0 9px 0 rgba(6,29,69,0.15);

        .loading-panel {
            @include loading-panel();
        }

        .cover-summary {
            position: relative;
            padding: 18px;

            .icon-separator {
                margin: 10px 0;

                svg {
                    height: 16px;
                    width: 16px;
                    color: $primary_4;
                }
            }

            text-align: center;

            .display-label {
                @extend %font-secondary;
                @include fontSize(11px);
                @include lineHeight(15px);
                font-weight: 600;
                color: $neutral_3;
                text-transform: uppercase;
            }

            .display-value {
                @extend %font-primary;
                @include fontSize(20px);
                @include lineHeight(22px);
                color: $lqt-colour-quote-panel-values;

                .tier-pill {
                    top: -3px;
                    position: relative;
                }
            }
        }

        .price-frequency {
            margin-top: 10px;
            border-top: 1px solid $lqt-colour-quote-panel-divider;
            border-bottom: 1px solid $lqt-colour-quote-panel-divider;
            text-align: left;
            display: flex;
            flex: 1;

            @include clearfix();

            .price, .excess, .frequency {
                display: block;

                .title {
                    @extend %font-secondary-bold;
                    @include fontSize(12px);
                    color: $lqt-colour-quote-panel-labels;
                    text-transform: uppercase;
                    text-align: center;
                }
            }

            .options, .price {
                width: 50%;
            }

            .options {
                border-right: 1px solid $lqt-colour-quote-panel-divider;

                .frequency, .excess {
                    padding: 15px 10px;
                }

                .frequency {
                    border-bottom: 1px dashed $lqt-colour-quote-panel-divider;
                }

                .excess {
                }
            }

            .price {
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .cost {
                    .value {
                        @include fontSize(28px);
                        @include lineHeight(28px);
                        font-family: $fontPrimary;
                        color: $primary_2;

                        .dollar-sign {
                        }

                        .asterisk {
                            @include fontSize(22px);
                            @include lineHeight(22px);
                            top: -4px;
                            display: inline-block;
                            position: relative;
                        }
                    }
                }
            }
        }

        .buttons {
            .sg-btn {
                text-align: center;
                display: block;
                padding: 18px;
                border-radius: 0;
            }
        }

        .cover-options {
            text-align: center;
            height: 0;
            overflow-y: hidden;
            transition-property: all;
            transition-duration: .5s;
            transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

            .cover-options-inner {
                background-color: $neutral_6;

                button {
                    background-color: #F7F7F7;
                }
            }

            &.open {
                height: 310px;
                overflow-y: visible;
            }

            .display-label {
                color: $lqt-colour-quote-panel-labels;
                font-weight: 600;

                label {
                    padding-bottom: 0;
                }
            }

            .state,
            .age,
            .cover-type,
            .income {
                padding: 15px 10px;
                border-top: 1px solid $lqt-colour-quote-panel-divider;
            }
        }

        .cover-checkboxes {
            border-bottom: 1px solid $lqt-colour-quote-panel-divider;
            padding: 18px 18px;

            .field-wrap {
                padding-bottom: 0;
            }
        }
    }

    .disclaimer {
        @include fontSize(12px);
        padding: 16px 4px 0px;
        text-align: center;
        line-height: 150%;
        color: $lqt-colour-quote-panel-labels;
    }

    .section-title.comparison {
        padding-top: 52px;
        padding-bottom: 20px;

        h3 {
            margin: 0;
            color: $primary_2;
        }
    }

    .tabs {
        display: flex;
        justify-content: space-between;

        .tab {
            width: 45%;
            background: $neutral_white;

            .top {
                height: 4px;
                overflow: hidden;
                background-color: $neutral_1;
            }

            &.ampersand {
                width: 10%;
                background: transparent;
                text-align: center;

                svg.icon {
                    visibility: hidden;
                    margin-top: 19px;
                    height: 15px;
                    width: 15px;
                    color: $lqt-colour-quote-panel-values;
                }
            }

            .bottom {
                .left {
                    padding: 10px 0;
                    @extend %font-secondary-bold;
                    text-align: center;

                    .label {
                        padding: 0 8px;
                        color: $lqt-colour-quote-panel-labels;
                        text-transform: uppercase;
                        @include fontSize(10px);
                        padding-bottom: 4px;
                    }

                    .value {
                        padding: 0 8px;
                        color: $lqt-colour-quote-panel-labels;
                        @extend %font-primary;
                        @include fontSize(18px);
                        @include lineHeight(22px);
                        font-weight: normal;

                        .tier-pill {
                            top: -2px;
                            position: relative;
                        }
                    }
                }

                .right {
                    display: none;

                    button {
                        @extend %font-primary;
                    }
                }
            }


            &.active {
                background: $lqt-colour-tab-active;

                .bottom {
                    .left {
                        .value {
                            color: $lqt-colour-quote-panel-values;
                        }
                    }
                }

                &.hospital {
                    .top {
                        background-color: $primary_1;
                    }
                }

                &.extras {
                    .top {
                        background-color: $primary_3;
                    }
                }
            }
        }
    }



    .summary-compare-panel {
        position: relative;

        .loading-panel {
            @include loading-panel();

            .content {
                top: 6%;
            }
        }

        .panel-content {
            .title-right {
                @extend %font-primary;
                @include fontSize(18px);
                @include lineHeight(22px);
                padding: 20px 0;
                color: $primary_2;
                position: relative;
                display: block;
                float: right;
                height: 100%;
                width: 111px;
            }

            .panel-body {
                display: table;
                border: 1px solid $lqt-colour-tab-border;
                width: 100%;

                .data-row {
                    display: table-row;

                    .data-label, .data-value {
                        @extend %font-primary;
                        @include fontSize(16px);
                        @include lineHeight(17px);
                        display: table-cell;
                    }

                    .data-label {
                        padding: 14px 12px;
                        border-bottom: 1px solid $lqt-colour-tab-border;

                        a {
                            color: $neutral_2;
                            border-bottom: 1px dashed $primary_4;
                        }
                    }

                    .data-value {
                        @extend %font-secondary;
                        @include fontSize(14px);
                        position: relative;
                        width: 111px;
                        border-left: 1px solid $lqt-colour-tab-border;
                        border-bottom: 1px solid $lqt-colour-tab-border;
                        padding: 10px;
                        text-align: center;

                        > span {
                            display: block;
                        }

                        svg.icon {
                            width: 14px;
                            height: 14px;
                        }
                    }

                    &:last-child {
                        .data-label, .data-value {
                            border-bottom: none;
                        }
                    }
                }
            }

            &.hospital {
                .panel-body {
                    .data-row {
                        .data-value {
                            width: 44px;
                        }
                    }
                }
            }
        }

        .btn-mobile-change {
            padding: 10px 0;
            display: block;

            button {
                @extend %font-primary;
                text-align: center;
                color: $primary_4;
                @include fontSize(15px);
                padding: 13px 6px;
                width: 100%;
                border: 1px solid $lqt-colour-compare-btn-border;
                background: white;
            }
        }


        .accordion-content {
            .header {
                padding-left: 12px;
                height: 60px;

                p {
                    @include fontSize(18px);
                    @include lineHeight(22px);
                    padding: 20px 0;
                    color: $primary_2;
                }
            }

            .trigger {
                border-left: 0;

                svg.icon {
                    width: 12px;
                    height: 12px;
                    color: $primary_2;
                    fill: $primary_2;

                    &.icon-down {
                        display: none;
                    }

                    &.icon-up {
                        display: block;
                    }
                }

                &.collapsed {
                    svg.icon {
                        &.icon-down {
                            display: block;
                        }

                        &.icon-up {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .sublimits-disclaimer {
        color: $lqt-colour-quote-panel-labels;
        padding: 12px 35px 12px 18px;
        @include fontSize(14px);
    }

    .live-quote-tool-specific .quote-panel .bootstrap-select {
        text-align: center;

        .dropdown-toggle {
            border: none;
            padding: 0;
            width: auto;
            height: auto;
            line-height: 1;
            position: relative;
            padding-right: 20px;
            margin-top: 6px;

            .filter-option {
                @extend %font-primary;
                @include fontSize(16px);
                @include lineHeight(16px);
                text-align: center;
                width: auto;
                color: $primary_2;
            }

            .bs-caret {
                position: absolute;
                top: 0;
                right: 0;
                display: inline-block;
                height: 12px;
            }

            .caret {
                @extend %font-primary;
                @include fontSize(16px);
                display: inline-block;
                position: static;
                top: unset;
                left: unset;
                right: unset;
                bottom: unset;
                width: auto;
                height: auto;
                vertical-align: top;
                margin: 0;

                svg.icon {
                    height: 9px;
                    width: 14px;
                    position: static;
                    top: initial;
                    left: initial;
                    right: initial;
                    bottom: initial;
                    transform: none;
                }
            }
        }

        .dropdown-menu {
            top: 30px;
            background: $lqt-colour-dropdown-options-bg;
            border: none;
            padding: 0;
            box-shadow: 0 0 20px 0 rgba(32, 32, 32, 0.2);

            li {
                padding: 0;
                border-bottom: 1px solid $lqt-colour-dropdown-options-selected-border;

                &:last-child {
                    border: none;
                }

                &:hover {
                    background: $lqt-colour-dropdown-options-selected-bg;
                    color: white;
                }
            }

            a {
                display: block;
                @include fontSize(12px);
                font-weight: bold;
                text-transform: uppercase;
                color: inherit;
                padding: 14px 20px 10px;
            }
        }



        &.dropup {
            .dropdown-menu {
                top: auto;
                bottom: 30px;
                border-bottom: none;
                border-top-width: 1px;
            }
        }

        &.open {
            .dropdown-toggle {
                background: transparent;
            }
        }
    }

    .lqt-compare-table {
        display: block;
        @include clearfix();

        .toolbar {
            padding-top: 10px;
            padding-bottom: 26px;
            display: flex;
            justify-content: stretch;

            .btn-close {
                flex-grow: 1;

                button {
                    padding: 14px 19px;
                    @extend %font-primary;
                    @include fontSize(15px);
                    @include lineHeight(16px);
                    text-align: left;
                    color: $primary_4;
                    border: 1px solid $lqt-colour-compare-btn-border;
                    border-right: 0;
                    background: white;
                    width: 100%;
                }
            }

            .pagination {
                width: 92px;
                display: flex;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid $lqt-colour-compare-btn-border;
                    width: 46px;
                    height: 46px;

                    svg.icon {
                        width: 16px;
                        height: 16px;
                        color: $neutral_3;
                    }

                    &:first-child {
                        border-right: 0;
                    }

                    &.inactive {
                        background: $neutral_6;
                    }
                }
            }
        }



        &.hospital {
        }

        &.extras {
            .column {
                &.service-label {
                    width: 46%;
                }

                &.selected {
                    width: 27%;
                }

                &.slider-container {
                    width: 27%;
                }
            }
        }

        .columns {
            @include clearfix();
        }

        .sublimits-disclaimer {
            padding: 25px 20px;
            color: $lqt-colour-quote-panel-labels;
        }

        .column {
            display: block;
            float: left;
            border-top: 1px solid $lqt-colour-compare-btn-border;
            border-right: 1px solid $lqt-colour-compare-btn-border;
            position: relative;
            z-index: 2;

            &.service-label {
                width: 54%;
                border-left: 1px solid $lqt-colour-compare-btn-border;
                border-right: 0;
            }

            .product-label {
                @extend %font-primary;
                display: block;
                height: 74px;
                position: relative;

                .label {
                    padding: 11px 9px;
                    @include fontSize(14px);
                    @include lineHeight(18px);
                    display: block;
                    width: 100%;
                    color: $primary_2;
                    text-align: center;
                }

                &.cover-compared {
                    display: flex;
                    align-items: center;
                    position: relative;

                    .label {
                        padding-left: 14px;
                        @include fontSize(18px);
                        @include lineHeight(22px);
                        text-align: left;
                    }
                }

                .selected-bar {
                    position: absolute;
                    top: -15px;
                    left: -1px;
                    right: -1px;
                    display: block;
                    @include fontSize(10px);
                    @include lineHeight(14px);
                    font-family: $fontSecondary;
                    font-weight: bold;
                    text-align: center;
                    background: $primary_4;
                    color: white;
                    padding: 2px 0;
                    border-radius: 4px 4px 0 0;

                    .selected-icon {
                        display: inline-block;
                        background: $neutral_white;
                        color: $primary_4;
                        height: 10px;
                        width: 10px;
                        position: relative;
                        top: 1px;

                        svg {
                            position: absolute;
                            top: 1px;
                            left: 1px;
                        }
                    }

                    .selected-text {
                    }
                }

                .extra-info {
                    display: none;
                }
            }

            .service-row {
                @extend %font-primary;
                @include fontSize(14px);
                @include lineHeight(17px);
                border-top: 1px solid $lqt-colour-compare-btn-border;
                padding: 15px;
                position: relative;

                a {
                    color: $neutral_2;
                    border-bottom: 1px dashed $primary_4;
                }

                .value {
                    font-family: $fontSecondary;
                    @include fontSize(10px);
                    text-align: center;
                    height: 100%;

                    svg.icon {
                        width: 14px;
                        height: 14px;
                        @include centre('true','true','absolute');
                    }
                }
            }

            .group {
                border-bottom: 1px solid $lqt-colour-compare-btn-border;
                position: relative;

                .group-label {
                    @extend %font-primary;
                    @include fontSize(16px);
                    @include lineHeight(22px);
                    padding: 12px;
                    height: 46px;
                    background: $lqt-colour-compare-group-bg;
                    color: $primary_2;
                }

                .accordion-arrow {
                    display: block;
                    position: absolute;
                    right: -83%;
                    top: 0;
                    z-index: 3;
                    width: 39%;
                    height: 46px;

                    svg.icon {
                        @include centre('true','true');
                        color: $primary_2;
                        width: 10px;
                        height: 10px;

                        &.icon-up {
                            display: block;
                        }

                        &.icon-down {
                            display: none;
                        }
                    }
                }

                .accordion-yearly-limits {
                    @include fontSize(14px);
                    @include lineHeight(18px);
                    color: $neutral_2;
                    text-align: center;
                    display: block;
                }

                &.open {
                    .accordion-arrow {
                        svg.icon {
                            &.icon-up {
                                display: none;
                            }

                            &.icon-down {
                                display: block;
                            }
                        }
                    }
                }
            }

            &.selected {
                width: 23%;
                position: relative;
                border-right: 0;
                border-left: 0;
                box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.2);
                background: $neutral_white;

                .group {
                    background-color: $neutral_white;

                    .group-label {
                        background-color: $neutral_white;
                    }
                }

                .product-label {
                    z-index: 4;

                    .extra-info {
                        display: none;
                    }
                }
            }

            &.slider-container {
                width: 23%;
                overflow: hidden;
                position: relative;
                z-index: 1;

                .slider {
                    display: block;
                    width: 32000em;
                    transition: all 0.2s ease-in-out;

                    .slide {
                        display: block;
                        float: left;
                        min-width: 64px;
                    }
                }
            }
        }
    }
    /*
    *
    *
    2021 Amends - THFM-728
     *
     *
     */
    //Parent wrapper for new panel
    .lead-capture.mobile {
        
        &.affix {
            z-index: 400;
        }
        
        .inner-wrap {
            transform: none !important;
        }
        
        .lead-capture-form-panel.panel-open {
            position: fixed;
            top: 0;
            left: 0;
            padding: 20px;
            background: white;
            overflow: auto;
            bottom: 0;
            right: 0;
            z-index: 500;
            max-height: 100vh;
        }
    }
    
    .lead-capture {
        width: 100%;

        .inner-wrap {
            padding: 12px 17px;
            margin: 20px 0;
            background-color: white;
            box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
            border-radius: 5px;
        }
        //Post scroll fixed
        &.affix {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 199;
           
            .inner-wrap {
                width: 100%;
                max-width: 962px;
                margin: auto;
                border-radius: 0;
            }
        }
    }
    //Controls row in initial view
    .lead-capture-controls {
        @include animate-custom(#{'height'}, 250ms);
        width: 100%;
        overflow: hidden;
        position: relative;

        .control-row {
            display: flex;
            width: 100%;
        }

        .confirmation-panel {

            p {
                @include fontSize(14px);
                @include lineHeight(19px);
                margin: 0;
            }

            button.btn-lead-capture {
                width: 100%;
                margin-top: 20px;
                margin-bottom: 10px;
                margin-left: 0;
            }
        }

        .messaging {
            display: none;
        }

        .btn-lead-capture {
            @extend %font-primary;
            @include fontSize(16px);
            @include lineHeight(18px);
            @include animate-custom(#{'background-color'}, $defaultAnimationTime);
            background: none;
            padding: 9px 15px;
            text-align: center;
            background-color: white;
            border-style: solid;
            border-width: 2px;
            border-radius: 5px;
            flex-basis: 50%;

            &:focus,
            &:active {
                outline: none;
            }

            .icon {
                width: 15px;
                height: 15px;
                margin-left: 3px;
                position: relative;
                top: 1px;
            }

            &.email {
                color: $primary_4;
                border-color: $primary_4;

                &:hover {
                    background-color: $primary_4;
                    color: white;
                }
            }

            &.callback {
                color: $primary_2;
                border-color: $primary_2;

                &:hover {
                    background-color: $primary_2;
                    color: white;
                }
            }
        }

        button.email {
            margin-right: 10px;
        }

        button.callback {
            margin-left: 10px;
        }

        &.minimized {
            height: 0;
        }
    }
    //Form panel, hidden by default
    .lead-capture-form-panel {
        @include animate-custom(#{'max-height'}, 350ms);
        max-height: 0;
        overflow: hidden;

        &.panel-open {
            max-height: 900px;
        }

        .intro-text {
            @include fontSize(14px);
            margin-top: 10px;
            margin-bottom: 30px;
        }
        /*
        Form buttons; submit + cancel
         */
        .buttons {
            display: flex;

            button {
                flex-basis: 50%;

                &:first-child {
                    margin-right: 10px;
                }

                &:last-child {
                    margin-left: 10px;
                }
            }
        }

        .disclaimer-wrap {
            margin: 20px 0;

            p {
                @include fontSize(10px);
                @include lineHeight(14px);
                color: $neutral_2;
                margin: 0;
            }
        }

        .optional-phoneNumber {
            margin: 0;

            p {
                @include fontSize(11px);
                color: $neutral_2;
                margin: 0;
            }
        }

        .item {
            position: relative;
            text-align: left;
            z-index: 48;
        }
    }
    /*
    Processing overlay / loading state
     */
    .processing-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: white;
        left: 0;
        top: 0;
        z-index: 200;
    }

    .error-msg {
        margin: 25px 0 20px 0;

        p {
            @include fontSize(14px);
            @include lineHeight(16px);
            color: $error;
            margin: 0;
        }
    }
}





@media #{$sm} {
    .live-quote-tool {

        .loading-panel.global {
            .content {
                .loader {
                    top: 62px;
                }
            }
        }

        .section-title {
            padding: 0;
            padding-top: 10px;
            padding-bottom: 24px;

            h1 {
                @include fontSize(36px);
                @include lineHeight(44px);
                color: $primary_2;
                margin: 0;

                .print-email {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: block;
                    @include fontSize(16px);
                    @include lineHeight(16px);

                    .btn-email,
                    .btn-print {
                        display: inline-block;
                        border: 1px solid $neutral_5;
                        background: $neutral_white;
                        padding: 10px 11px;
                        color: $neutral_3;

                        svg {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }

        .quote-panel {
            box-shadow: 0 0 50px 8px rgba(6,29,69,0.15);

            .quote-row {
                display: flex;
            }

            .cover-summary {
                width: 50%;
                border-right: 1px solid $lqt-colour-quote-panel-divider;

                .display-label {
                    @include fontSize(12px);
                    @include lineHeight(15px);
                    margin-bottom: 9px;
                }

                .display-value {
                    @include fontSize(24px);
                    @include lineHeight(27px);
                }

                .cover-details {
                    display: flex;
                    justify-content: space-between;

                    .hospital-cover,
                    .extras-cover {
                        width: 48%;
                        padding-top: 44px;
                        padding-bottom: 37px;
                    }

                    .icon-separator {
                        padding-top: 59px;

                        svg {
                            height: 24px;
                            width: 24px;
                        }
                    }
                }

                .excess {
                    margin: 0 28px;
                    border-top: 1px dashed $lqt-colour-quote-panel-divider;
                    padding: 11px;
                    display: flex;
                    justify-content: center;

                    .title {
                        @include fontSize(12px);
                        @include lineHeight(15px);
                        @include font-secondary();
                        font-weight: 600;
                        color: $lqt-colour-quote-panel-labels;
                        text-transform: uppercase;
                        margin-top: 8px;
                        margin-right: 11px;
                    }

                    .field {
                        width: 100px;
                    }
                }
            }

            .price-frequency {
                display: block;
                flex: initial;
                width: 34%;
                border-top: 0;
                border-bottom: 0;

                .price {
                    padding-top: 40px;
                    padding-bottom: 31px;
                    width: 100%;

                    .cost {
                        .value {
                            @include fontSize(52px);
                            @include lineHeight(52px);

                            .asterisk {
                                @include fontSize(45px);
                                @include lineHeight(45px);
                                top: -4px;
                                display: inline-block;
                                position: relative;
                            }
                        }
                    }
                }

                .frequency {
                    margin: 0 28px;
                    border-top: 1px dashed $lqt-colour-quote-panel-divider;
                    padding: 11px;
                    display: flex;
                    justify-content: center;

                    .title {
                        @include fontSize(12px);
                        @include lineHeight(15px);
                        @include font-secondary();
                        font-weight: 600;
                        color: $lqt-colour-quote-panel-labels;
                        text-transform: uppercase;
                        margin-top: 8px;
                        margin-right: 11px;
                    }

                    .field {
                        width: 130px;
                    }
                }
            }

            .buttons.join {
                width: 16%;

                .sg-btn {
                    height: 100%;
                    position: relative;

                    .centred {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        white-space: nowrap;
                    }
                }
            }

            .buttons.cover-details {
                width: 100%;
                border-top: 1px solid $lqt-colour-quote-panel-divider;
            }

            .cover-options {
                width: 100%;

                &.open {
                    height: 145px;
                }

                .cover-options-inner {
                    display: flex;
                    border-bottom: 1px solid $lqt-colour-quote-panel-divider;

                    .state, .age, .cover-type, .income {
                        width: 25%;
                        border-right: 1px solid $lqt-colour-quote-panel-divider;

                        &:last-child {
                            border-right: 0;
                        }
                    }
                }

                .cover-checkboxes {
                    border-bottom: 0;

                    .field-wrap {
                        display: inline-block;

                        &:first-child {
                            margin-right: 20px
                        }
                    }
                }
            }
        }

        .disclaimer {
            padding-top: 26px;
            padding-bottom: 26px;
        }

        .section-title {
            h3 {
                @include fontSize(36px);
                @include lineHeight(48px);
                margin: 0;
            }

            &.comparison {
                padding-top: 14px;
                padding-bottom: 29px;
            }
        }

        .tabs {
            .tab {
                width: 47%;
                position: relative;

                &.ampersand {
                    width: 6%;

                    svg.icon {
                        visibility: visible;
                        display: inline;
                        margin-top: 27px;
                        color: $lqt-colour-quote-panel-values;
                    }
                }

                .top {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }

                .bottom {
                    .left, .right {
                        float: left;
                        display: block;
                        text-align: left;

                        .label {
                            padding: 11px 22px 0;
                            color: $lqt-colour-quote-panel-labels;
                        }

                        .value {
                            padding: 0 21px;
                            @include fontSize(24px);
                            @include lineHeight(32px);
                        }
                    }

                    .left {
                        width: 71%;
                    }

                    .right {
                        width: 29%;

                        button {
                            border-radius: 4px;
                            box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.20);
                            background: white;
                            color: $primary_4;
                            border: none;
                            margin-top: 20px;
                            width: 84%;
                            padding: 9px;
                            @include fontSize(18px);

                            &:disabled {
                                background-color: $lqt-colour-tab-inactive;
                            }
                        }
                    }
                }

                &.active {
                    background: $neutral_4;
                }

                &.active-selected {
                    background: $neutral_white;
                }
            }
        }

        .lqt-compare-table {

            .toolbar {
                justify-content: flex-end;
                padding-bottom: 0;
                padding-top: 36px;

                .pagination {
                    width: 80px;

                    a {
                        height: 40px;
                        width: 40px;
                        font-size: 12px;
                        padding: 4px 8px;
                        color: $lqt-colour-black;
                        border-bottom: 0;
                    }
                }
            }

            .panel-content {
            }

            .column {
                border-right: none;

                .product-label {
                    height: 130px;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;

                    .action-icon {
                        font-size: 24px;
                        top: 61px;

                        svg.icon {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .extra-info {
                        position: absolute;
                    }

                    .label {
                        @include fontSize(22px);
                        @include lineHeight(28px);
                        top: 0;
                        padding: 13px 12px;
                    }

                    .selected-bar {
                        top: -25px;
                        @include font-secondary();
                        @include fontSize(11px);
                        font-weight: bold;
                        padding: 8px 0;

                        .selected-icon {
                            margin-right: 3px;
                        }
                    }
                }

                .service-row {
                    @include fontSize(18px);
                    @include lineHeight(21px);
                    padding: 27px;

                    .value {
                        @include fontSize(14px);
                        text-align: center;

                        svg.icon {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                .group {
                    .group-label {
                        @include fontSize(20px);
                        padding: 18px;
                        height: 59px;
                    }

                    .accordion-arrow {
                        right: -133%;
                        width: 101px;
                        cursor: pointer;
                        height: 59px;
                        top: 0;
                        margin-right: 1px;

                        svg.icon {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .accordion-yearly-limits {
                        @include fontSize(16px);
                        @include lineHeight(22px);
                    }
                }

                .combined {
                    .product-label {
                        .extra-info {
                            display: block;
                            top: 0;
                            left: 0;
                            width: 100%;
                            @include font-secondary();
                            @include fontSize(9px);
                            font-weight: bold;
                            text-align: center;
                            padding: 7px 0 5px;
                            text-transform: uppercase;
                        }

                        .label {
                            padding-top: 0;
                        }
                    }
                }

                &.service-label {
                    width: 43%;

                    .product-label {
                        .label {
                            @include fontSize(32px);
                            @include lineHeight(48px);
                            padding: 0 17px;
                            color: $primary_4;
                        }
                    }
                }

                &.selected {
                    width: 17%;

                    .product-label {
                        .label {
                            top: -4px;
                            @include fontSize(22px);
                            @include lineHeight(28px);
                        }
                    }
                }

                &.slider-container {
                    width: 40%;
                    border-right: 1px solid $lqt-colour-border;

                    .slider {
                        .slide {
                            border-right: 1px solid $lqt-colour-border;
                            width: 165px;
                        }
                    }
                }
            }

            &.extras {

                .columns {
                    display: flex;
                }

                .column {
                    &.service-label {
                        width: 44%;
                    }

                    &.selected {
                        width: 182px;
                    }

                    &.slider-container {
                        flex-grow: 1;

                        .slider {
                            .slide {
                                width: 182px;
                            }
                        }
                    }
                }
            }
        }


        .summary-compare-panel {

            .summary-panel {
                padding-top: 38px;
            }



            .panel-content {
                .panel-body {
                    .data-row {
                        .data-label {
                            @include fontSize(16px);
                            font-weight: normal;
                            padding: 15px 19px;

                            .icon-tooltipincircle {
                                margin-top: -5px;
                                @include fontSize(14px);
                            }
                        }

                        .data-value {
                            width: 166px;
                            @include fontSize(14px);
                            position: relative;

                            svg.icon {
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                }

                .title-right {
                    width: 166px;
                }

                .accordion-content {
                    .trigger {
                        svg.icon {
                            width: 19px;
                            height: 19px;
                        }
                    }
                }

                &.hospital {
                    .accordion-content {
                        margin-right: 20px;
                    }

                    .panel-body {
                        .data-row {
                            .data-value {
                                width: 102px;
                            }
                        }
                    }
                }

                &.extras {
                    .accordion-content {
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    /*
    2021 Amends - THFM-728
     */
    .live-quote-tool {
        .lead-capture {
            //Post scroll fixed
            &.affix {                
                .inner-wrap {
                    width: calc(100vw - (#{$grid-gutter-width}));
                    max-width: 962px;
                    margin: auto;
                }
            }
        }

        .lead-capture-controls {
            //height: 44px; //TODO -- correct this

            .control-row {
                align-items: center;
                justify-content: space-evenly;
            }


            .messaging {
                @include fontSize(14px);
                @include lineHeight(32px);
                display: flex;
                color: #000000;
            }

            .btn-lead-capture {
                @include lineHeight(22px);
                flex-basis: auto;

                &.email {
                    width: 138px;
                }
            }

            button.email,
            button.callback {
                margin-right: 15px;
                margin-left: 15px;
            }


            /*
            Confirmation panel
             */
            .confirmation-panel {
                display: flex;
                align-items: center;
                padding-bottom: 15px;

                button.btn-lead-capture {
                    width: auto;
                }
            }
        }

        .lead-capture-form-panel {
            .form-wrap {
                padding-top: 20px;
            }

            .buttons {
                padding-top: 30px;

                button {
                    flex-basis: auto;
                    min-width: 95px;
                    margin-right: 30px;
                }
            }

            .disclaimer-wrap {
                margin: 0 0 30px 0;
            }
        }
    }
}







@media print {
    .live-quote-tool {
        .hospital-panel,
        .btn-mobile-change,
        .top-section .tabs,
        #quote-panel,
        .extras-panel {
            display: none !important;
        }

        .summary-panel,
        .cover-options,
        .summary-panel .panel-content.hospital,
        .summary-panel .panel-content.extras,
        .summary-panel .panel-content.hospital .hospital-panel,
        .summary-panel .panel-content.extras .extras-panel,
        .summary-panel .panel-content.hospital .panel-collapse.collapse,
        .summary-panel .panel-content.extras .panel-collapse.collapse {
            display: block !important;
        }
    }
}


.ngdialog.ngdialog-theme-plain {

    &.email-form {
        form {
            .form {
                text-align: left;
                padding-top: 30px;
            }
        }
    }

    &.email-success {
        .ngdialog-content .buttons a.btn-primary {
            float: initial;
            margin: 0 auto;
            width: 130px;
        }
    }

    @media #{$sm} {
        &.email-form {
            form {
                .buttons {
                    button {
                        width: auto;

                        &.btn-cancel {
                            //float: none;
                            padding-right: 50px;
                            padding-left: 50px;
                        }

                        &.btn-primary {
                            padding-left: 50px;
                            padding-right: 50px;
                        }
                    }
                }
            }
        }
    }
}
