/**
*
* Legend
*
**/
.legend {

	h1 {
		@extend %font-primary;
		@include fontSize(24px);
		color: $neutral_2;
		padding: 0;
		margin: 0;
		padding-bottom: 10px;
		border-bottom: solid 1px $neutral_5;
	}

	ul {
		margin: 0;
		padding: 0;
		padding-top: 15px;
		list-style-type: none;
	}

	li {
		@extend %font-secondary;
		@include fontSize(14px);
		position: relative;
		padding-left: 30px;
		margin-bottom: 20px;
		color: $neutral_3;
	}

	svg.icon {
		fill: $neutral_3;
		position: absolute;
		left: 2px;
		top: 2px;
		height: 16px;
		width: 16px;
	}

	//Desktop
	@media #{$sm} {

		h1 {
			text-indent: 20px;
		}

		li {
			@include fontSize(14px);
			padding-left: 60px;
		}

		svg.icon {
			left: 20px;
		}
	}
}
