/**
*
* Category Tile - used in 'categories CTAs'
*
**/
.category-tile {
    box-shadow: 0 2px 3px rgba(28, 37, 44, 0.2);
    background-color: white;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .img-block {
        flex-shrink: 0;
        border-radius: 16px 16px 30px 0;
        overflow: hidden;
    }

    img {
        width: 100%;
    }

    .content-block {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        padding: 10px 24px 24px 24px;
    }

    .category-title {
        @extend %font-primary;
        @include fontSize(25px);
        @include lineHeight(25px);
        color: $primary_2;
    }

    .category-desc {
        @include fontSize(14px);
        @include lineHeight(19px);
        color: #374A59;
    }

    a {
        margin-top: auto;
        @include btnFilled();
    }
}
