/**
*
* Rating Feedback module
*
**/
[data-rating-feedback] {
    display:flex;
    flex-direction: column;
    .rating-top{
        display:flex;

        button {
            @include btnReset();
            width: 20px;
            height: 20px;
            position: relative;
            top: 2px;
    
            .icon {
                width: 100%;
                height: 100%;
                fill: rgba(black, 0.5);
            }
    
            &:first-of-type {
                margin-right: 20px;
            }
    
            /*
           Button specific styling
            */
            &[data-helpful="true"]{
                &:hover,
                &.selected {
                    .icon {
                        fill: $primary_4;
                    }
                }
            }
    
            &[data-helpful="false"] {
                &:hover,
                &.selected {
                    .icon {
                        fill: $primary_2;
                    }
                }
            }
        }
    }

    .rating-bottom{
        flex-direction: column;
        display:none;
        margin-top: 10px;
    }

    textarea{
        margin-top: 10px;
        height: 100px;
        min-height: auto;

        &.error{
            border: 1px solid red;
        }
    }

    .btn-save-comment{
        background-color: $primary_2;
        color: $neutral_white;
        text-align: center;
        border-radius: 5px;
        border: 2px solid $primary_4;
        @include fontSize(14px);
        @include lineHeight(14px);
        position: relative;
        display: inline-block;
        padding: 10px;
        margin-top: 10px;
        width: 60%;
    }

    .txt {
        @include fontSize(18px);
        @include lineHeight(24px);
        margin-right: 24px;
        color: #2D3748;
    }

    

    .error-msg {
        display: none; //Hide by default
        width: 100%;
        padding-top: 9px;

        .icon {
            width: 20px;
            height: 20px;
            fill: #A67C11;
            color: #A67C11;
            margin-right: 12px;
        }

        .txt {
            @include fontSize(14px);
            @include lineHeight(19px);
            color: #A67C11;
        }
    }

    /*
    Processing and Error states
     */
    &.processing {
        .btn-ratings {
            pointer-events: none;
            opacity: 0.3;
        }
    }
    &.error {
        .error-msg {
            display: flex;
            align-items: center;
        }
    }

    &.completed {
        .btn-ratings {
            pointer-events: none;
            opacity: 0.3;

            &.completed {
                opacity: 1;
            }
        }
    }

    //Desktop
    @media #{$sm} {
        position: relative;
        .error-msg {
            position: absolute;
            bottom: -29px;
        }
    }
}
