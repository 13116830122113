/**
*
* FAQ row
* NOTE: This file is extremely minimal. All CTA's use core global CTA styles.
* See	ui-elements/_buttons.scss
**/
.faq-btn-row {

	text-align: center;

	.service-tiles + & {
		margin-top: -38px;
	}

	a {
		color: white;
	}


	@media #{$sm} {

		.btn-large-primary {
			margin-bottom: 0px;
		}
	}
}
