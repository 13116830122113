.hh-kbtn {
    margin: 28px 0 0 0;
    padding: 0;
    text-align: center;

    li {
        margin: 0 0 10px 0;
        padding: 0 5px;
        list-style: none;
        width: 100%;
        display: inline-block;

        @media #{$sm} {
            width: 48%;
        }

        @media #{$md} {
            width: 24%;
        }

        svg {
            font-size: 3.2rem;
            margin-right: 10px;
        }

        a {
            margin: 0;
            padding: 20px;
            text-decoration: none;
            background: $neutral_6;
            border-radius: 9px;
            display: flex;
            width: 100%;
            font-size: 1.2rem;
            text-align: left;
            font-family: "TTLakesCompressed-Bold", Calibri, sans-serif;
            color: $primary_2;
            align-items: center;
            line-height: 20px;
            border: 1px solid transparent;
        }

        a.active {
            border: 1px solid $primary_4;
        }

        a:hover {
            box-shadow: 0px 5px 5px $neutral_4;
        }
    }
}

.hh-cover {
    background: $neutral_4;
    padding: 15px 0 30px 0;
    margin-top: 50px;

    .pagination {
        margin-top: 50px;
        margin-bottom: 21px;
        text-align: center;
        position: relative;

        .pagination-process-bar {
            height: 3px;
            width: 243px;
            position: relative;
            overflow: hidden;
            background-color: $neutral_5;
            margin: 15px auto;

            .inner {
                position: absolute;
                left: 0;
                top: 0;
                height: 2px;
                width: 0;
                background-color: $primary_4;
            }
        }
    }

    .btn-load-more {
        -webkit-transition-property: background-color, color;
        transition-property: background-color, color;
        -webkit-transition-duration: 250ms;
        transition-duration: 250ms;
        -webkit-transition-delay: 0ms;
        transition-delay: 0ms;
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        line-height: 1.375rem;
        display: block;
        margin: auto;
        padding: 9px 20px;
        color: $primary_2;
        border: 2px solid $primary_2;
        border-radius: 7px;
        text-transform: uppercase;
        font-family: "TTLakesCompressed-Bold", Calibri, sans-serif;
    }
}



.field-ktopic-main {
    border: 1px solid $neutral_5;
    background: $neutral_white;
    padding: 35px 20px;
    margin-bottom: 25px;

    h3 {
        font-size: 1.5rem;
    }

    .title {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 7px;
        padding-left: 15px;
        padding-right: 15px;

        span {
            background: $neutral_4;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            text-align: center;
            padding-top: 13px;
            margin-right: 20px;

            svg {
                font-size: 1.7rem;
            }
        }
    }

    .field-ktopic {
        position: relative;
        list-style: none;
        padding: 25px 15px;

        a {
            color: $neutral_2;
        }

        .last-view {
            position: absolute;
            font-size: 0.8rem;
            width: auto;
            right: 0;
        }
    }

    .field-ktopic:hover {
        background-color: #c9d8ea;// this color code is not being used anywhere else,
        border-radius: 5px; 
        a {
            color: $primary_2;
            font-weight: 600;
        }
    }

    .field-ktopic::before{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 0px 15px;
        width: 90%;
        border-bottom: 1px solid $primary_2;
    }
    
    .viewall {
        margin-top: 25px;
        margin-right: 15px;
        a {
            text-decoration: underline;
        }
    }

    .inactive{
        display:none;
    }
}


.field-ktopic .form-control {
    border-bottom: 1px solid $primary_3;
    border-top: none;
    border-left: none;
    border-right: none;
    background: none;
    height: 25px;
    padding: 0;
}

.field-ktopic label {
    margin: 0;
    padding: 0;
    text-transform: capitalize;
    font-size: 0.9rem;
    font-weight: 600;
}



.field-ktopic .form-control:focus {
    border-bottom: 1px solid $primary_3;
    border-top: none;
    border-left: none;
    border-right: none;
    background: none;
}

.hh-cover {
    background: $neutral_4;
    padding: 15px 0 30px 0;
    margin-top: 50px;

    .pagination {
        margin-top: 50px;
        margin-bottom: 21px;
        text-align: center;
        position: relative;

        .pagination-process-bar {
            height: 3px;
            width: 243px;
            position: relative;
            overflow: hidden;
            background-color: $neutral_5;
            margin: 15px auto;

            .inner {
                position: absolute;
                left: 0;
                top: 0;
                height: 2px;
                width: 0;
                background-color: $primary_4;
            }
        }
    }

    .btn-load-more {
        -webkit-transition-property: background-color, color;
        transition-property: background-color, color;
        -webkit-transition-duration: 250ms;
        transition-duration: 250ms;
        -webkit-transition-delay: 0ms;
        transition-delay: 0ms;
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        line-height: 1.375rem;
        display: block;
        margin: auto;
        padding: 9px 20px;
        color: $primary_2;
        border: 2px solid $primary_2;
        border-radius: 7px;
        text-transform: uppercase;
        font-family: "TTLakesCompressed-Bold", Calibri, sans-serif;
    }
}