/**
*
* Core typography settings
*
* See /utilities/_mixins.scss for font mixins
**/

%font-primary {
    font-family: $fontPrimary;
}

%font-primary-light {
    font-family: $fontPrimary_light;
}

%font-secondary {
    font-family: $fontSecondary;
    font-weight: 400;
}

%font-secondary-bold {
    font-family: $fontSecondary;
    font-weight: 700;
}

%font-secondary-x-bold {
    font-family: $fontSecondary;
    font-weight: 800;
}

%font-secondary-light {
    font-family: $fontSecondary;
    font-weight: 300;
}

@mixin font-primary() {
    font-family: $fontPrimary;
}

//@mixin font-primary-alt() {
//    font-family: $fontPrimaryAlt;
//}

@mixin font-secondary() {
    font-family: $fontSecondary;
    font-weight: 400;
}

/**
     Typography Updates based on new styleguide, use these going forward
**/

%header-x-large {
    @include font-primary();
    @include fontSize(26px);
    @include lineHeight(30px);
    color: $primary_2;

    @media #{$sm} {
        @include fontSize(36px);
        @include lineHeight(48px);
    }
}

%header-large {
    @include font-primary();
    @include fontSize(18px);
    @include lineHeight(22px);

    @media #{$sm} {
        @include fontSize(26px);
        @include lineHeight(32px);
    }
}

%header-medium {
    @include font-primary();
    @include fontSize(18px);
    @include lineHeight(22px);
}

%body-medium {
    @include fontSize(14px);
    @include lineHeight(21px);
}

%body-small {
    @include fontSize(12px);
    @include lineHeight(17px);
}




// Default font sizes and styles.
// These match the supplied style guide. Not sure what everything below is doing.
// 1: Commented out color for now as it was not being overridden properly throughout the site.
h1, h2, h3, h4, h5, h6 {
    @extend %font-primary;
}

h1 {
    @include fontSize(48px);
    @include lineHeight(72px);
    color: $primary_2;
}

h2 {
    @include fontSize(36px);
    @include lineHeight(54px);
    color: $primary_2;
}

h3 {
    @include fontSize(32px);
    @include lineHeight(48px);
    color: $primary_2;
}

h4 {
    @include fontSize(28px);
    @include lineHeight(42px);
    color: $primary_2;
}

h5 {
    @include fontSize(24px);
    @include lineHeight(36px);
    color: $primary_2;
}

h6 {
    @include fontSize(20px);
    @include lineHeight(30px);
    color: $primary_2;
}

.legal-text {
    @extend %body-small;
}

h4.restricted-icon,
h5.restricted-icon {
    background-image: url('/static/img/' + $global_brand + '/icons/ui-legend-restricted.svg?ver=#{$img-ver}');
    background-repeat: no-repeat;
    background-size: 1.2em 1.2em;
    background-position: -4px 0px;
    padding-left: 1.2em;


}


body {
    font-weight: 400;
    color: $neutral_2;
    @include fontSize(16px);
    -webkit-font-smoothing: antialiased;
}

// Desktop overrides
@media #{$sm} {
    h1 {
        @include fontSize(52px);
        @include lineHeight(54px);
    }

    h2 {
        @include fontSize(32px);
        @include lineHeight(42px);
    }

    h3 {
        @include fontSize(24px);
        @include lineHeight(34px);
    }

    h4 {
        @include fontSize(20px);
        @include lineHeight(24px);
    }

    h5 {
        @include fontSize(18px);
        @include lineHeight(22px);
    }

    h6 {
        @include fontSize(16px);
        @include lineHeight(22px);
    }
}

// TODO: What is going on here?
// Surely if you are assigning a primary font style, that style is assigned to a single element.
// "html", everything else will then inherit the style. How is someone supposed to enter content in the
// back office and have it styled correctly?

/*
Primary font-face
*/
h1.font-primary {
    @extend %font-primary;
    @include fontSize(44px);
}

h2.font-primary {
    @extend %font-primary;
    @include fontSize(32px);
}

h3.font-primary {
    @extend %font-primary;
    @include fontSize(24px);
}

h4.font-primary {
    @extend %font-primary;
    @include fontSize(20px);
}


/*
Secondary font-face
*/
h2.font-secondary {
    @extend %font-secondary;
    @include fontSize(32px);
}

h3.font-secondary {
    @extend %font-secondary;
    @include fontSize(24px);
}

h4.font-secondary {
    @extend %font-secondary;
    @include fontSize(20px);
}

/*
Generic text elements
*/

table {
    // border: 1px solid $border-colour;
    border: 1px solid $border-colour;
    border-left: none;
    border-top: none;
    tr {
        th, td {
            padding: 10px 15px;
            @include fontSize(14px);
            border-left: 1px solid $border-colour;
            border-top: 1px solid $border-colour;
        }
        th {
            font-weight: bold;
            text-transform: uppercase;
            // border-bottom: 1px solid $border-colour;
            vertical-align: top;
        }
        td {

            // border: 1px solid $border-colour;
        }
    }
}

table {
    display: block;
    overflow-x: auto;
}

@media #{$sm} {
    table {
        display: table;
        overflow-x: hidden;
    }
}

a {
    color: $link-color;
    text-decoration: none;


    &:hover,
    &:focus {
        color: $link-hover-color;
        text-decoration: none;
    }
}

p a {
    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}
