.hh-bottom {
    background: #C4D9F3;
    padding: 63px 0 63px 0;
    margin-bottom: -64px;

    a {
        color: $primary_2;
    }

    .search-box {
        width: 100%;
        margin: 15px auto;
        position: relative;
    }

    .search-box .s-field {
        border-radius: 5px;
        height: 60px;
        padding: 15px 155px 15px 15px;
        font-size: 16px;
    }

    p {
        color: $primary_2;
    }

    .search-box .s-btn {
        background: $primary_2;
        position: absolute;
        right: 8px;
        top: 8px;
        border: 0;
        color: $neutral_white;
        height: 45px;
        border-radius: 5px;
        padding: 5px 33px;
        text-transform: uppercase;
        font-size: 20px;
        display: flex;
        align-items: center;

        svg.icon {
            height: 24px;
            width: 24px;
        }
    }

    .mst-links {
        color: $primary_2;
        display: block;
        text-decoration: underline;
        margin-bottom: 5px;
    }

    .mst-links:hover {
        color: inherit;
        text-decoration: none;
    }

    .mst-box {
        padding-left: 100px;
    }
}

.general-content {
    hr {
        border-top: 0.5px solid #C4C4C4;
    }
}