///**
//*
//* Form navigation buttons
//*
//**/
.btn-form-prev {
    float: left;
}

.btn-form-next {
    float: right;
}

/**
*
* Join form buttons
*
**/

/**
*
* Remove button
* NOTE: this is an icon ONLY, and it uses misleading class names.
* We never had a design, so design team has suggested to rotate
* the 'add' icon
*
**/

.btn-remove {
    display: block;
    width: 100%;
    color: $neutral_1;
    text-align: center;
    text-decoration: none;

    svg.icon {
        width: 12px;
        height: 12px;
        position: relative;
        top: 14px;
    }
}

/**
*
* Remove button WITH text
* Similar in style to btn-add; diff. colour
* Icon and text ONLY
*
**/
.btn-remove-txt {
    @extend %font-secondary-bold;
    @include fontSize(12px);
    display: block;
    height: 18px;
    line-height: 18px;
    color: $neutral_1;
    text-transform: uppercase;

    span {
        display: inline-block;
    }

    svg.icon {
        @include fontSize(18px);
        padding-right: 5px;

        &:before {
            display: block;
            transform: rotate(45deg);
        }
    }

    &:hover {
        color: $primary_2;

        .icon {
            color: $primary_2;
        }
    }
}

/**
*
* Add button
* Icon and text ONLY
*
**/
.btn-add {
    @extend %font-secondary-bold;
    @include fontSize(12px);
    display: block;
    height: 18px;
    line-height: 18px;
    color: $primary_2;
    text-transform: uppercase;

    span {
        display: inline-block;
    }

    svg.icon {
        height: 18px;
        width: 18px;
    }

    .copy {
        position: relative;
        top: -4px;
        left: 6px;
    }

    &:hover {
        .icon {
            fill: $primary_4;
        }
    }
}

/**
*
* Simple text style button
* Icon and text ONLY
*
**/
.btn-text {
    @extend %font-primary;
    @include fontSize(18px);
    display: inline-block;
    color: $primary_2;
    text-decoration: none;
    border-bottom: solid 2px $primary_2;

    svg.icon {
        margin-left: 5px;
    }

    &:hover {
        border-bottom: solid 2px $primary_4;
    }
}