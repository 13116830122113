.module-image-promo {

    .tile {
        @include make-xs-column(12, 24px);
        display: block;
        position: relative;
        margin-top: 16px;
        overflow: hidden;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .image-block {
            display: block;
            position: relative;
            height: 100%;
            picture {
                display: block;
                height: 100%;
            }
            img {
                width: 100%;
                height: auto;
            }

            &:after {
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                transition: opacity 250ms;
                background: linear-gradient(180deg, rgba(255,255,255,0) 0.85%, rgba(0,0,0,1) 100%);
                opacity: 0.3;
            }
        }

        &:hover {
            .image-block:after {
                opacity: 0.5;
            }
        }

        .content-block {
            display: block;
            position: relative;
            color: $neutral_white;
            .title-copy {
                display: block;
                position: absolute;
                bottom: 16px;
                left: 24px;
                width: 75%;
                @extend %header-medium;
            }
            svg.icon {
                position: absolute;
                bottom: 16px;
                right: 28px;
                height: 18px;
                width: 10px;
            }
        }
    }

    @media #{$sm} {

        .tiles {
            @include clearfix-micro;
        }
        .tile {
            display: block;
            float: left;
            margin: 24px 0;
            @include make-sm-column(12, 24px);

            .image-block {
                display: block;
            }


            &:nth-last-of-type(n+1) {
                margin-top: 0;
            }

            &:nth-last-of-type(-n+1) {
                margin-bottom: 0;
            }

            /** If the item has 6 Items then we want to style this at 33% for all items. */
            &:first-child:nth-last-child(6),
            &:first-child:nth-last-child(6) ~ .tile {
                @include make-sm-column(4, 24px);
                &:nth-last-of-type(-n+3) {
                    margin-bottom: 0;
                }
            }

            /** If the item has 5 Items then we want to style this at 33% for the first 3 items but 50% for the last 2 items. */
            &:first-child:nth-last-child(5),
            &:first-child:nth-last-child(5) ~ .tile {
                @include make-sm-column(4, 24px);

                &:nth-child(n+4) {
                    @include make-sm-column(6, 24px);
                }

                &:nth-last-of-type(-n+2) {
                    margin-bottom: 0;
                }
            }

            /** If the item has 4 items then we want to style this at 50% for all items. */
            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ .tile {
                @include make-sm-column(6, 24px);
                &:nth-last-of-type(-n+2) {
                    margin-bottom: 0;
                }
            }

            /** If the item has 3 items then we want to style this at 33% for all items. */
            &:first-child:nth-last-child(3),
            &:first-child:nth-last-child(3) ~ .tile {
                @include make-sm-column(4, 24px);
                &:nth-last-of-type(-n+3) {
                    margin-bottom: 0;
                }
            }

            /** If the item has 2 items then we want to style this at 50% for all items. */
            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ .tile {
                @include make-sm-column(6, 24px);
                &:nth-last-of-type(-n+2) {
                    margin-bottom: 0;
                }
            }
        }
    }
}