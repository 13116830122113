#master-header {
	//Default, mobile first
	position: relative;
	height: 80px;

	.logo-column {
		width: siteSetting($header, logoColumnWidth_mobile, 25%);
		float: left;
		padding-left: 12px;
		padding-right: 12px;
	}

	.cta-links {
		a {
			@extend %font-primary;
		}
	}

	.master-logo {
		background-image: $primary-logo;
		background-position: left top;
		background-repeat: no-repeat;
		display: block;
		width: siteSetting($header, logoWidth_mobile, 102px);
		height: siteSetting($header, logoHeight_mobile, 61px);
		text-indent: -9999px;
		margin-top: siteSetting($header,logoMarginTop_mobile, 13px);

		h1 {
			margin: 0;
			padding: 0;
		}
	}

	.contact-block {
		@include clearfix-micro();
		@extend %font-primary;
	}

	.contact-number {
		@extend %font-primary;
		position: relative;

		.trading-hours {
			position: absolute;
			z-index: 2000;
			display: none;
			transform: translateX(-50%);
			left: 50%;
			top: 30px;

			&:before {
				content: '';
				position: absolute;
				left: 50%;
				top: 0;
				transform: translateX(-50%);
				width: 0;
				height: 0;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-bottom: 5px solid $primary_2;
			}

			.trading-hours-inner {
				display: inline-block;
				white-space: nowrap;
				padding: 10px;
				margin-top: 5px;
				background: $primary_2;
				color: $neutral_white;
				border-radius: 4px;

				@extend %font-secondary;
				@include fontSize(14px);
			}
		}

		&:hover {
			.trading-hours {
				display: inline-block;
			}
		}
	}

	.nav-column,
	.tertiary-nav,
	.contact-block,
	.cta-links {
		display: none;
		visibility: hidden;
	}

	.mobile-menu-trigger {
		display: block;
		position: absolute;
		right: 25px;
		top: 28px;

		svg.icon {
			height: 24px;
			width: 24px;
			color: $primary_2;
		}
	}
	//Desktop
	@media #{$md} {
		height: 140px;

		.master-logo {
			width: siteSetting($header,logoWidth_desktop, 100%);
			height: siteSetting($header,logoHeight_desktop, 94px);
			margin-left: 0;
			margin-top: siteSetting($header,logoMarginTop_desktop, 23px);
			float: left;
		}

		.nav-column,
		.tertiary-nav,
		.contact-block,
		.cta-links,
		.mobile-menu-trigger {
			display: block;
			visibility: visible;
		}

		> .container-fluid {
			position: relative;
		}

		.logo-column {
			width: siteSetting($header, logoColumnWidth_desktop, 20%);
			float: left;
			padding-left: 12px;
			padding-right: 12px;
		}

		.nav-column {
			width: siteSetting($header, navColumnWidth_desktop, 80%);
			float: left;
			padding-left: 12px;
			padding-right: 12px;
		}

		.tertiary-nav {
			height: 52px;
			text-align: right;
		}


		.contact-block {
			display: inline-block;
			margin-top: 13px;
			padding-right: 20px;

			p {
				margin-bottom: 0;
			}
		}

		.contact-text {
			@include fontSize(16px);
			@include lineHeight(20px);
			display: block;
			float: left;
			border-right: solid 1px $neutral_2;
			margin-right: 14px;
			padding-right: 14px;

			a {
				color: $neutral_2;
				text-decoration: none;
			}
		}

		.contact-text-no-number {
			@include fontSize(16px);
			@include lineHeight(20px);
			display: block;
			float: left;

			a {
				color: $neutral_2;
				text-decoration: none;
			}
		}

		.contact-number {
			@include fontSize(16px);
			@include lineHeight(20px);
			display: block;
			float: left;
			color: $neutral_2;
			text-decoration: none;

			svg.icon {
				width: 16px;
				height: 16px;
				position: relative;
				top: 2px;
				color: $neutral_2;
			}
		}

		.cta-links {
			float: right;

			a {
				@include fontSize(18px);
				display: block;
				float: left;
				height: 100%;
				width: 120px;
				padding: 12px 10px;
				margin-left: 4px;
				text-decoration: none;
				color: white;
				text-align: center;
			}

			span {
				display: inline-block;
				position: relative;
				top: -2px;
			}

			svg.icon {
				height: 17px;
				width: 17px;
				margin-right: 4px;
				color: white;
			}

			.members {
				background-color: $primary_2;
				color: white;

				svg.icon {
					color: white;
				}

				&:hover {
					background-color: darken( $primary_2, 5% );
				}
			}

			.quote {
				background-color: $primary_3;

				&:hover {
					background-color: darken( $primary_3, 10% );
				}
			}

			.join {
				background-color: $primary_4;

				&:hover {
					background-color: darken( $primary_4, 10% );
				}
			}

			.search {
				color: $primary_2;
				background-color: $neutral_6;
				position: relative;

				.icon {
					color: $primary_2;
					margin-left: 8px;
					position: relative;
					top: 2px;
				}

				&:hover {
					background-color: darken( $neutral_6, 5% );
				}
			}
		}

		.mobile-menu-trigger {
			display: none;
		}
		//&.search-open {
		//	background: red;
		//	.search {
		//		.state.active {
		//			opacity: 1;
		//		}
		//	}
		//}
	}
}
