/**
*
* Related Articles row
*
**/
.related-articles {
    background-color: #F7F7F7;
    width: 100%;
    padding: 40px 0 10px 0;

    .section-title {
        @extend %font-primary;
        @include fontSize(36px);
        @include lineHeight(48px);
        color: $primary_2;
        margin-bottom: 27px;
    }

    //Desktop
    @media #{$sm} {
        padding: 30px 0 34px 0;

        .section-title {
            margin-bottom: 55px;
        }
    }
}
