/**
*
* Steps Module
*
**/
.module-steps {

    .intro-content {
        padding-top: 8px;
        margin-bottom: 26px;
    }

    .step-item {
        width: 100%;
        border-style: solid;
        border-width: 2px 4px 2px 4px;
        padding: 24px 0;

        &:first-child {
            border-top-width: 4px;
        }

        &:last-child {
            border-bottom-width: 4px;
        }
    }

    .indicator {
        @include make-xs-column(2, 0);
        @include make-xs-column-offset(1);
        @include make-sm-column(1, 0);
        text-align: center;

        span {
            display: block;
        }

        .label {
            @include fontSize(10px);
            @include lineHeight(16px);
            font-weight: bold;
            text-transform: uppercase;
        }

        .index {
            @include font-primary();
            @include fontSize(26px);
            @include lineHeight(30px);
            position: relative;
            top: -4px;
        }
    }

    .divider {
        @include make-xs-column(1, 0);
        span {
            display: block;
            width: 70%;
            height: 2px;
            position: relative;
            top: 22px;
        }
    }

    .content {
        @include make-xs-column(6, 0);
        @include make-sm-column(8, 0);

        padding-top: 8px;
        margin-bottom: 26px;

        span {
            @include fontSize(16px);
            @include lineHeight(24px);
        }
    }

    .step-item .content {
        margin-bottom: 0;
        padding-top: 0;

        p {
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    /*
    Colour varients
     */
    @each $class, $colour in $brandColoursMap {
        &.colour-#{$class} {

            .step-item {
                border-color: $colour;
                color: $colour;
            }

            .divider span {
                background-color: $colour;
            }

            &.background-fill {
                .step-item {
                    background-color: rgba($colour, 0.1);
                }
            }
        }
    }

    /*
    Font override when used on modular campaign pages
     */
    .modular-campaign-pages & {
        .number {
            font-family: $fontPrimary;
        }
    }

    //Desktop
    @media #{$sm} {
        .row {
            display: flex;
        }
        .col {
            padding-left: $grid-gutter-width / 2;
            padding-right: $grid-gutter-width / 2;
            align-self: center;
        }

        .indicator {
            .label {
                @include fontSize(12px);
            }

            .index {
                @include fontSize(36px);
                @include lineHeight(48px);
            }
        }

        .divider {
            .dash {
                width: 100%;
                top: 0;
            }
        }
        .content {
            span {
                @include fontSize(18px);
                @include lineHeight(30px);
            }
        }
    }
}
