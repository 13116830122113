$lqt-colour-modal-primary: $primary_2;

@-webkit-keyframes ngdialog-fadeout {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes ngdialog-fadeout {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes ngdialog-fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes ngdialog-fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.ngdialog {
    box-sizing: border-box;
}

.ngdialog *,
.ngdialog *:before,
.ngdialog *:after {
    box-sizing: inherit;
}

.ngdialog {
    position: fixed;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.ngdialog.ngdialog-disabled-animation,
.ngdialog.ngdialog-disabled-animation .ngdialog-overlay,
.ngdialog.ngdialog-disabled-animation .ngdialog-content {
    -webkit-animation: none!important;
    animation: none!important;
}

.ngdialog-overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    -webkit-animation: ngdialog-fadein 0.5s;
    animation: ngdialog-fadein 0.5s;
}

.ngdialog-no-overlay {
    pointer-events: none;
}

.ngdialog.ngdialog-closing .ngdialog-overlay {
    -webkit-backface-visibility: hidden;
    -webkit-animation: ngdialog-fadeout 0.5s;
    animation: ngdialog-fadeout 0.5s;
}

.ngdialog-content {
    background: white;
    -webkit-backface-visibility: hidden;
    -webkit-animation: ngdialog-fadein 0.5s;
    animation: ngdialog-fadein 0.5s;
    pointer-events: all;
}

.ngdialog.ngdialog-closing .ngdialog-content {
    -webkit-backface-visibility: hidden;
    -webkit-animation: ngdialog-fadeout 0.5s;
    animation: ngdialog-fadeout 0.5s;
}

.ngdialog-close:before {
    font-family: 'Helvetica', Arial, sans-serif;
    content: '\00D7';
    cursor: pointer;
}

html.ngdialog-open,
body.ngdialog-open {
    overflow: hidden;
}





.ngdialog.ngdialog-theme-table,
.ngdialog.ngdialog-theme-plain {
    background: rgba(255,255,255,0.8);

    .ngdialog-overlay {
        display: none;
        background: rgba(255,255,255,0.8);
    }

    .ngdialog-close {
        display: none;
    }

    .ngdialog-content {

        @include font-secondary();
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
        background: white;
        color: #444;
        font-family: 'Helvetica Neue',sans-serif;
        margin: 0 auto;
        max-width: 100%;
        padding: 25px 40px 40px 40px;
        position: absolute;
        width: 630px;
        left: 50%;
        transform: translateX(-50%);

        h1 {
            @extend %font-primary;
            @include fontSize(32px);
            margin-top: 0;
            color: $primary_2;
        }

        p {
            @include fontSize(14px);
            color: $neutral_2;
        }

        input[type='radio'] {
            @include inputIcon('radio');
        }

        input[type='checkbox'] {
            @include inputIcon('checkbox');
        }

        input[type='radio'],
        input[type='checkbox'] {
            margin: 0;
            padding: 0;
            width: 15px;
            height: 15px;

            & + label {
                padding-left: 0px;
            }
        }

        .radiobuttonlist {
            svg.icon {
                height: 12px;
                width: 12px;
                color: $primary_2;
            }
        }


        .buttons {
            @include clearfix();

            a {
                &.btn-primary {
                    float: right;
                }
            }

            button {
                &.btn-cancel {
                    float: left;
                }

                &.btn-primary {
                    float: right;
                }

                &.btn-center {
                    float: none;
                }

            }
        }
    }

    .intro-block {
        text-align: center;
    }

}

.ngdialog.ngdialog-theme-table {
    .ngdialog-content {
        width: max-content;
        min-width: 80%;
        padding: 20px;
        
        .module {
            margin-top: 0;
            margin-bottom: 0;
        }
        
        .modal-close-btn {
            position: absolute;
            top: 25px;
            right: 25px;
        }
    }
}

.ngdialog.ngdialog-theme-plain .ngdialog-close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
}

.ngdialog.ngdialog-theme-plain .ngdialog-close:before {
    background: transparent;
    color: #bbb;
    content: "\00D7";
    font-size: 26px;
    font-weight: 400;
    height: 30px;
    line-height: 26px;
    position: absolute;
    right: 3px;
    text-align: center;
    top: 3px;
    width: 30px;
}

.ngdialog.ngdialog-theme-plain .ngdialog-close:hover:before,
.ngdialog.ngdialog-theme-plain .ngdialog-close:active:before {
    color: #777;
}

.ngdialog.ngdialog-theme-plain .ngdialog-message {
    margin-bottom: .5em;
}

.ngdialog.ngdialog-theme-plain .ngdialog-input {
    margin-bottom: 1em;
}

.ngdialog.ngdialog-theme-plain .ngdialog-input textarea,
.ngdialog.ngdialog-theme-plain .ngdialog-input input[type="text"],
.ngdialog.ngdialog-theme-plain .ngdialog-input input[type="password"],
.ngdialog.ngdialog-theme-plain .ngdialog-input input[type="email"],
.ngdialog.ngdialog-theme-plain .ngdialog-input input[type="url"] {
    background: #f0f0f0;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    margin: 0 0 .25em;
    min-height: 2.5em;
    padding: .25em .67em;
    width: 100%;
}

.ngdialog.ngdialog-theme-plain .ngdialog-input textarea:focus,
.ngdialog.ngdialog-theme-plain .ngdialog-input input[type="text"]:focus,
.ngdialog.ngdialog-theme-plain .ngdialog-input input[type="password"]:focus,
.ngdialog.ngdialog-theme-plain .ngdialog-input input[type="email"]:focus,
.ngdialog.ngdialog-theme-plain .ngdialog-input input[type="url"]:focus {
    box-shadow: inset 0 0 0 2px rgba(0,0,0,0.2);
    outline: none;
}

.ngdialog.ngdialog-theme-plain .ngdialog-buttons:after {
    clear: both;
    content: '';
    display: table;
}

.ngdialog.ngdialog-theme-plain .ngdialog-button {
    border: 0;
    cursor: pointer;
    float: right;
    font-family: inherit;
    font-size: .8em;
    letter-spacing: .1em;
    line-height: 1em;
    margin: 0 0 0 .5em;
    padding: .75em 2em;
    text-transform: uppercase;
}

.ngdialog.ngdialog-theme-plain .ngdialog-button:focus {
    -webkit-animation: ngdialog-pulse 1.1s infinite;
    animation: ngdialog-pulse 1.1s infinite;
    outline: none;
}

@media (max-width: 568px) {
    .ngdialog.ngdialog-theme-plain .ngdialog-button:focus {
        -webkit-animation: none;
        animation: none;
    }
}

.ngdialog.ngdialog-theme-plain .ngdialog-button.ngdialog-button-primary {
    background: #3288e6;
    color: #fff;
}

.ngdialog.ngdialog-theme-plain .ngdialog-button.ngdialog-button-secondary {
    background: #e0e0e0;
    color: #777;
}

.ngdialog.ngdialog-theme-plain {
    .ngdialog-content {
        .icon {
            height: 100px;
            width: 100px;
            display: inline-block;
        }
        svg.icon {
            color: $primary_2;
            line-height: 100%;
            height: 100px;
            width: 100px;
            margin-bottom: 20px;


            span {
            }
        }

        .field-wrap {
            svg.icon {
                margin-bottom: 0;
            }
        }

        .formSuccess {
            svg.icon {
                color: $lqt-colour-success;
            }
        }
    }
}
/**
*
* Modal window states
*
**/
.ngdialog.ngdialog-theme-plain {
    .intro-block {
        text-align: center;
    }

    .buttons {
        padding-top: 30px;
    }

    &.email-success {

        .buttons {
            button {
                width: 45%;
                margin: auto;
            }
        }
    }

    //Save application progress success state
    &.save-application-success {


        .buttons {
            button {
                width: 45%;
                margin-bottom: 0;
            }
        }
    }
}
