body.no-scroll {
    overflow: hidden;
}

.contact-form-popup {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 200005;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

    .contact-form-popup-opacity {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.35);
        backdrop-filter: blur(5px);
        z-index: 1;
    }

    .contact-form-popup-container {
        padding: 18px 18px 40px;
        background: $neutral_white;
        border-radius: 0;
        z-index: 2;
        width: 100%;
        max-height: 100dvh;
        height: 100dvh;
        overflow: hidden;
        overflow-y: scroll;
        box-shadow: none;

        .module.module-contact-form {
            margin: 0;

            .container-fluid {
                max-width: unset;
                padding: 0;
            }

            .primary-header {
                .heading {
                    margin-top: 0;
                }
            }

            .form-wrapper {
                .row-2 {
                    .input-container {
                        .phone-number-input {
                            .btn.dropdown-toggle {
                                padding: 10px;

                                .bs-caret {
                                    .caret {
                                        right: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 993px) {
    .contact-form-popup {
        .contact-form-popup-container {
            padding: 18px 18px 40px;
            background: $neutral_white;
            border-radius: 0;
            z-index: 2;
            width: 95%;
            height: 95%;
            max-height: 95dvh;
            overflow: hidden;
            overflow-y: scroll;
            box-shadow: 0 2px 20px rgba(0, 0, 0, .15);
            .module.module-contact-form {
                .form-wrapper {
                    .row-2 {
                        .input-container {
                            .phone-number-input {
                                .btn.dropdown-toggle {
                                    padding: 0 20px 0 20px;

                                    .bs-caret {
                                        .caret {
                                            right: 17px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* medium breakpoint up */
@media #{$md} {
    .contact-form-popup {
        .contact-form-popup-container {
            width: 900px;
            padding: 24px 36px;
            max-height: 95dvh;
            height: auto;
            overflow: hidden;
            overflow-y: scroll;
            border-radius: 5px;
            box-shadow: 0 2px 20px rgba(0, 0, 0, .15);
            .module.module-contact-form {
                .form-wrapper {
                    .row-2 {
                        .input-container {
                            .phone-number-input {
                                .btn.dropdown-toggle {
                                    padding: 0 20px 0 20px;

                                    .bs-caret {
                                        .caret {
                                            right: 17px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
