/**
*
* Search Results
* NOTE: This hard coded into Search.cshtml template
* All markup is driven via Google custom search
*
**/
.search-results {

	table {
		border: none;
		th,
		td {
			border: none;
			padding: 10px 10px;
		}
	}

	.search-results-title {
		h1 {
			@extend %font-primary;
			@include fontSizeFluid(32px);
			color: $primary_2;
			margin-top: 0;
		}
	}


	.search-results-content {
		.title {
			padding-bottom: 10px;
		}
		.description {

		}
		.url {
			@include fontSize(14px);
			padding-top: 10px;
			color: $neutral_1;
		}
	}

	.pagination {

		.previous {
			float: left;
		}

		.next {
			float: right;
		}

	}

	//Desktop
	@media #{$sm} {

	}
}
