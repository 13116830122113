/**
*
* Styles for mobile off-canvas nav ONLY
* This is a cloned element, so is seperate from
* desktop + large screen Nav
*
**/
#mobile-nav {

	background-color: white;

	/*Structural overrides to remove padding on columns*/
	.col-xs-12 {
		padding: 0;
	}

	//Common elements + styles
	.mm-panel {
		background-color: white;
	}
	//Nav bar - containing contextual title
	.mm-navbar {
		height: 60px;
		padding: 0 20px;
		text-align: left;
		border: none;

		a {
			@extend %font-secondary;
			@include fontSize(12px);
			color: $neutral_2;
			text-transform: uppercase;
			position: relative;
			width: 100%;
		}

		.mm-title {
			padding-left: 20px;

			&:first-of-type {
				padding-left: 0;
			}
		}
	}

	//Back arrow buttons
	.mm-prev {
		position: absolute !important;
		left: 20px;

		svg.icon {
			color: $neutral_2;
			height: 1em;
			width: 1em;
			position: absolute;
			left: 4px;
			top: 24px;
			z-index: 1;
		}

	}


	//First panel + Top level list
	//Second panel + 2nd level list
	.top-level,
	.second-level {
		li {
			border: none;

			&:after {
				border: none;
			}
		}
		a {
			@extend %font-primary;
			@include fontSize(16px);
			display: block;
			padding: 10px 20px;
			color: $primary_2;
			text-decoration: none;
			line-height: 20px;
			white-space: normal;
			overflow: visible;
			text-overflow: initial;

			svg.icon {
				fill: $neutral_2;
				position: absolute;
				right: 21px;
				top: 14px;
				z-index: 1;
			}

			/*List headers - fake links*/
			&.no-link {
				@extend %font-secondary-bold;
				@include fontSize(16px);
				color: $neutral_2;
			}
		}
	}

	//Force second level arrow colours
	.second-level {
		a {
			color: $neutral_2;
		}
		.mm-next {
			&:after {
				color: $neutral_2;
			}
		}
	}

	//Third level
	.third-level {
		li {
			border: none;

			&:after {
				border: none;
			}
		}
		a {
			@extend %font-secondary;
			@include fontSize(15px);
			display: block;
			padding: 10px 20px;
			color: $neutral_2;
			text-decoration: none;
			line-height: 20px;
			white-space: normal;
			overflow: visible;
			text-overflow: initial;
		}
	}


	.close-nav-btn {
		display: block;
		position: absolute;
		z-index: 9999;
		top: 15px;
		right: 0px;
		width: 39px;
		height: 30px;
		text-decoration: none;
		background-color: white;

		svg.icon {
			display: block;
			height: 20px;
			width: 20px;
			text-align: center;
			color: $neutral_2;
			line-height: 30px;
			margin: 5px;
		}
	}


	//Post nav mobile tools - CTA's, search etc
	.mobile-tools {
		padding: 20px;
		padding-top: 10px;

		.cta-links {
			a {
				margin-bottom: 18px;
			}
		}

		.contact-number {
			@extend %font-primary;
			@include fontSize(18px);
			display: block;
			color: $primary_2;
			margin-top: 12px;
			text-decoration: none;
		}

		.trading-hours {
			padding-top: 8px;
			@include fontSize(14px);
			color: $primary_2;
		}

		.search-mobile {
			display: block;
			margin-bottom: 20px;
			border-bottom: solid 1px $neutral_1;
		}
	}
}

//Site overlay
#mm-blocker {
	background-color: rgba(0,0,0,0.7);
}