/*
Global Coloured text + text highlights
 */
@each $class, $colour in $brandColoursMap {
    .heading-#{$class} {
        #{headings(1,6)}{
            color: $colour;
        }
    }

    .highlight-#{$class} {
        .highlight {
            color: $colour;
        }
    }
}
