@import 'home';
@import 'upper-section';
@import 'service-section';
@import 'search-section';
@import 'breadcrumbs';
@import 'article/article';
@import 'related-topics';
@import 'article/article-search';
@import 'key-topics';


// TODO BB 2022-08-23 - why are we including a different frameworks margin formatting in here, and why the importants?
// TODO                 this should be refactored as this is very bad practice.
.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}


.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.mb-55{
    margin-bottom:55px !important;
}

.mt-34 {
    margin-top: 34px !important;
}

.mt-5 {
    margin-top: 3rem !important;
}