.module-cta-buttons {
    text-align: center;

    .row {
        margin-top: -6px;
    }

    a {
        margin: 6px;
    }

    &.align-left {
        text-align: left;

        a {
            &:first-child {
                margin-left: 0;
            }
        }
    }

    &.align-right {
        text-align: right;

        a {
            &:last-child {
                margin-right: 0;
            }
        }
    }

    /*
    Modular campaign pages overides
     */
    .modular-campaign-pages & {
        //margin: 32px 0;
    }

    @media #{$sm} {
        .row {
            margin-top: -12px;
        }

        a {
            margin: 12px;
        }
    }
}
