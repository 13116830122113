/**
*
* Health Hub - Service tile
* Apologies for prefixed class name; there is actually a pre-existing 'service-tile'
* component and this was the best I could think of to prevent conflicts
*
**/

.hh-service-tile {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(28, 37, 44, 0.2);
    border-radius: 20px;
    overflow: hidden;
    color: $primary_2;
    margin-bottom: 40px;

    .img-block {
        flex-shrink: 0;
        width: 100%;
        position: relative;

        img {
            width: 100%;
            border-radius: 0 0 30px 0;
            overflow: hidden;
        }
    }

    .icon-block {
        min-height: 170px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        border-radius: 0 0 30px 0;

        @each $class, $colour in $brandColoursMap {
            &.#{$class} {
                background-color: $colour;
            }
        }

        &.primary2 {
            color: white;
        }

        .title {
            font-family: $fontPrimary;
            font-weight: 700;
            @include fontSize(20px);
            @include lineHeight(27px);
        }

        .icon {
            width: 85px;
            height: 85px;
        }
    }


    .content-block {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 13px 16px 22px 16px;
    }

    .title {
        @include fontSize(16px);
        @include lineHeight(22px);
        margin-bottom: 5px;
        font-weight: 600;
    }

    .desc {
        @include fontSize(14px);
        @include lineHeight(17px);
        margin-bottom: 23px;
    }

    a.dark {
        margin-top: auto;
        font-weight: 400;
        @include btnFilled();

        .icon-right {
            display: inline;
        }
    }

    a.light {
        @include fontSize(16px);
        @include lineHeight(20px);
        @include animate-custom(#{'background-color, color'}, 250ms);
        display: block;
        width: 100%;
        padding: 13px 30px 14px 30px;
        margin-top: auto;
        color: black;
        border: 2px solid #A7CAE3;
        border-radius: 6px;
        text-decoration: none;
        text-align: center;
        font-weight: 600;

        &:hover {
            color: white;
            background-color: #A7CAE3
        }

        .icon-right {
            display: none;
        }
    }
    //Desktop
    @media #{$md} {
        height: 100%;

        .content-block {
            padding: 15px 20px 20px 20px;
        }

        .title {
            @include fontSize(18px);
            @include lineHeight(25px);
            margin-bottom: 5px;
            font-weight: 600;
        }

        .desc {
            margin-bottom: 22px;
        }
    }
}
