/**
*
* Background Module
*
**/
.module-background {
    margin: 0;

    &.bg-neutral6 {
        background-color: $neutral_6;
    }

    .module-horizontal-subnav {
        margin: 0;
    }

    .module-members-dash {
        padding-top: 0;
    }
}
