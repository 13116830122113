.tier-pill {
    font-family: $fontSecondary;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    background: $neutral_white;
    color: $primary_2;
    border: 1px solid $neutral_1;
    border-radius: 9px;
    padding: 0 4px;
    display: inline-block;
}