.module-access-gap-calculator {
    margin: 0;
    background: $neutral_6;
    padding: 0;

    h2 {
        margin-top: 36px;
        margin-bottom: 12px;
    }

    p {

    }

    h3 {
        @include fontSize(26px);
        @include lineHeight(32px);
        margin: 0;
        margin-bottom: 4px;
    }

    .make-selection {
        margin-bottom: 57px;

        .procedure-selector {
            margin-top: 15px;
            padding: 20px;
            background: $neutral_white;
            color: $primary_2;
            text-align: center;

            @extend %font-primary;
            @include fontSize(22px);
            @include lineHeight(27px);
        }
    }

    .your-results {
        margin-bottom: 57px;

        .procedure {
            padding: 20px;
            background: $neutral_white;


            .cost-breakdown {
                margin-top: 50px;
                display: flex;
                padding-top: 78px;
                position: relative;

                .agc-value {
                    @include fontSize(15px);
                    @include lineHeight(19px);
                }

                .total-costs {
                    display: flex;
                    justify-content: space-between;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;

                    .your-cost,
                    .procedure-cost {
                    }

                    .your-cost {
                        padding-right: 12px;
                        .agc-value {
                            color: $primary_4;
                        }
                    }

                    .procedure-cost {
                        padding-left: 12px;
                        border-left: 1px solid $neutral_3;
                    }

                    .agc-label {
                        @include fontSize(10px);
                        @include lineHeight(11px);
                    }

                    .agc-value {
                        @include fontSize(36px);
                        @include lineHeight(42px);
                    }
                }


                .colour-block {
                    border-radius: 3px;
                    width: 13px;
                    height: 13px;
                    margin-bottom: 3px;
                }

                .graph {
                    width: 60%;
                    margin-right: 10px;
                }

                .costs {
                    .breakdown {
                        margin-botton: 3px;
                    }
                    .you-pay {
                        .colour-block {
                            background-color: $primary_4;
                        }
                        .agc-value {
                            color: $primary_4;
                        }
                    }
                    .fund-pays {
                        .colour-block {
                            background-color: $primary_2;
                        }
                        .agc-value {
                            color: $primary_2;
                        }
                    }
                    .medicare-pays {
                        .colour-block {
                            background-color: $primary_3;
                        }
                        .agc-value {
                            color: $primary_3;
                        }
                    }
                }
            }

            .youpay-breakdown {
                h4 {
                    text-align: center;
                    @include fontSize(18px);
                    @include lineHeight(22px);
                    margin-top: 38px;
                    margin-bottom: 15px;
                }

                .charts {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .chart {
                    position: relative;
                    color: $neutral_3;

                    display: flex;
                    flex-direction: column;
                    text-align: center;

                    .graphic {
                        font-size: 0;
                        line-height: 0;
                    }

                    .data {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        padding: 0 10px;
                        .agc-value {
                            @include fontSize(18px);
                            @include lineHeight(18px);
                            color: $primary_2;
                        }
                    }

                    .circular-chart {
                        width: 100%;
                        height: 100%;
                        stroke: $primary_3;
                        transition: 300ms ease-out;
                        .inner-circle {
                            transition: 300ms ease-out;
                        }
                    }
                }

                .column {
                    width: 100%;
                    &.equals,
                    &.plus {
                        text-align: center;
                        width: 25%;
                        padding: 0 5px;
                        @extend %font-primary;
                        @include fontSize(20px);
                        @include lineHeight(20px);
                    }
                }


                .total {
                    .chart {
                        .circular-chart {
                            stroke: $primary_4;
                        }
                    }
                    .data {
                        .agc-value {
                            color: $primary_4;
                        }
                    }
                }
            }

            .disclaimer-rule {
                margin-top: 34px;
                margin-bottom: 34px;
            }

            .disclaimers {
                @include fontSize(12px);
                @include lineHeight(17px);

                h4 {
                    @extend %font-secondary;
                    @include fontSize(12px);
                    @include lineHeight(18px);
                    font-weight: bold;
                    color: $primary_2;
                }

                .cost-assumptions {
                    margin-bottom: 28px;

                    ul {
                        margin: 0;
                        padding: 0;
                        margin-left: 15px;
                    }
                }

                .mbs-codes {
                    margin-bottom: 28px;
                    .highlight {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .whats-next {
        margin-bottom: 60px;
        .module-icon-cta-list {
            padding: 20px;
            background: $neutral_white;
        }
    }


    .agc-label {
        text-transform: uppercase;
        @include fontSize(10px);
        @include lineHeight(11px);
        font-weight: bold;
        color: $neutral_3;
    }

    .agc-value {
        @extend %font-primary;
        @include fontSize(36px);
        @include lineHeight(42px);
        color: $neutral_2;
    }


    .select-alternate {
        .bootstrap-select,
        .bootstrap-select.btn-group {
            width: auto;
            border-bottom: 1px solid $neutral_3;

            .dropdown-toggle {
                top: 3px; // Fix for text positioning.

                .filter-option {
                    color: $neutral_3;
                    text-align: center;

                }
            }

            .dropdown-menu.inner {
                // min-width: 250px;
            }
        }

        &.active {
            .bootstrap-select,
            .bootstrap-select.btn-group {
                border-bottom: 1px solid $primary_4;

                .dropdown-toggle {
                    .filter-option {
                        color: $primary_4;
                    }
                }
            }
        }


    }

    .graph {
        text-align: right;
    }
    .pie-chart {
        display: inline-block;
        width: 100%;
        max-width: 300px;
        max-height: 300px;
        .inner {
            overflow:hidden;
            border-radius: 50%;
            width: 100%;
            padding-top: 100%;
            position: relative;
        }

        svg {
            position: absolute;
            transform: translate(-50%, -50%) rotate(-90deg) scale(3);
            transform-origin: 50%;
            top: 50%;
            left: 50%;
            path {
                transition: 300ms ease-out;
            }
        }
        .pie-you-pay {
            fill: $primary_4;
        }
        .pie-fund-pays {
            fill: $primary_2;
        }
        .pie-medicare-pays {
            fill: $primary_3;
        }
    }

    @media only screen and (min-width: 500px) {
        .your-results {
            .procedure {

                .youpay-breakdown {
                    margin-top: 50px;
                    margin-bottom: 90px;
                    h4 {
                        @include fontSize(24px);
                        @include lineHeight(32px);
                    }

                    .column {
                        &.equals,
                        &.plus {
                            width: auto;
                            padding: 0 20px;
                            @include fontSize(40px);
                            @include lineHeight(40px);
                        }
                    }


                    .charts {
                        justify-content: center;
                        .column {
                            max-width: 143px;
                        }
                    }

                    .chart {
                        .data {
                            .agc-value {
                                @include fontSize(36px);
                                @include lineHeight(36px);
                            }
                        }
                    }
                }
                .cost-breakdown {
                    padding-top: 0;

                    .costs {
                        .breakdown {
                            margin-bottom: 25px;
                            display: flex;
                        }
                    }

                    .colour-block {
                        border-radius: 5px;
                        width: 32px;
                        height: 32px;
                        margin-right: 17px;
                    }

                    .total-costs {
                        position: static;
                        top: auto;
                        left: auto;
                        right: auto;
                        margin-bottom: 40px;
                    }
                }
            }
        }
        .graph {
            padding-right: 40px;
        }

        .graphic {
            .outer-circle {
                stroke-width: 0.75;
            }
            .inner-circle {
                stroke-width: 2.5;
            }
        }
    }

    @media #{$sm} {
        h2 {
            margin-bottom: 20px;
        }

        h3 {
            @include fontSize(36px);
            @include lineHeight(48px);
            margin-bottom: 6px;
        }

        .make-selection {
            .procedure-selector {
                padding: 75px;

                @include fontSize(29px);
                @include lineHeight(36px);
            }
        }

        .your-results {
            .procedure {
                padding: 75px;

                .cost-breakdown {
                    .agc-value {
                        @include fontSize(20px);
                        @include lineHeight(30px);
                    }

                    .total-costs {
                        .agc-value {
                            @include fontSize(52px);
                            @include lineHeight(60px);
                        }
                    }
                }

                .disclaimers {
                    .columns {
                        display: flex;
                        justify-content: space-between;

                        .cost-assumption {
                            padding-right: 20px;
                        }

                        .mbs-codes {
                            padding-left: 20px;
                        }

                        .column {
                            max-width: 304px;
                        }
                    }
                }
            }
        }

        .whats-next {
            .module-icon-cta-list {
                padding: 75px;
            }
        }

        .select-alternate {
            .bootstrap-select,
            .bootstrap-select.btn-group {
                .dropdown-toggle {
                    .bs-caret {
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    .filter-option {
                        @include fontSize(29px);
                        @include lineHeight(29px);
                    }
                }
            }
        }

        .agc-label {
            @include fontSize(12px);
            @include lineHeight(17px);
        }

        .agc-value {
            @include fontSize(20px);
            @include lineHeight(30px);
        }
    }
}