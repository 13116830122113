/**
*
* User story hero
*
**/
.user-story-hero {

	//Basic, common structure
	position: relative;
	margin-bottom: 100px;

	.image-block {
		height: 310px;
		overflow: hidden;
		position: relative;

		&:after {
			content: ' ';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;

			background: rgba(0,0,0,0.15);
		}

		img {
			width: 100%;
			@include centre('true', 'false', 'absolute');
		}
	}

	.content-block {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.content-wrap {
		display: table;
		width: 100%;
		height: 100%;
	}

	.inner {
		display: table-cell;
		width: 100%;
		height: 100%;
		padding-bottom: 20px;
		vertical-align: bottom;
		text-align: left;
	}

	.user-overview {
		transform: translateY(40%);
	}


	//Reusable text style, so not scoped within a parent selector
	p.user-desc {
		@extend %font-primary;
		@include fontSize(22px);
		color: white;
		display: inline;
		position: relative;
		top: -2px;

		span{
			@extend %font-secondary;
			@include fontSize(14px);
		}
	}


	//Initial copy block
	.intro-copy {
		margin-bottom: 15px;

		h2 {
			@extend %font-secondary;
			@include fontSize(22px);
			display: inline;
			font-style: italic;
			line-height: 1.3;
			margin-right: 20px;
			color: #FFFFFF;
		}

		.user-desc {
			display: none;
		}
	}


	//Overview includes price and CTA
	.cover-overview {
		@include clearfix-micro();
		position: relative;
		background-color: $primary_3;
		border-radius: 5px;
		padding: 15px;
		padding-right: 20px;
		color: white;

		.intro {
			display: none;
			@extend %font-secondary;
		}
	}

	//Left hand side, bordered (on desktop) element
	//listing cover types
	.cover-types {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		margin: 10px 0;
		border: solid 1px white;
		padding: 15px 15px;

		.product-name {
			width: 40%;
			@extend %font-primary;
			@include fontSize(20px);
			text-align: center;
		}
		.separator {
			width: 20%;
			text-align: center;
			svg.icon {
				fill: $neutral_white;
				height: 20px;
				width: 20px;
			}
		}

		@media #{$sm} {
			width: 55%;
			float: left;
			margin: 0;
			margin-right: 5%;
		}
	}

	//Price block, with price + cta
	.cover-price {
		width: 100%;

		p {
			@extend %font-primary;
			@include fontSize(28px);
			display: block;
			width: 50%;
			float: left;
			line-height: 1;
			margin: 0;
			padding: 0;
			padding-top: 10px;

			.currency {
				@include fontSize(13px);
				vertical-align: super;
				letter-spacing: -5.0px;
			}

			.period {
				@extend %font-secondary;
				@include fontSize(14px);
				display: inline-block;
				margin-left: 5px;
				opacity: 0.6;
				color: white;
			}
		}

		a {
			width: 50%;
			display: block;
			float: right;

			background: $neutral_white;
			color: $primary_2;

			&:visited {
				background: $neutral_white;
				color: $primary_2;
			}

			&:hover,
			&:focus {
				background: darken($neutral_white, 10);
			}

			&:active {
				background: darken($neutral_white, 20);
			}
		}
	}

	//Desktop
	@media #{$sm} {
		margin-bottom: 0%;

		.image-block {
			width: 100%;
			height: auto;
			position: static;
			img {
				width: 100%;
				position: static;
				left: auto;
				right: auto;
				transform: none;
			}
		}

		.user-overview {
			transform: none;
		}

		.intro-copy {
			h2 {
				@include fontSize(30px);
			}

			.user-desc {
				display: inline-block;
			}
		}

		.cover-overview {
			padding: 20px;

			.intro {
				@include fontSize(15px);
				display: block;
			}

			.user-desc {
				display: none;
			}
		}

		.cover-price {
			width: 40%;
			float: left;

			p {
				@include fontSize(40px);
				width: 100%;
				float: none;
				padding-top: 0px;

				.currency {
					@include fontSize(20px);
				}

				.period {
					@include fontSize(16px);
				}
			}

			a {
				width: 100%;
				display: block;
				float: none;
				margin-top: 14px;
			}
		}
	}
}