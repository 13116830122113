/**
*
* Common Join Form elements and structure
*
**/

.join-form {
	
	.loading-panel.global {
		@include loading-panel();
		position: fixed;

		.content {
			.copy {
				top: 0;
				top: 0px;
				@include fontSize(31px);
				@include font-primary();
				color: $primary_4;
				text-align: center;
			}
			.loader {
				top: 62px;
				top: 151px;
			}
		}
	}


	.rebate-form-loading {
		position: fixed; 
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(0,0,0,0.4);
		z-index: 1000000000;

		
		.inner {
			position: absolute;
			top:0;
			left: 50%;
			transform: translate(-50%, 0);

			box-shadow: rgba(0,0,0,0.4) 1px 1px 10px;
			background: white;
			padding: 40px;
			padding-bottom: 70px;

			.loader {
				top: 70%;
			}

			
			.buttons {
				width: 100%;
				margin: 10px;
				display: flex;
                
                .sg-btn {
					width: 100%;
				}
			}
		}
		
		&.error {
			.inner {
				padding-bottom: 40px;

				.message {
					text-align: left;
					padding-bottom: 20px;
				}
			}
		}
		
		@media #{$sm} {
			.inner {
				.buttons {

					flex-direction: row-reverse;
				}	
			}
		}
		
		
		
	}

	.global-indicates {
		font-size: 80%;
		margin-top: 24px;
	}
	@media #{$sm} {
		.global-indicates {
			float: right;
		}
	}

	.medicare-rebate-question {
		padding: 20px;
		background: $neutral_5;
		margin-bottom: 20px;
		@include fontSize(14px);
	}

	.medicare-card {
		padding-bottom: 48px;
		padding-top: 16px;
		
		img {
			width: 100%;
			max-width: 271px;
			height: auto;
		} 
	}

	@media #{$sm} {
		.medicare-card {
			img {
				max-width: 309px;
			}
		}
	}
}
.field-wrap.text-private,
.field-wrap.bsb-private{
	position: relative;
	.toggle-btn {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.field-wrap.bsb,
.field-wrap.address {
	.input {
		position: relative;
	}
	.validation-check {
		position: absolute;
		top: 14px;
		right: 15px;

		svg.icon {
			fill: $neutral_2;
		}
	}
}

.field-wrap.bsb {
	.loading {
		position: absolute;
		top: 15px;
		right: 15px;
		width: 24px;
		height: 20px;
		.loader-field {
			.dot {
				background: $neutral_2;
			}
		}
	}
}

//Top level join form parent
.join-form-container {

	h1 {

		&.tab-title {
			margin-top: 38px;
    		margin-bottom: 3px;
    	}

		.label-number,
		.label-text {
			@extend %font-primary;
	    	@include fontSize(18px);
			color: $primary_2;
		}

		.label-number {
			@extend %font-primary-light;
		}
	}

	//Tab introduction container
	.introduction {
		padding-bottom: 20px;
	}

    .note{
        margin-bottom: 30px;
    }

	//Desktop
	@media #{$sm} {

		position: relative;
		top: -90px;

		h1 {
			.label-number {
				@include fontSize(32px);
			}

			.label-text {
		    	@include fontSize(32px);
			}
		}
	}
}

.ngdialog.ngdialog-theme-plain.save-application .ngdialog-content {
	.form{
		padding-top: 10px;
	}
	.icon {
		padding-top: 0;
	}

}

//Phone number multi-field element
//Specific to join form at this stage
.phone-number-field {
	@include clearfix-micro();
	padding-bottom: 30px;

	.field-wrap {
		padding-bottom: 15px;
	}

	.ngdialog.ngdialog-theme-plain .ngdialog-content & {
		.btn-add {
			svg.icon {
				color: $neutral_2;
				height: 18px;
				width: 18px;
				margin: 0;
			}
		}


		.btn-remove {
			svg.icon {
				color: $neutral_1;
				height: 12px;
				width: 12px;
				margin: 0;
			}
		}
	}
}

/**
*
* Autocomplete fields
* Note: inputs / fields themselves aren't styled directly,
* Just the resulting dropdown list and no results block
**/
//Parent div
.autocomplete {
	.no-results {
		@extend %font-secondary;
		@include fontSize(14px);
		color: $neutral_2;
		width: 100%;
		padding: 0;
		margin: 0;
		background-color: $neutral_6;
		border-style: solid;
		border-color: $neutral_3;
		border-width: 1px;
		padding: 16px 20px 16px;

	}
}

//Dropdown list
.autocomplete-list {
	width: 100%;
	padding: 0;
	margin: 0;
	background-color: $neutral_6;
	border-style: solid;
	border-color: $neutral_3;
	border-width: 0px 1px 1px 1px;

	display: block;
	list-style-type: none;

	li {
		position: relative;
		padding: 16px 40px 16px;
		border-bottom: solid 1px $neutral_5;

		&:last-child {
			border-bottom: solid 0px $neutral_5;
		}

		//Location indicator
		&:before {
			@include icon();
			@include fontSize(18px);
			color: $neutral_3;
			content: "\33";
			position: absolute;
			left: 12px;
			top: 17px;
		}
	}

	a {
		@extend %font-secondary;
		@include fontSize(14px);
		color: $neutral_2;
	}
}


/*
THFM-509 -
Please move below block if there is a more appropriate place for it
 */
.join-form {
	.label {
		display: block;
		padding-bottom: 10px;

		label {
			display: inline;
		}
	}
}
