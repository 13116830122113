.ngdialog.ngdialog-theme-plain.debugModal {

    .ngdialog-content {
        width: 1000px;
        text-align: left;
        form {
            .tabs {
                button {
                    float: left;
                    margin: 2px;
                    font-size: 13px;
                    padding-left: 10px;
                    padding-right: 10px;

                    &.btn-close {
                        float: right;
                    }
                }
            }
        }
    }

    &.debugManualData .ngdialog-content form button.btn-close {
        float: left;
    }
}

.debug-links {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #333333;
    font-size: 12px;
    z-index: 120000;

    ul {
        list-style: none;
        li {
            position: relative;
            float: left;
            display: block;
            border-right: 1px solid #E9E9E9;
            color: #FFFFFF;
            cursor: pointer;
            a {
                display: block;
                padding: 13px;
                color: #FFFFFF;

                &:hover {
                    color: #333333;
                    background: #CCCCCC;
                }
            }

            ul {
                display: none;
                position: absolute;
                left: 0;
                bottom: 43px;
                width: 200px;
                background: #333333;
                padding-left: 0;

                li {
                    display: block;
                    float: initial;
                    border-right: none;
                    border-bottom: 1px solid #E9E9E9;
                }

            }

            &:hover {
                ul {
                    display: block;
                    li {

                    }
                }
            }


        }
    }
}

.api-details {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 10px 20px;
    text-align: center;
    z-index: 200000000;

    &.development-api {
        background: #0066CC;
        color: #FFFFFF;
    }
    &.test-api {
        background: #FF9900;
        color: #FFFFFF;
    }
    &.production-api {
        background: #009900;
        color: #FFFFFF;
    }
}    


.debug-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -362px;
    margin-top: -250px;
    width: 724px;
    height: 500px;

    background-color: rgba( 255, 255, 255, .95);
    z-index: 120000;
    border: 1px solid #000000;
}