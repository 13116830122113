/**
*
* Quick links / USP tiles
*
**/
.quick-links {
	//Common, Base, structural
	background-color: $neutral_6;
	padding-top: 20px;
	padding-bottom: 20px;

	.quick-link-item {
		padding-bottom: 20px;
	}

	a {
		border: none;
		text-decoration: none;
	}

	.icon-wrap {
		background-color: $primary_2;
		height: 36px;
		width: 36px;
		border-radius: 18px;
		margin: auto;
		position: relative;
	}

	svg.icon {
		color: white;
		height: 18px;
		width: 18px;
		position: relative;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		margin-top: -1px; // Moving it up as the designers asked for it to be "centred".
	}

	.copy {
		padding-top: 20px;
		text-align: center;

		p {
			@include fontSize(18px);
			font-family: $fontPrimary_light;
			color: $neutral_2;
			line-height: 1.2;
		}
	}

	//Desktop
	@media #{$sm} {
		min-height: 183px;
		padding-top: 60px;

		.copy-col {
			padding-right: 0;
		}

		.quick-link-item {
			height: auto;
		}

		.copy {
			padding-top: 0;
			text-align: left;
			margin-left: -2px;
		}
	}
}
