.module-article-tiles {

    .tile {
        position: relative;
        margin: 12px;

        .image-block {
            :after {
                display: block;
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: linear-gradient(180deg, rgba(255,255,255,0) 0.85%, rgba(0,0,0,1) 100%);
                opacity: 0.3;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .content-block {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;

            .title-copy {
                @extend %header-large;
                color: $neutral_white;

                a, .txt {
                    display: block;
                    padding-left: 24px;
                    padding-bottom: 14px;
                    padding-right: 64px;
                    color: $neutral_white;
                }
            }

            .chevron {
                position: absolute;
                bottom: 14px;
                right: 24px;
                color: $neutral_white;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media #{$sm} {
        .description {
            margin-bottom: 24px;
        }

        .tiles {
            display: flex;
            flex-wrap: wrap;
        }

        .tile {
            margin: 0;
            padding-bottom: 34px;
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 12px;
            }

            .image-block {
                position: static;

                :after {
                    display: none;
                }
            }

            .content-block {
                position: static;

                .title-copy {
                    color: $primary_2;

                    a, .txt {
                        padding: 0;
                        margin-top: 28px;
                        margin-bottom: 12px;
                        color: $primary_2;
                    }
                }
            }

            .link-block {
                position: absolute;
                left: 12px;
                bottom: 0;
            }

            .description {
                text-align: left;
                margin-bottom: 0;
                color: $primary_2;

                p:last-child {
                    margin-bottom: 0;
                }
            }

            &:first-child:nth-last-child(4),
            &:first-child:nth-last-child(4) ~ .tile {
                @include make-sm-column(6, 24px);

                &:nth-last-of-type(-n+2) {
                    margin-bottom: 0;
                }
            }

            &:first-child:nth-last-child(3),
            &:first-child:nth-last-child(3) ~ .tile {
                @include make-sm-column(4, 24px);

                &:nth-last-of-type(-n+3) {
                    margin-bottom: 0;
                }
            }

            &:first-child:nth-last-child(2),
            &:first-child:nth-last-child(2) ~ .tile {
                @include make-sm-column(6, 24px);

                &:nth-last-of-type(-n+3) {
                    margin-bottom: 0;
                }
            }
        }
    }
}
