/**
*
* Promo Tiles module
* 3 rows of tiles with image bg
*
**/
.promo-tiles {

	//Common, core styles
	//Structure
	$customGridPadding: 5px;

	padding-left: 6px;
	padding-right: 6px;


	//Override grid setttings
	.row > div {
		padding-left: 5px;
		padding-right: 5px;
	}


	.tile {
		position: relative;
		height: auto;
		max-height: 191px;
		width: 100%;
		margin-bottom: $customGridPadding * 2;
		overflow: hidden;
	}

	.image-block {
		width: 100%;
		height: 100%;
		background-color: grey;
		overflow: hidden;

		img {
			width: 100%;
		}
	}

	.content-block {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

	}

	.content-inner {
		display: table-cell;
		width: 100%;
		height: 100%;
		vertical-align: middle;
	}

	//Inner stylings
	a {
		display: table;
		position: relative;
		width: 100%;
		height: 100%;
		padding: 30px;
		border: none;
		text-decoration: none;
		text-transform: uppercase;
		outline: none;

		svg.icon {
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
			height: 22px;
			width: 22px;

			&.icon-tile-arrow {
				color: $neutral_white;
			}
		}
	}

	p {
		@extend %font-primary;
		@include fontSize(18px);
		display: inline-block;
		width: 80%;
		color: white;
		line-height: 1;
		padding: 0;
		margin: 0;
	}




	/**
	*
	* First tile
	* Slightly different styles as first tile includes CTA button element
	*
	**/

	.tile.first {	//Class names MAY change - will discuss with Zhen.

        .title-copy {
            width: 54%;
        }

		.button-copy {
			display: block;
			width: auto;
			position: absolute;
			background-color: white;
			border-radius: 4px;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
			padding: 16px 24px 16px 30px;
			padding-top: 9px;
			padding-bottom: 8px;

			text-align: center;

			color: $primary_2;
			font-size: 18px;
			text-transform: initial;

			span {
				display: inline-block;
				padding-top: 6px;
				padding-bottom: 8px;
				padding-right: 12px;
			}

			svg.icon {
				height: 0.75em;
				width: 0.75em;
			}

			&:focus,
			&:hover {
				background-color: darken( white, 10% );
				color: $primary_2;
			}
		}
	}


	//Desktop
	@media #{$sm} {
		a {
			svg.icon.icon-tile-arrow {
				display: none;
			}
		}
		//Inner stylings
		p {
			@include fontSize(28px);
		}


		.tile.first {
			.button-copy {
				padding: 16px 55px;
                padding-top: 9px;
                padding-bottom: 8px;
			}
		}
	}
}



