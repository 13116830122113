.search-mobile {
    //Default mobile first
    @include clearfix-micro();
    display: none;

    input {
        @extend %font-secondary;
        @include fontSize(14px);
        display: block;
        width: 80%;
        padding: 0;
        background: none;
        float: left;
        height: 30px;
        line-height: 30px;
        color: $primary_2;
        border: none;
        outline: none;


        &::-webkit-input-placeholder {
            color: $neutral_1;
            font-style: normal;
        }

        &:-moz-placeholder { /* Firefox 18- */
            color: $neutral_1;
            font-style: normal;
        }

        &::-moz-placeholder {  /* Firefox 19+ */
            color: $neutral_1;
            font-style: normal;
        }

        &:-ms-input-placeholder {
            color: $neutral_1;
            font-style: normal;
        }

        &:focus {
            background: none;
            border: none;
        }
    }

    .search-submit {
        display: block;
        position: relative;
        top: 5px;
        float: right;
        padding: 0;
        background: none;
        border: 0;

        &:hover,
        &:active,
        &:focus {
            background: none;
            border: 0;
        }

        svg.icon {
            height: 18px;
            width: 18px;
            color: $primary_2;
        }
    }
}