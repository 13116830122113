/*
Module CSS
 */
.module-two-columns-promo-tile {
    width: 100%;

    .left-promo-tile {
        margin-bottom: 40px;
    }

    //Desktop
    @media #{$sm} {
        .left-promo-tile {
            margin-bottom: 0;
        }
    }
}

/*
Module Child Promo tiles
 */
//Shared styles
.promo-tile {
    width: 100%;
    height: 429px;
    position: relative;

    .inner {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 40px 40px 50px 40px;
    }

    .title {
        @extend %font-primary;
        @include fontSize(52px);
        @include lineHeight(52px);
    }

    .sg-btn {
        margin-top: auto;
        margin-right: auto;

        //Override default btn colours
        background-color: white;
        color: $primary_2;
        border: 2px solid white;

        &:visited {
            background-color: white;
            color: $primary_2;
            border-color: white;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $neutral_4;
            color: darken($primary_2, 10);
            border-color: $neutral_4;
        }

        &:active {
            background-color: white;
            color: darken($primary_2, 20);
            border-color: white;
        }

        &:disabled {
            background-color: white;
            color: $neutral_4;
            border-color: white;
        }
    }

    /*
    Brand colour variants
     */
    &.style-primary2 {
        background-color: siteSetting($backgroundTextHighlight-primary2, backgroundColor, $primary_2);

        .title {
            color: siteSetting($backgroundTextHighlight-primary2, txtColor, $neutral_white);
        }

        .highlight {
            color: siteSetting($backgroundTextHighlight-primary2, txtHighlightColor, $primary_4);
        }
    }

    &.style-primary3 {
        background-color: siteSetting($backgroundTextHighlight-primary3, backgroundColor, $primary_2);

        .title {
            color: siteSetting($backgroundTextHighlight-primary3, txtColor, $neutral_white);
        }

        .highlight {
            color: siteSetting($backgroundTextHighlight-primary3, txtHighlightColor, $primary_4);
        }
    }

    &.style-primary4 {
        background-color: siteSetting($backgroundTextHighlight-primary4, backgroundColor, $primary_2);

        .title {
            color: siteSetting($backgroundTextHighlight-primary4, txtColor, $neutral_white);
        }

        .highlight {
            color: siteSetting($backgroundTextHighlight-primary4, txtHighlightColor, $primary_4);
        }
    }
}

//Promo tile with icon
.promo-tile-icon {
    .promo-icon {
        height: 70px;
        margin-bottom: 20px;
    }

    img {
        height: 100%;
    }
}

//Promo tile with background image
.promo-tile-background-image {
    .background-image {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: #061D45;
            opacity: 0.15;
        }
    }

    .title {
        color: white;
    }

}

















