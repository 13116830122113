.module-intro-action-ctas {

    .title {
        @extend %font-primary;
        @include fontSize(26px);
        @include lineHeight(30px);
        color: $primary_2;
        margin-bottom: 12px;
    }

    .tile-wrapper {
        margin-top: 30px;
    }

    .tile {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: #FFFFFF;
        box-shadow: 0 2px 3px rgba(28, 37, 44, 0.2);
        border: 1px solid $neutral_4;
        padding: 15px 20px;
        margin-bottom: 30px;

        p {
            @include fontSize(14px);
            @include lineHeight(19px);
        }
    }

    .sg-btn-secondary {
        border-color: $primary-2;
        color: $primary-2;

        &:hover {
            background-color: $primary-2;
            color: white;
        }
    }

    @media #{$sm} {
        .tile-wrapper {
            margin-top: 0;
        }
    }
}
