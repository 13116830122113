/**
*
* Text + CTA module
*
**/
.module-text-block-cta {

    .headline {
        @include font-primary();
        margin-bottom: 15px;
        color: $primary_2;
    }

    .sub-title {
        margin-bottom: 16px;
    }

    .body-copy {
        margin-bottom: 16px;
    }

    h2 {
        @include fontSize(36px);
        @include lineHeight(48px);
    }

    h3 {
        @include fontSize(24px);
        @include lineHeight(34px);
    }
}
