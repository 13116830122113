.module-video {
    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        width: 100%;
        height: auto;

        iframe,
        object,
        embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .text-block {
        color: $primary_2;

        &.center {
            text-align: center;

            .title {
                text-align: center; //Conflicts with global .module.title
            }
        }
    }

    .title {
        @include font-primary();
        @include fontSize(26px);
        @include lineHeight(30px);
        margin-bottom: 7px;
        color: inherit;
    }

    .sub-title {
        @include fontSize(20px);
        @include lineHeight(27px);
        color: inherit;
        margin-bottom: 24px;
    }

    //Desktop
    @media #{$sm} {
        .title {
            @include fontSize(36px);
            @include lineHeight(40px);
            margin-bottom: 16px;
        }
    }
}
