.module-link-list {
    color: $neutral_2;

    h2.title {
        margin-bottom: 8px;
    }

    .description {
        margin-bottom: 16px;
    }

    ul {
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;

        li {
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    svg {
        color: $neutral_2;
        margin-right: 4px;
    }

    a {
        &:hover {
            text-decoration: underline;
        }
    }
}