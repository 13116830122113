.module-text-block {
    p:last-child {
        margin-bottom: 0;
    }

    .padding {
        padding: 64px 12px;
    }

    &.bg-primary1, .bg-primary1 {
        background-color: $primary_1;
    }

    &.bg-primary2, .bg-primary2 {
        background-color: $primary_2;
    }

    &.bg-primary3, .bg-primary3 {
        background-color: $primary_3;
    }

    &.bg-primary4, .bg-primary4 {
        background-color: $primary_4;
    }

    &.bg-primary5, .bg-primary5 {
        background-color: $primary_5;
    }

    &.bg-primary6, .bg-primary6 {
        background-color: $primary_6;
    }

    &.bg-primary2, .bg-primary2,
    &.bg-primary4, .bg-primary4 {
        color: white;

        .title, h1, h2, h3, h4, h5, h6 {
            color: white;
        }
    }

    h2 {
        @include fontSize(36px);
        @include lineHeight(48px);
    }

    h3 {
        @include fontSize(24px);
        @include lineHeight(34px);
    }

    .sg-btn{
        text-decoration: none;
    }
}
