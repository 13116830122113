/**
*
* Health Hub Article feature
*
**/
.article-feature {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    min-height: 294px;
    height: 294px;

    &.tall {
        min-height: 500px;
        height: 500px;
    }

    .img-layer {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    picture,
    img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }
}
