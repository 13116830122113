/**
*
* Join Form Page actions
* Next, prev, save for later buttons
*
**/
.page-actions {

	.navigation-block {
		@include clearfix-micro();
	}

	.save-progress-block {
		padding-top: 30px;
		text-align: center;

		p {
			@extend %font-primary-light;
			@include fontSize(14px);
			margin-bottom: 6px;
			color: $neutral_2;
		}
	}

	//Desktop
	@media #{$sm} {
		padding-left: 40px;
		padding-right: 40px;

		.save-progress-block {
			padding-top: 50px;

			p {
				@include fontSize(18px);
			}
		}
	}
}
