/**
 Collection of flex utilities to help with layout, structure etc
 A lot of these can be found in Bootstrap 4, but not 3

**/

//Use for equal height colums - add class 'eq-height-cols' to your row, and good to go!
.row.eq-height-cols {
    display: flex;
    flex-wrap: wrap;

    [class*='col-'] {
        display: flex;
        flex-direction: column;
    }
}