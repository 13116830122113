/**
*
* A-feature slider controls
*
**/

//Circular pagination controls
//By default this is an <ol> element
$bulletSize: 10px;
$bulletSize_mobile: 10px;

.flex-control-paging {
	display: block;
	height: $bulletSize;
	margin: 0;
	padding: 0;
	list-style-type: none;
	text-align: center;


	li {
		display: inline-block;
		list-style-type: none;
		margin-right: 7px;
	}

	a {
		display: block;
		width: $bulletSize_mobile;
		height: $bulletSize_mobile;
		background-color: white;
		opacity: 0.3;
		border-radius: $bulletSize_mobile / 2;
		text-decoration: none;
		text-indent: -9999px;
		cursor: pointer;

		&.flex-active {
			opacity: 1;
		}
	}

	//768px +
	@media #{$sm} {
		li {
			margin-right: 10px;
		}

		a {
			width: $bulletSize;
			height: $bulletSize;
			opacity: 0.4;
			border-radius: $bulletSize / 2;
			background-color: $primary_2;
		}
	}
}

//Left + Right arrows
//NOTE: This are intentionally left without colour styling
//Colours will depend on media queries, and usage
//See individual component for colours etc
.flex-direction-nav {
	margin: 0;
	padding: 0;

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	li {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	a {
		outline: none;
		border: none;

		&:before {
			@include icon();
		}
	}

	.flex-prev:before {
		content: "\62";
	}

	.flex-next:before {
		content: "\63";
	}
}