/**
*
* Secondary copy module, title, text and CTA
*
**/
.secondary-copy {
	margin-top: 30px;
	text-align: left;

	h1 {
		@extend %font-primary;
		@include fontSize(20px);
		margin-top: 0;
		margin-bottom: 10px;
		text-align: left;
	}

	p {
		@extend %font-secondary;
		@include fontSize(16px);
		margin-bottom: 35px;
		text-align: left;

		a {
			color: $primary_2;
			text-decoration: underline;
		}
	}

	//Desktop
	@media #{$sm} {
		margin-top: 40px;
		text-align: center;

		h1 {
			@include fontSize(24px);
			text-align: center;
		}

		p {
			text-align: center;
		}
	}
}