.hh-search-result {
    padding: 0 14%;
    .search-result-title {
        margin-top: 60px;
        margin-bottom: 0px;
        line-height: 45px;
    }

    .search-count {
        color: $neutral_black;
        @include fontSize(18px);
        margin-bottom: 48px;
    }

    .search-container {
        .search-record {
            .title {
                @extend %font-primary;
                @include fontSize(24px);
                padding-bottom: 24px;

                a {
                    color: $primary_2;
                }
            }

            .description {
                @include fontSize(18px);
                padding-bottom: 24px;
            }

            .url {
                color: $neutral_3;
            }
        }
    }
}

.no-border {
    border: none !important;
}

.basic-content {
    .topics {
        border-top: 1px solid $neutral_1;
        margin-bottom: 60px;

        h3 {
            margin-top: 42px;
            @include fontSize(32px)
        }
    }

    .service-section {
        .title {
            @include fontSize(24px)
        }
    }
}
