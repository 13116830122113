/**
*
* Tooltip popups
* NOTE: Some styles override default plugin CSS
*
**/

/**
*
* Tooltip popups
* NOTE: Some styles override default plugin CSS
*
**/
//Tooltip trigger
.tooltipster-default {
	border-radius: 5px;
	border: none;
	background: $primary_4;
	color: white;
}

.tooltipster-default .tooltipster-content {
	@extend %font-secondary;
	@include fontSize(12px);
	font-weight: bold;
	color: white;
	line-height: 19px;
	padding: 9px 15px;
	overflow: hidden;
}

.join-form {
	//Tooltip trigger
	.tooltip {
		display: inline-block;
		position: relative;
		margin-left: 5px;
		top: 2px;
		cursor: pointer;

		.icon {
			@include fontSize(14px);
			color: $neutral_3;
		}

		svg.icon {
			color: $neutral_3;
			width: 14px;
			height: 14px;
			position: relative;
			top: 0; //Reverted to correct issue THFM-509
			left: 0;

		}

		&.active,
		&:hover {
			.icon {color: $neutral_2;}
		}
	}
}

.tooltipster-default.tooltipster-join {
	border-radius: 5px;
	border: none;
	background: $neutral_2;
	color: white;
}

.tooltipster-default.tooltipster-join .tooltipster-content {
	@extend %font-secondary;
	@include fontSize(12px);
	font-weight: normal;
	color: white;
	line-height: 19px;
	padding: 15px;
	overflow: hidden;
}