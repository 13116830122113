#primary-nav {

	height: 88px;

	.inner-wrap {
		height: 100%;
	}

	/**
	*
	* Top Level list
	*
	*
	**/
	ul.top-level  {
		height: 100%;
		margin: 0;
		padding: 0;
		list-style-type: none;

		display: flex;
		align-items: flex-end;
		justify-content: flex-end;

		> li{
			display: block;
			height: 100%;

			&.contact-text {
				display: none;
			}
		}

		.primary-link {
			@include fontSize(20px);
			@extend %font-primary;
			position: relative;
			display: block;
			height: 100%;
			color: $primary_2;
			text-decoration: none;
			text-align: center;
			padding: 17px 10px;
			padding-top: 25px;

			svg.icon {
				display: none;
			}

			//Active underline state
			&:after {
				@include animate-custom(#{'opacity'}, 350ms);
				content: '';
				display: block;
				width: calc(100% - 20px);
				height: 5px;
				background-color: $primary_4;
				position: absolute;
				bottom: 24px;
				left: 10px;
				opacity: 0;
				z-index: 9999;
			}

			&.active {
				&:after {
					opacity: 1;
				}
			}
		}

	}

	/**
	*
	* Secondary, megamenu dropdown
	*
	*
	**/
	.secondary-nav {
		display: none;
		position: absolute;
		left: 0;
		width: 100%;
		z-index: 200;


		.inner {
			background-color: #F9F9F9;
			border-radius: 0 0 5px 5px;
			padding: 37px 55px;
		}

		//Second level uls + li
		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;

			&.second-level {
				display: block;
				width:33%;
				float: left;

				> li {
					> a {
						@extend %font-secondary-bold;

						svg.icon {
							display: none;
						}

						&.external-link {
							svg.icon {
								display: inline-block;
							}
						}
					}
				}
			}

			//Specific styles for 3rd column, second level lists
			&:nth-child(n+4){
				padding-top: 26px;
			}
		}

		div.grouped {
			display: block;
			width:33%;
			float: left;
			&:nth-child(n+4){
				padding-top: 26px;
			}
			ul.second-level {
				padding-top: 26px;
				float: none;
				width: 100%;
				display: block;
				
				&:first-child {
					padding-top: 0;
				}
			}
		}

		li {
			@extend %font-secondary;
			@include fontSize(16px);
		}

		//Common link styles
		a {
			@include fontSize(15px);
			@include lineHeight(34px);
			color: $neutral_2;
			text-decoration: none;

			&:hover {
				color: $primary_2;
				text-decoration: underline;
			}
		}

		//External links
		.external-link {
			position: relative;
			padding-right: 10px;
			color: $primary_2;

			svg.icon {
				margin-left: 5px;
			}
		}

		//Third level uls + li
		ul.third-level {
			margin-top: 13px;

			li {
				margin-bottom: 8px;
			}
		}
	}

	/**
	*
	* Supporting mobile tools - search etc
	*
	*
	**/
	.mobile-tools {
		display: none;
	}
}