.service-section {
    div > div {
        background: $neutral_6;
        border-radius: 10px;
        padding: 44px 15px 30px 15px;
        text-align: center;
        min-height: 222px;
    }
    
    .space {
        padding-left: 20px;
        padding-right: 20px;
    }

    .icon {
        font-size: 5rem;
    }

    p.title {
        font-size: 1.5em;
        margin-bottom: 0;
        padding-top: 12px;
        color: $primary_2;
        line-height: normal;
        font-weight: 400;
        font-family: "TTLakesCompressed-Bold", Calibri, sans-serif;
    }
}