/**
*
* Join form styles - confirmation tap
*
**/

//Top level parent
.tab.confirmation {
	.section-header {
		@include clearfix-micro();

		.section-title {
			float: left;

			.label-number {
				@extend %font-primary-light;
				@include fontSize(14px);
				color: $primary_2;
			}

			.label-text {
				@extend %font-primary;
		    	@include fontSize(14px);
		    	color: $primary_2;
			}
		}


		.btn-edit {
			@extend %font-primary;
			@include fontSize(9px);
			position: relative;
			top: 5px;
			float: right;
			color: $primary_2;
			text-decoration: underline;

			svg.icon {
				display: inline;
				height: 10px;
				width: 10px;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.content-block {
		padding-bottom: 25px;
	}

	//Desktop
	@media #{$sm} {
		.section-header {

			.section-title {
				float: left;

				.label-number {
					@include fontSize(20px);
					color: $primary_2;
				}

				.label-text {
			    	@include fontSize(20px);
			    	color: $primary_2;
				}
			}

			.btn-edit {
				@include fontSize(13px);
				color: $primary_2;
				text-decoration: none;
			}
		}
	}
}
