.module-two-column {

    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child,
    h5:first-child,
    h6:first-child {
        margin-top: 0;
    }

    @each $class, $colour in $brandColoursMap {
        &.background-#{$class} {
            padding: 23px 0;
            margin: 0;
        }
    }

    &.background-primary1,
    &.background-primary2,
    &.background-primary3,
    &.background-primary4 {
        color: $neutral_white;
        h1, h2, h3, h4, h5, h6 {
            color: $neutral_white;
        }
    }

    .module:first-child {
        margin-bottom: 24px;
    }
    .module:last-child {
        margin-top: 24px;
    }

    .mobile-reverse{
        display:flex;
        flex-direction: column-reverse;
    }

    //Desktop
    @media #{$sm} {
        .columns {
            display: flex;
            align-items: flex-start;
        }

        .mobile-reverse{
            display:flex;
            flex-direction: row;
        }

        &.vertical-align-middle {
            .columns {
                align-items: center;
            }
        }

        &.vertical-align-bottom {
            .columns {
                align-items: flex-end;
            }
        }

        .module:first-child {
            margin-bottom: 0;
        }
        .module:last-child {
            margin-top: 0;
        }

        .module {
            width: 50%;
        }
	
	@each $class, $colour in $brandColoursMap {
            &.background-#{$class} {
                padding: 46px 0;
            }
        }
	
    }
}
