/**
*
* User stories component
*
**/
.user-stories {
	margin-top: 45px;
	min-height: 400px;

	//Common, mobile first
	.intro-block {
		margin-bottom: 40px;
		text-align: left;

		h1 {
			@extend %font-primary;
			@include fontSizeFluid(26px);
			margin: 0;
			margin-bottom: 10px;
			padding: 0;
			color: $primary_2;
		}

		p {
			@extend %font-primary-light;
			@include fontSizeFluid(16px);
			margin: 0;
			padding: 0;
			line-height: 1;
			color: $neutral_2;
		}
	}

	//Witchcraft and sorcery to allow margin
	//between slides but hide the overhang
	$margin: 7px;
	.slider-block {
		position: relative;
		margin-left: - $margin / 2;
		margin-right: - $margin / 2;

		&:before,
		&:after {
			content: '';
			width: $margin;
			height: 100%;
			background-color: white;
			position: absolute;
			top: 0;
			z-index: 100;
		}

		&:before { left: 0};
		&:after { right: 0};
	}

	.slide {
		max-width: 50%;
	}

	.story-block {
		margin: 0px $margin;
	}

	//Image and primary copy
	.img-block {
		position: relative;

		img {
			width: 100%;
		}
	}

	.copy-block {
		width: 100%;
		height: auto;
		position: absolute;
		left: 0;
		bottom: 0;
		padding: 18px 20px;
		color: white;
	}

	.user-details {
		@extend %font-primary;
		@include fontSize(30px);
		@include fontSize(siteSetting($userStories_slider, userDetails_fontSize, 32px));
		margin-bottom: 0;
	}

	.user-story {
		@extend %font-secondary-light;
		@include fontSize(18px);
		display: block;
		float: left;
		margin: 0;
		padding: 0;
		width: 70%;
		font-style: italic;
		line-height: 1.4;
	}

	.detail-link {
		@extend %font-secondary-light;
		@include fontSize(16px);
		display: block;
		position: absolute;
		left: 70%;
		bottom: 18px;
		color: white;
		text-decoration: none;
		border-bottom: solid 1px rgba(255,255,255,0.5);
	}

	//Cover row and children
	.cover-row {
		width: 100%;
		height: 80px;
		line-height: 0;
	}

	.cover-type-block {
		width: 62%;
    	height: 100%;
    	float: left;

		.cover-type-block-inner {
			display: table;
			background-color: $neutral_6;
			width: 100%;
			height: 100%;
			float: left;
			text-align: center;
			line-height: normal;

			> div {
				@extend %font-primary;
				@include fontSize(15px);
				color: $primary_4;
				vertical-align: middle;
				display: table-cell;
			}
		}

		&.single {
		}

		//Apply '+' icon
		&.double {

			.cover-type-block-inner > div {
				&:first-child {
					padding-right: 0;
					text-align: right;
				}

				&:last-child {
					padding-left: 0;
					text-align: left;
				}

				span {
					text-align: center;
					display: inline-block;
				}
			}

			.cover-type-block-separator {
				text-align: center;
				padding: 0 11px;

				svg.icon {
					color: $neutral_2;
					height: 14px;
					width: 14px;
					margin-top: 9px;
				}
			}
		}
	}


	.cta-block {
		float: right;
		width: 38%;
		height: 100%;
	}


	.get-quote {
		@include animate-background();
		background-color: $primary_3;
		display: block;
		height: 100%;
		position: relative;
		text-align: center;
		color: white;

		span {
			display: inline-block;
		}

		.copy {
			position: relative;
			@include fontSize(siteSetting($userStories_slider, getQuoteCTA_fontSize, 18px));
			@extend %font-primary;
			line-height: siteSetting($userStories_slider, getQuoteCTA_lineHeight, 86px);
		}

		svg.icon {
			position: relative;
			height: 0.85em;
			width: 0.85em;
			margin-left: 7px;
		}


		&:hover {
		 	background-color: darken( $primary_3, 10% );
		}
	}

	.slider-controls {
		position: relative;
		z-index: 10;
		a {
			svg.icon {
				color: white;
				height: 25px;
				width: 25px;
			}
		}
	}

	//Slider pagination controls
	.flex-prev,
	.flex-next {
		position: absolute;
		top: 10px;

		&:before {
			font-size: 26px;
			color: white;
		}
	}

	.flex-prev {
		left: 10px;
	}
	.flex-next {
		right: 10px;
	}


	//Desktop
	@media #{$sm} {
		margin-top: 75px;

		.intro-block {
			text-align: center;

			h1 {
				@include fontSize(siteSetting($userStories_intro, header_fontSize, 32px));
			}

			p {
				@include fontSizeFluid(20px);
			}
		}

		.cover-type-block {
			.cover-type-block-inner > div {
				@include fontSize(siteSetting($userStories_slider, coverTypeBlock_fontSize, 17px));
			}
		}

		.slider-controls {
			a {
				svg.icon {
					color: $primary_2;
				}
			}
		}

		.flex-prev,
		.flex-next {
			top: 190px;
		}

		.flex-prev {
			left: -25px;
		}
		.flex-next {
			right: -25px;
		}
	}
}
