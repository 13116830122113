/**
*
* Contact us page and associated components
*
**/
.contact-us {

	h1 {
		@extend %font-primary;
		@include fontSizeFluid(18px);
		margin-top: 35px;
		margin-bottom: 10px;
		color: $primary_2;
	}

	h2 {
		@extend %font-primary;
		@include fontSizeFluid(14px);
		margin-top: 0px;
		color: $neutral_2;
	}

	h3 {
		@extend %font-primary;
		@include fontSizeFluid(14px);
		margin-top: 0px;
		color: $primary_2;
	}

	p {
		@extend %font-secondary;
		@include fontSizeFluid(12px);
		color: $neutral_2;

		&:last-child {
			margin-bottom: 0;
		}
	}


	/**
	*
	* Tab buttons
	*
	**/
	.tab-btns-row {
		@include clearfix-micro();
		position: relative;
		z-index: 10;

		li {
			position: relative;
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
			text-align: center;

			//Active state
			&.active {
				a {
					border-top: solid 3px $primary_2;
					border-left: solid 1px $neutral_5;
					border-right: solid 1px $neutral_5;
					background-color: white;
				}
			}
		}


		a {
			@extend %font-primary;
			@include fontSizeFluid(14px);
			background-color: #dcdcdc;
			position: relative;
			display: block;
			width: 100%;
			height: 60px;
			padding: 0 10px;
			border-top: solid 3px $neutral_1;
			border-left: solid 1px $neutral_5;
			border-right: solid 1px $neutral_5;
			color: $neutral_2;


			span {
				display: block;
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				line-height: 1.2;
			}
		}
	}


	/**
	*
	* Tabs content
	*
	**/
	.tab-pane {
		padding-top: 30px;
		border-right: 1px solid $neutral_5;
		border-bottom: 1px solid $neutral_5;
		border-left: 1px solid $neutral_5;

		> .text-block:first-child {
			h1 {
				margin-top: 0;
			}
		}
	}


	.tab-content {
		border-top: 1px solid $neutral_5;
		position: relative;
		top: -2px;
		z-index: 5;

		section {
			padding-bottom: 40px;
			border-bottom: 1px solid $neutral_5;

			> .text-block {
				margin-top: 40px;

				&:first-child {
					p:first-child {
						margin-top: -30px;
					}
				}
			}

			&:last-of-type {
				border-bottom: none;
			}
		}

		.state-block {
			padding-top: 50px;
			margin-bottom: 115px;
		}

		.text-block {
			padding-left: 30px;
			padding-right: 30px;
		}

	}

	/**
	*
	* Form section
	*
	**/
	.form-wrapper {
		@extend .clearfix;
		padding: 20px 0px;
		border-top: solid 1px $neutral_5;
	}

	/**
	*
	* Service tile overrides
	*
	**/
	.service-tiles {

		margin-top: 0;
		margin-bottom: 0px;

		.row:last-child {
			margin-bottom: 0px;
		}

		.description {
			@extend %font-secondary;
			@include fontSizeFluid(16px);
			display: block;
			margin: 0;
			margin-bottom: 10px;
			padding: 0;
			line-height: 1.3;
			color: $neutral_2;
		}

		a {
			@extend %font-secondary;
		}

		> .text-block {
			margin-left: -20px;
			margin-right: -20px;
		}
	}

	.opening-hours {
		background-color: $neutral_6;
		margin-left: 20px;
		margin-right: 20px;
		margin-bottom: 20px;
		padding: 20px 25px;

		p {
			@include fontSizeFluid(16px);
		}
	}


	//Desktop
	@media #{$sm} {

		h1 {
			@include fontSizeFluid(32px);
			margin-bottom: 28px;
		}

		h2 {
			@include fontSizeFluid(24px);
		}

		h3 {
			@include fontSizeFluid(20px);
		}

		p {
			@include fontSizeFluid(16px);
		}

		.tab-btns-row {
			li {
				text-align: left;

				//Active state
				&.active {
					a {
						border-top: solid 6px $primary_2;
					}
				}
			}

			a {
				@include fontSizeFluid(24px);
				height: 90px;
				padding: 0 20px;
				border-top: solid 6px $neutral_1;
			}
		}

		.tab-pane {
			padding-top: 60px;
		}

		.tab-content {
			section {
				> .text-block {
					margin-bottom: 65px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}


		.form-wrapper {
			padding: 40px 100px;
		}

		.opening-hours {
			padding: 50px 45px;
		}


		/**
		*
		* Service tile overrides
		*
		**/
		.service-tiles {
			.row {
				margin-bottom: 80px;
			}

			> .text-block {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}