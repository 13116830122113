/**
*
* Basic tab structure - NO styling, just structure and visibility
*
**/

//Nav
.nav-tabs {
	list-style-type: none;
	margin: 0;
	padding: 0;

	li {
		list-style-type: none;
		margin: 0;
		padding: 0;
		float: left;
	}
}
.tab-content {
	> .tab-pane {
		display: none;
	}

	> .active {
		display: block;
	}
}