/**
*
* Page Utility components
* Contains text resizing, social sharing, print, email etc
*
**/
.page-utility {
	float: right;
	border: solid 1px $neutral_5;

	.btn-wrap {
		display: flex;
	}

	a {
		height: 40px;
		width: 40px;
		border-right: solid 1px $neutral_5;
		color: #7f7f7f;
		text-decoration: none;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;

		svg.icon {
			height: 16px;
			width: 16px;
		}

		&.email {
			.stButton { display: none !important;} //Hiding ShareThis injected markup
		}

		&.print {
			display: none;
		}

		&:last-child {
			border-right: none;
		}


		&:hover {
			color: $primary_2;
		}
	}

	//Force styles for sharing trigger
	//This class is 3rd party lib defined, and MUST be on sharing
	//element for event binding.
	//Important required to override JS applied inline styles
	.st_sharethis_large {
		.stButton {
			display: none !important;
		}
	}

	//Hide default buttons - remote triggering in PageUtility.js
	.hidden-buttons {
		display: none;
		visibility: hidden;
	}

	//Desktop
	@media #{$sm} {
		a.print {
			display: flex;
		}
	}
}
