.module-dynamic-iframe{
    .iframe-container{
        > iframe{
            width: 100%;
            min-height: 820px;
        }
    }

    .loader-field{
        font-size: 12px;
    }
}