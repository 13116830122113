﻿.small-message {
    
    .small-message-title {
        margin-bottom: 10px;
        @include font-primary();
        @include fontSize(20px);
        @include lineHeight(24px);
        color: $primary_2;
    }
    
    .small-message-body {
        @include fontSize(14px);
        @include lineHeight(20px);
    }
}