.module-extras-product-table {
    h2 {
        margin-bottom: 23px;
        @include fontSize(26px);
        @include lineHeight(30px);
    }

    .content {
        padding-bottom: 18px;
        @include fontSize(16px);
        @include lineHeight(24px);
    }

    .legend-block {
        padding-bottom: 45px;
    }


    .panel-group {
        border-top-color: $primary_3;
    }

    .service-name {
        color: $primary_2;
        a {
            color: $primary_2;
            border-bottom: 1px dashed $primary_4;
        }
    }

    .extras-table {
        .row-span {
            position: relative;
        }

        .extras-table-row {
            border: 1px solid $neutral_5;
            border-top: 0;

            background: $neutral_white;

            &.even { // Cannot do css nth-child(odd) because of issues with the structure and column merging.
                background: #FBFBFB;
            }

            &.headings {
                display: none
            }

            .benefit-yearly-limits {
                display: flex;
                align-items: center;
                padding-bottom: 10px;
            }

            &.open {
                .extras-table.column {
                    .extras-label {
                        svg {
                            &.icon.icon-down {
                                display: none;
                            }
                        }
                    }
                }
            }

            .extras-table-column {

                .extras-label {
                    color: $neutral_3;
                    text-transform: uppercase;
                    @include fontSize(10px);
                    @include lineHeight(17px);

                    svg {
                        position: relative;
                        top: 2px;
                        margin-left: 1px;
                        height: 12px;
                        width: 12px;

                        &.icon.icon-up {
                            display: none;
                        }
                    }
                }

                &.service-name {
                    text-align: center;
                    display: block;
                    font-family: $fontPrimary;
                    @include fontSize(16px);
                    @include lineHeight(17px);
                    padding: 22px;

                    span {
                        display: block;
                        font-family: $fontSecondary;
                        @include fontSize(12px);
                        @include lineHeight(18px);
                        color: $neutral_3;
                    }
                }
                &.benefit,
                &.yearly-limits {
                    width: 50%;
                    text-align: center;
                }

                &.benefit {
                    border-right: 1px solid $neutral_5;
                }
                &.yearly-limits {
                    .yearly-limits-icon {
                        color: $primary_4;

                        svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

            // mobile only yearly limits copy.
            .yearly-limits-block {
                max-height: 0;
                overflow: hidden;
                @include fontSize(12px);
                @include lineHeight(16px);
                text-align: center;
                transition: all 0.7s;

                .inner {
                    border-top: 1px solid $neutral_5;
                    padding: 20px 0;
                    margin: 0 20px;
                }

                .yearly-limits-icon {
                    color: $primary_4;

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
                .yearly-limits-title {
                    margin-bottom: 10px;
                }

                .yearly-limits-content {
                    p:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &.active {

                .extras-table-column {

                    .extras-label {
                        svg {
                            &.icon.icon-up {
                                display: inline;
                            }

                            &.icon.icon-down {
                                display: none;
                            }
                        }
                    }
                }

                .yearly-limits-block {
                    max-height: 700px;
                }
            }

        }
    }

    @media #{$sm} {
        h2 {
            @include fontSize(36px);
            @include lineHeight(48px);
            margin-bottom: 23px;
        }

        .content {
            @include fontSize(16px);
            @include lineHeight(24px);
            padding-bottom: 18px;
        }

        .extras-table-wide {
            width: 100%;
            tr {
                th, td {
                    padding: 27px;

                    &.service-name {
                        width: 63%;
                    }

                    &.benefit {
                        width: 10%;
                    }

                    &.yearly-limits {
                        width: 27%;
                    }
                }
                th {
                    border-top: 0;
                    text-align: left;
                    color: $neutral_3;
                    padding: 10px 27px;
                }
                td {
                    vertical-align: top;
                    text-align: center;
                    &.service-name {
                        font-family: $fontPrimary;
                        @include fontSize(18px);
                        @include lineHeight(21px);
                        text-align: left;

                        span {
                            font-family: $fontSecondary;
                            @include fontSize(14px);
                            @include lineHeight(18px);
                        }
                    }

                    &.benefit {
                        @include fontSize(12px);
                        @include lineHeight(21px);
                    }

                    &.yearly-limits {
                        @include fontSize(12px);
                        @include lineHeight(21px);
                        vertical-align: middle;

                        .yearly-limits-icon {
                            color: $primary_4;

                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }

                        .yearly-limits-title {
                            font-weight: bold;
                            margin-bottom: 10px;
                        }

                        .yearly-limits-content {

                        }

                    }
                }
            }
        }
    }
}