/**
*
* Compare Cover Tile
* Image, with text and CTA
*
**/
.compare-cover-tile {

	position: relative;
	margin-top: 40px;

	.column {
		padding-left: 0px;
		padding-right: 0px;
	}

	img {
		width: 100%;
	}

	.content-block {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.content-wrap {
		display: table;
		width: 100%;
		height: 100%;
	}

	.inner {
		display: table-cell;
		width: 100%;
		height: 100%;
		vertical-align: middle;
		text-align: center;
	}

	h1 {
		@extend %font-primary;
		@include fontSize(16px);
		margin-top: 0;
		margin-bottom: 5px;
		color: white
	}

	p {
		@extend %font-secondary;
		@include fontSize(12px);
		color: white
	}

	a {
		display: inline-block;
		width: auto;
		margin: auto;
		padding-left: 30px;
		padding-right: 30px;
	}


	//Desktop
	@media #{$sm} {

		.column {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
		}

		h1 {
			@include fontSize(28px);
			color: white
		}

		p {
			@include fontSize(16px);
			color: white
		}
	}
}
