/**
*
* Extras Cover comparison table
* See also:  components/comparison-tables/comparison-table.scss
*
**/
.hospital-cover-table {

	table {
		display: table;
		width: 100%;
		border-right: 0;
	}

	td {
		padding: 10px;

		&.service {
			width: calc(100% - 46px);
			text-align: left;
			border-style: solid;
			border-color: $neutral_5;
			border-width: 0px 1px 1px 0px;
		}

		&.availability {
			width: 45px; //46 - 1px border
			text-align: center;
			vertical-align: middle;
			border-style: solid;
			border-color: $neutral_5;
			border-width: 0px 0px 1px 1px;
			@include fontSize(11px);
		}
	}

	p {
		@extend %font-secondary;
		@include fontSize(12px);
		margin: 0;
		padding: 0;
		color: $neutral_2;
	}

	svg.icon {
		fill: $neutral_3;
		width: 10px;
		height: 10px;

	}

	//Desktop
	@media #{$sm} {

		td {
			padding: 20px;

			&.service {
				width: calc(100% - 98px);
			}

			&.availability {
				width: 98px; //100 - 2px border
			}
		}

		p {
			@include fontSize(16px);
		}

		svg.icon {
			width: 15px;
			height: 15px;
		}
	}
}
