#master-footer {
    /**
	*
	* Mobile, default first
	*
	**/
    margin-top: 45px;
    /*Primary, content links*/
    .primary-links {
        @include clearfix-micro();
        padding-top: 10px;
        padding-bottom: 10px;
        
        .list-header {
            @extend %font-primary;
            @include fontSize(24px);
            position: relative;
            padding: 10px 0px 10px 10px;
            margin: 0px;
            margin-top: 3px;
            background-color: $neutral_4;
            color: $primary_2;
            //Icon
            svg.icon {
                position: absolute;
                right: 15px;
                top: 24px;
                width: 18px;
                height: 18px;
                padding-top: 10px;
            }

            .icon-opened {
                display: block;
            }

            .icon-closed {
                display: none;
            }

            &.active {
                .icon-opened {
                    display: none;
                }

                .icon-closed {
                    display: block;
                }
            }
        }

        ul {
            margin-top: -5px;
            display: none; //Accordion functionality
            margin: 0;
            padding: 0;
            list-style-type: none;
            background-color: $neutral_4;
        }

        li {
            padding: 5px 0px 5px 10px;
        }

        a,
        p {
            @extend %font-secondary;
            @include fontSize(14px);
            margin: 0;
            padding: 0;
            color: $neutral_2;
        }

        a:hover {
            color: $primary_2;
            text-decoration: underline;
        }
        //Active accordion list
        ul.active {
            display: block;
        }
    }
    /*Bottom left social icons*/
    .social-links {
        @include clearfix-micro();
        margin-top: 10px;

        .social-icon-link {
            display: block;
            position: relative;
            float: left;
            width: 40px;
            height: 40px;
            margin-right: 10px;
            border-radius: 17px;
            background-color: $neutral_5;
            text-decoration: none;
            text-align: center;

            .icon {
                @include fontSize(18px);
                display: flex;
                justify-content: center;
                align-items: center;
                color: $primary_2;
                position: relative;
                width: 40px;
                height: 40px;
            }

            //Specific icon overrides
            &.facebook {
                .icon {
                    @include fontSize(22px);
                }
            }
            &.youtube {
                .icon {
                    @include fontSize(42px);
                }
            }
            &.insta {
                .icon {
                    @include fontSize(42px);
                }
            }
        }

        .social-icon-link:last-child {
            margin-right: 0;
        }
    }

    /*Bottom right misc. logos*/
    .footer-awards {
        margin-top: 0px;
        margin-bottom: 20px;
        text-align: left;
        .award-item {
            display: inline-block;
        }
    }
    
    .branding {
        @include clearfix-micro();
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: left;

        .logo {
            display: inline-block;
            background-repeat: no-repeat;
            background-size: contain;
            margin-left: 0;
            position: relative;
        }

        .site-brand {
            background-image: url('#{$img-url}/logos/logo-secondary.svg?ver=#{$img-ver}');
            width: siteSetting($footer, logoWidth_mobile, 91px);
            height: siteSetting($footer, logoHeight_mobile, 59px);
            max-width: siteSetting($footer, logoMaxWidth_mobile, 148px);
            max-height: siteSetting($footer, logoMaxHeight_mobile, 59px);
            margin-right: 20px;
        }
        .Nurses{
            width: 91px;
        }
        .UniHealth {
            width: 134px;
        }
        .members-own {
            background-image: url('/static/img/common/logos/MHF-logo.svg?ver=#{$img-ver}');
            width: siteSetting($footer, logoMembersOwnWidth_mobile, 52px);
            height: siteSetting($footer, logoMembersOwnHeight_mobile, 59px);
            top: 0;
        }

        .reviews {
            display: block;
            margin-top: 20px;
            font-weight: bold;

            @include fontSize(12px);
            @include lineHeight(12px);

            .intro {
                margin-bottom: 2px;
            }

            .stars {
                color: $primary_4;

                svg.icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;

                    :last-child {
                        margin-right: 0;
                    }
                }
            }

            .disclaimer {
                @include fontSize(10px);
                @include lineHeight(10px);
                margin-top: 2px;
            }
        }

        a {
            display: block;
            width: 100%;
            height: 100%;

            span {
                display: none;
            }
        }
    }
    .country-acknowledgement {
        display: block;
        background-image: url('/static/img/common/backgrounds/footer-pattern.svg');
        background-color: $primary_2;
        background-size: cover;
        background-position: center center; 
        padding: 20px 30px;
        color: $neutral_white;
        @include fontSize(14px);
        font-weight: 700;
        line-height: 22px;
        p:last-child {
            margin-bottom: 0;
        }

    }
    
    .contact-us-link-wrapper{
        margin-top: 40px;
        margin-bottom: 25px;
        text-align: left;
        color: $primary_2;
        width: 90%;

        .contact-us-button {
            display: flex;
            justify-content: center;
            .left-copy {
                font-weight: 600;
                margin-top: 3px;
            }
            .contact-us-link {
                @extend %font-primary;
                @include fontSize(24px);
                @include lineHeight(24px);
                color: $primary_2;
                font-weight: none;
                margin-left: 20px;
                border: solid 2px $primary_2;
                border-radius: 5px;
                padding: 10px 20px 10px 20px;
                white-space: nowrap;
                text-align: center;
                width: 100%;
            }
        }
        
        .reviews-container {
            .reviews {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-weight: 600;
            }
        }

    }
    .store-link {
        margin-top: 20px;
        .app-store {
            .app-store-icon {
                height: 34px;
            }
        }
        .play-store {
            .play-store-icon {
                height: 34px;
            }
        }
    }
    /*Fine print secondary links*/
    .secondary-links {
        margin-top: 30px;
        margin-bottom: 25px;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            justify-content: center;
            display: inline-flex;
            flex-wrap: wrap;
            margin: auto;
            justify-content: center;
        }

        li {
            margin-bottom: 5px;
            width: auto;
        }

        a {
            @extend %font-secondary;
            @include fontSize(14px);
            display: block;
            height: 15px;
            line-height: 15px;
            color: $neutral_2;
            padding: 0px 10px;
            border-right: solid 1px $neutral_black;

            &:hover {
                color: $primary_2;
                text-decoration: underline;
            }
        }
        
        li:first-child {
            a {
                padding-left: 0;
            }
        }

        li:last-child {
            a {
                padding-right: 0;
                border-right: 0;
                border-radius: 5px;
            }
        }
    }

    /*Fine print copy, bottom row*/
    .fine-print {
        @extend %font-secondary;
        @include fontSize(12px);
        color: $neutral_3;
        margin-bottom: 65px;
        line-height: 22px;
        text-align: center;
    }

    .reviews-disclaimer {
        @extend %font-secondary;
        @include fontSize(12px);
        color: $neutral_2;
    }

    .bottom-section {
        justify-content: center;
        .bottom-section-col {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .footer-divider {
        position: relative;
        width: 100%;
        height: 1px;
        background-color: $neutral_3;
    }


    /**
	*
	* Large screens
	*
	**/
    @media #{$sm} {
        padding-left: 0px;
        margin-top: 65px;

        .footer-ellipse {
            position: relative;
            background-color: white;

            &:before {
                background-position: center 1px;
                background-repeat: no-repeat;
                position: absolute;
                content: ' ';
                display: block;
                height: 120px;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
        /*Primary, content links*/
        .primary-links {
            background-color: none;
            padding-top: 27px;

            &:after{
                border-bottom: 5px $neutral_3;
            }

            .list-header {
                background-color: white;
                padding: 0px 0px 15px 0px;
                margin-bottom: 0px;
                .icon {
                    display: none;
                }
                //Need greater specificity to counter mobile styles
                &.active {
                    .icon {
                        display: none;
                    }
                }
            }

            ul {
                display: block; //Accordion functionality disabled for large screens
                background-color: white;
            }

            li {
                padding: 0px 0px 5px 0px;
            }
        }
        /*Bottom left social icons*/
        .social-links {
            margin-top: 20px;
            margin-right: 12px;
            padding-left: auto;
            padding-right: auto;
            float: right;
            .social-icon-link {
                width: 40px;
                height: 40px;
                margin-right: 10px;
                border-radius: 25px;
            }
        }
        .social-icon-link:last-child {
            margin-right: 0;
        }
        .footer-awards {
            margin-top: 20px;
            text-align: right;
        }
        
        /*Bottom right misc. logos*/
        .branding {
            margin-top: 20px;
            text-align: left;

            .site-brand {
                max-width: siteSetting($footer, logoMaxWidth_desktop, 148px);
                max-height: siteSetting($footer, logoMaxHeight_desktop, 59px);
                margin-right: 20px;
            }

            .members-own {
                width: siteSetting($footer, logoMembersOwnWidth_desktop, 52px);
                height: siteSetting($footer, logoMembersOwnHeight_desktop, 59px);
                top: 0;
            }

        }

        .country-acknowledgement {
            display: block;
            background-image: url('/static/img/common/backgrounds/footer-pattern.svg');
            background-color: $primary_2;
            background-size: cover;
            background-position: center center; 
            padding: 20px 30px;
            color: $neutral_white;

            p:last-child {
                margin-bottom: 0;
            }
        }

        .contact-us-link-wrapper{
            text-align: left;
            @include fontSize(14px);
            .contact-us-button{
                display: flex;
                // align-items: center;
                justify-content: flex-start;
            }
            .contact-us-link {
                @include fontSize(24px);
                line-height: 27px;
                height: 47px;
                width: 120px;
                border: solid 2px $primary_2;
                border-radius: 5px;
                padding: 10px 20px 10px 20px;
            }
            .reviews-container {
                .reviews{
                    align-items: flex-start;
                }
            }
        }
    

        /*Fine print secondary links*/
        .secondary-links {
            li {
                display: inline;
                float: left;
            }

            li:first-child {
                a {
                    padding-left: 0;
                }
            }

            li:last-child {
                a {
                    padding-right: 0;
                    border-right: 0;
                    border-radius: 5px;
                }
            }
        }

        .store-link {
            float: right;
            margin-right: 12px;
        }

        .bottom-section {
            justify-content: center;

            .bottom-section-col {
                display: block;

            }
        }

        .fine-print {
            text-align: left;
        }
    }
}
