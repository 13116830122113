/**
*
* Service Tiles
*
**/
.service-tiles {

	margin-top: 40px;

	//Module tilte - ONLY applicable to 'service tile small'
	.module-title {
		@extend %font-primary;
		@include fontSizeFluid(24px);
		margin: 0 auto 50px auto;
		padding: 0;
		text-align: center;
		line-height: 1;
		color: $neutral_2;

	}

	.service-tile {
		margin-bottom: 60px;
	}

	.service-icon {
		margin-bottom: 22px;
	}

	.content-block {
		text-align: center;
	}

	.title {
		@extend %font-primary;
		@include fontSizeFluid(siteSetting($serviceTile, title_fontSize, 26px));
		margin: 0;
		padding: 0;
		line-height: 1;
		margin-bottom: 10px;
		color: $primary_2;
	}

	a {
		@extend %font-primary;
		@include fontSizeFluid(siteSetting($serviceTile, cta_fontSize, 16px));
		position:relative;

		span {
			display: inline-block;
		}

		svg.icon {
			display: inline-block;
			margin-left: 10px;
			height: 0.7em;
			width: 0.7em;
		}
	}

	.description {
		display: none;
		@extend %font-secondary;
	}

	.text-block,
	.description {
		a {
			@include fontSizeFluid(16px);
		}
	} 


	/**
	*
	* Specific layout variants
	*
	**/

	&[data-layout="grid"]{
		.service-tile {
			margin-bottom: 60px;
		}
	}

	//Alternate layout where icons float to left for mobile screens
	//Primarily in use on contact us page
	&[data-layout="alternate"]{
		padding-left: 20px;
		padding-right: 20px;

		.service-icon {
			transform: scale(0.7);
			float: left;
		}

		.content-block {
			max-width: calc(100% - 70px); //70px is actual icon width - see ui-elements/_service-icon.scss
			float: left;
			padding-top: 20px;
			text-align: left;
		}

		.title {
			@include fontSizeFluid(18px);
		}
	}



	//Desktop
	@media #{$sm} {

		margin-top: 60px;
		margin-bottom: 84px;

		.service-tile {
			// margin-bottom: 0px;
		}

		.service-icon {
			margin-bottom: 37px;
		}

		.title {
			@include fontSizeFluid(siteSetting($serviceTile, title_fontSize, 30px));
			margin-bottom: 20px;
		}

		.description {
			@include fontSizeFluid(16px);
			display: block;
			margin: 0;
			margin-bottom: 10px;
			padding: 0;
			line-height: 1.3;
			color: $neutral_2;
		}

		&[data-layout="alternate"]{
			.service-icon {
				transform: none;
				float: none;
			}

			.content-block {
				max-width: 100%;
				float: none;
				padding-top: 0px;
				text-align: center;
			}

			.title {
				@include fontSizeFluid(siteSetting($serviceTile, title_fontSize, 30px));
			}
		}
	}
}

@media #{$sm} {
	main .service-tiles:last-child {
		margin-bottom: 15px;
	}
}
