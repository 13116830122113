/**
*
* Full width promo module - reforms phase 2, 2019
*
**/
.module-full-promo {
    height: auto;
    color: white;
    padding: 30px 0;
    margin: 0;

    &.bg-primary1 {
        background-color: $primary_1;
    }

    &.bg-primary2 {
        background-color: $primary_2;
    }

    &.bg-primary3 {
        background-color: $primary_3;
    }

    &.bg-primary4 {
        background-color: $primary_4;
    }

    &.bg-primary5 {
        background-color: $primary_5;
    }

    &.bg-primary6 {
        background-color: $primary_6;
    }

    .img-promo {
        text-align: center;
        padding: 20px;
    }

    img {
        margin-bottom: 10px;
        width: auto;
        max-width: 100%;
        max-height: 150px;
    }

    .promo-text {
        padding: 0 18px;
    }

    .disclaimer {
        @include fontSize(13px);
        @include lineHeight(20px);
        display: block;
    }


    .promo-title {
        @include fontSize(36px);
        @include lineHeight(48px);
        margin-top: 0;
        margin-bottom: 15px;
        color: inherit;

        &.primary1 {
            color: $primary_1;
        }

        &.primary2 {
            color: $primary_1;
        }

        &.primary3 {
            color: $primary_1;
        }

        &.white {
            color: $neutral_white;
        }
    }
    //Desktop
    @media #{$sm} {
        /*
        Force into a flex row, for vertical centering
         */
        .row {
            display: flex;
        }

        .content-col {
            display: flex;
            align-items: center;
            padding: 0 42px;

            img {
                margin-bottom: 10px;
            }
            //Force image to align right
            &:first-child {
                justify-content: flex-end;
            }
        }

        .promo-text {
            padding: 0;
        }

        .img-promo {
            max-width: 300px;
            padding-left: 0;
            padding-right: 0;
        }
        /*
        Alternate layout overrides, when image is on right
         */
        .col-sm-push-6.content-col {
            //Force image to align left
            &:first-child {
                justify-content: flex-start;
            }
        }

        .col-sm-pull-6.content-col {
            text-align: right;
        }
    }
}
