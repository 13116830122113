.panel-group {
    border-top: 5px solid $primary_4;

    .panel {
        .panel-heading {
            font-family: $fontPrimary;
            background: $neutral_6;
            margin-top: 2px;

            h4.panel-title {
                @include fontSize(20px);
                @include lineHeight(22px);
                margin: 0;
                color: $primary_2;
            }

            a {
                display: block;
                color: $primary_2;
                position: relative;
                padding: 18px 12px;
                padding-right: 60px;

                svg {
                    position: absolute;
                    top: 50%;
                    right: 19px;
                    transform: translateY(-50%);

                    &.icon-up {
                        display: block;
                    }
                    &.icon-down {
                        display: none;
                    }
                }

                &.collapsed {
                    svg {
                        &.icon-up {
                            display: none;
                        }
                        &.icon-down {
                            display: block;
                        }
                    }
                }
            }
        }
        .panel-collapse {

        }
    }

    .panel-group {
        border-top: 0;
        .panel-heading {
            font-family: $fontPrimary;
            background: $neutral_white;
            margin-top: 2px;
            border: 1px solid $neutral_5;

            h4.panel-title {
                margin: 0;
                @include fontSize(20px);
                @include lineHeight(22px);
                color: $primary_4;

                a {
                    color: $primary_4;
                }
            }
        }
    }

    @media #{$sm} {
        .panel {
            .panel-heading {
                h4.panel-title {
                    @include fontSize(20px);
                    @include lineHeight(27px);
                }
                a {
                    padding: 20px;
                    padding-right: 60px;
                }
            }
        }
    }
}
