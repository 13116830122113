.legend-block {
    color: $neutral_3;

    .legend-title {
        padding-bottom: 6px;
        border-bottom: 1px solid $neutral_5;
        margin-bottom: 16px;
    }

    .legend-item {
        display: flex;
        align-items: center;
        font-size: 13px;
        @include fontSize(13px);
        @include lineHeight(16px);
        margin-bottom: 9px;

        &:last-child {
            margin-bottom: 0;
        }

        svg {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }

        &.scope-of-cover,
        &.scope-of-cover:last-child {
            margin-top: 4px;
            margin-bottom: 1px;

            p {
                @include fontSize(16px);
                @include lineHeight(16px);
                position: relative;
                top: -1px;
                margin-bottom: 0;
                padding-bottom: 3px;
                margin-right: 10px;
                text-align: center;
                font-family: $fontPrimary;
                color: $primary_2;
                border-bottom: 1px dashed $primary_4;
            }

            div {
                position: relative;
                top: -2px;
            }
        }
    }

    @media #{$sm} {
        .legend-title {
            margin-bottom: 20px;
        }

        .legend-items {
            display: flex;
            flex-flow: wrap;
        }

        .legend-item {
            margin: 5px;
            margin-left: 0;
            margin-right: 26px;

            &:last-child {
                margin: 5px;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
