/**
*
* Health Hub Article intro
*
**/
.article-intro {

    border-top: solid 1px #C4C4C4;
    border-bottom: solid 1px #C4C4C4;
    padding: 15px 0 30px 0;

    h1 {
        @include fontSize(36px);
        @include lineHeight(46px);
        margin-top: 0;
        margin-bottom: 8px;
    }

    h3 {
        @extend %font-secondary;
        @include fontSize(20px);
        @include lineHeight(27px);
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 700;
    }

    //Desktop
    @media #{$sm} {
        max-width: $max-content-width;
        margin: auto;
        padding: 35px 0 45px 0;

        h1 {
            @include fontSize(55px);
            @include lineHeight(74px);
            margin-top: 0;
            margin-bottom: 20px;
        }

        h3 {
            @include fontSize(22px);
            @include lineHeight(30px);
        }
    }
}
