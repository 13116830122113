.module-fullwidth-cta-image {

    margin: 0;
    width: 100%;
    height: 300px;
    position: relative;
    overflow: hidden;

    /*
    Background image
     */
    .background {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        picture {
            display: block;
        }

        img {
            width: 100%;
            object-fit: cover;
            object-position: center center;
        }

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(180deg, rgba($neutral-white, 0) 0%, rgba($neutral-white, 0.8) 100%);
            opacity: 0.9;
        }
    }

    /*
    Content and structure
     */
    .caption,
    .container-fluid,
    .row,
    [class*='col-']{
        height: 100%;
    }

    .copy {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 5px;
        color: $primary_2;

        &.align-center {
            justify-content: center;
        }

        &.align-bottom {
            justify-content: flex-end;
        }
    }

    .title {
        @include fontSize(35px);
        @include lineHeight(40px);
        color: inherit;
        text-align: center;
        margin: 0;
    }

    .sg-btn {
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
    }

    /*
    Branded version
     */
    &.branded {
        .copy {
            color: $neutral-white;
        }
        .background:after {
            background: linear-gradient(180deg, rgba($primary_2, 0) 0%, rgba($primary_2, 0.8) 100%);
        }
    }


    //Tablet
    @media #{$sm} {
        height: 429px;

        .copy {
            flex-direction: column;
            align-items: center;
            padding: 30px 5px;
            color: $primary_2;

            &.align-center,
            &.align-bottom {
                justify-content: center;
            }
        }

        .title {
            text-align: left;
            margin: 0;
            margin-right: auto;
        }

        .sg-btn {
            margin-top: 20px;
            margin-left: 0;
            margin-right: auto;
        }
    }

    //Desktop
    @media #{$md} {
        .copy {
            padding-right: 25px;
        }

        .title {
            @include fontSize(52px);
            @include lineHeight(52px);
        }

        //Background overlay
        .background:after {
            width: 50%;
            background-color: white;
            opacity: 0.8;
        }


        /*
        Branded version
         */
        &.branded {
            .copy {
                color: $neutral-white;
            }
            .background:after {
                background: $primary_2;
            }

            .sg-btn {
                background-color: white;
                color: $primary_2;
                border: 2px solid white;

                &:visited {
                    background-color: white;
                    color: $primary_2;
                    border-color: white;
                }

                &:hover,
                &:focus,
                &:active {
                    background-color: $neutral_4;
                    color: darken($primary_2, 10);
                    border-color: $neutral_4;
                }

                &:active {
                    background-color: white;
                    color: darken($primary_2, 20);
                    border-color: white;
                }

                &:disabled {
                    background-color: white;
                    color: $neutral_4;
                    border-color: white;
                }
            }
        }
    }
}