/**
*
* Health Hub - Article tile, featured
*
**/
.article-tile-featured {

    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 1.5px 3px 1.5px rgba(28, 37, 44, 0.2);
    border-radius: 20px;
    overflow: hidden;
    color: $primary_2;
    margin-bottom: 40px;

    a {
        color: $primary_2;
        display: flex;
        flex-direction: column;
        height: 100%;

        &.tag-link{
            height:auto;
            z-index: 2;
        }

        &.stretched-link{
            display:block;
            height: auto;

            &::after{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                content: "";
            }
        }
    }


    .img-block {
        width: 100%;
        position: relative;

        img {
            width: 100%;
            border-radius: 0 0 30px 0;
            overflow: hidden;
        }
    }

    .label {
        @include fontSize(12px);
        @include lineHeight(17px);
        display: block;
        position: absolute;
        right: 16px;
        bottom: 16px;
        padding: 7px 11px;
        border-radius: 16px;
        background-color: $primary_2;
        color: white;
        font-weight: 700;
        text-transform: uppercase;
    }

    .content-block {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 13px 16px 22px 16px;
    }

    .title {
        @include fontSize(16px);
        @include lineHeight(22px);
        margin-bottom: 5px;
        font-weight: 600;
    }

    .desc {
        @include fontSize(14px);
        @include lineHeight(19px);
        margin-bottom: 22px;
    }

    .link-row {
        display: flex;
        margin-top: auto;
        align-items: center;
    }

    .author {
        display: flex;
        align-self: flex-start;
        align-items: center;
    }

    .author-img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 8px;
    }

    .author-name {
        @include fontSize(11px);
        @include lineHeight(15px);
        color: #505050;
        word-break: break-word;
        width: 120px;
        margin: 0;
    }

    .link {
        @include fontSize(14px);
        @include lineHeight(19px);
        text-decoration: none;
        text-align: right;
        margin-left: auto;
        color: $link-color;

        &:hover {
            text-decoration: underline;
        }
    }

    //Desktop
    @media #{$md} {
        height: 100%;

        .content-block {
            padding: 15px 20px 20px 20px;
        }

        .title {
            @include fontSize(18px);
            @include lineHeight(25px);
            margin-bottom: 5px;
            font-weight: 600;
        }

        .desc {
            margin-bottom: 22px;
        }
    }
}