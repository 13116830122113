.module-quick-links {
    margin: 0;
    background-color: $neutral_6;
    padding: 40px 0;

    .tile-col {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    a {
        color: $neutral_2;
    }

    .tile {
        position: relative;
        width: 50%;
        padding: 20px;
        .icon-wrap {
            display: block;
            text-align: center;
            svg.icon {
                fill: currentColor;
                width: 24px;
                height: 24px;
            }
        }
        .name {
            display: block;
            font-family: $fontPrimary_light;
            @include fontSize(18px);
            @include lineHeight(20px);
            text-align: center;
            padding-top: 20px;
        }
    }

    @media #{$sm} {

        .tile {
            width: 25%;
            flex: 1;
            .icon-wrap {
                padding-top: 0;
                position: absolute;
                top: 23px;
                left: 20px;
            }

            .name {
                text-align: left;
                padding-top: 0;
                padding-left: 36px;
                padding-right: 8px;
            }
        }
    }
}