.modal {
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255,255,255,0.5);
        z-index: 100000000;
    }

    .modal-content {
        z-index: 100000001;
    }

    &.centred {
        .modal-content {
            position: fixed;
            top: 50% !important;
            left: 50% !important;
            width: 90%;
            max-height: 80%;
            transform: translate(-50%, -50%);
        }
    }

    &.right {
        .modal-content {
            margin-left: 15px;
            &:after {
                content: '';
                position: absolute;
                left: -10px;
                top: 50%;
                transform: translateY(-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 10px 10px 0;
                border-color: transparent $primary_4 transparent transparent;
            }
        }
    }

    .modal-content {
        padding: 12px;
        border-radius: 4px;
        background: $primary_4;
        box-shadow: 0 5px 50px -20px rgba(0,0,0,0.4);


        .modal-title {
            position: relative;
            padding-bottom: 20px;
            padding-right: 35px;
            .modal-heading {
                color: $neutral_white;
                font-family: $fontPrimary;
                @include fontSize(20px);
                @include lineHeight(20px);
            }

            .modal-btn-close {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;

                svg {
                    width: 24px;
                    height: 24px;
                    color: $neutral_white;
                }
            }
        }
        .modal-body {
            background: $neutral_white;
            padding: 20px;
            max-height: 400px;
            overflow: auto;
            @include fontSize(13px);
            @include lineHeight(18px);

            &::-webkit-scrollbar {
                -webkit-appearance: none;
            }

            &::-webkit-scrollbar:vertical {
                width: 12px;
            }

            &::-webkit-scrollbar:horizontal {
                height: 12px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $neutral_3;
                border-radius: 10px;
                border: 2px solid #ffffff;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: #ffffff;
            }
        }
    }
}

@media #{$sm} {
    .modal {
        background: transparent;
        .modal-content {
            max-width: 633px;
        }
    }
}