.module-three-column {

    .centre-text{
        text-align: center;
    }

    .description {
        margin-bottom: 26px;
    }
    
    @media #{$sm} {
        
    }
}
