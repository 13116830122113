/**
*
* Homepage a-feature slider
*
**/
.a-feature {

	//Common, Base, structural
	background-color: $neutral_4;
	position: relative;

	//Hidden by default until slider inits
	.slider-wrapper {
		visibility: hidden;
	}

	.slide {
		position: relative;
		max-height: 500px;
	}

	.content-link,
	.container-fluid,
	.row,
	.row > div {
		height: 100%;
	}

	img {
		width: 100%;
	}

	//Inner content block
	.content {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.text-block {
        margin-top: 86px;
        .text {
            @include fontSize(12px);
            @include lineHeight(16px);
            color: $primary_2;
        }
    }

	//Controls UI
	.flex-control-nav {
		position: absolute;
		width: 100%;
		left: 0;
		right: 0;
		bottom: 20px;
		margin-left:auto;
		margin-right:auto;
		padding-left: $grid-gutter-width / 2;
		max-width: $max-content-width;
		z-index: 99;
		text-align: center;
	}


	/**
	*
	* Loading state
	*
	**/
	&.loading {
		min-height: 400px;
	}


	/**
	*
	* Default, mobile styles
	*
	**/
	.bg-fill {
	    display: block;
	    left: 0;
	    top: 0;
	    bottom: 0;
	    right: 0;
	    position: absolute;
		background: rgba(0,0,0,0);
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.7)));
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
		background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
		background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
	}

	p {
		@extend %font-primary;
		@include fontSize(34px);
		color: $primary_2;
		color: white;
		position: absolute;
		bottom: 15%;
		left: 20px;
		line-height: 1;
	}


	/**
	*
	* Breakpoint specific styles
	*
	**/

	//768px +
	@media #{$sm} {
		.bg-fill {
			display: block;
			background-color: white;
			background-image: none;
			background-repeat: no-repeat;
			background-size: 100% 100%;
			width: 45%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0.7;
		}

		//Controls UI
		.flex-control-nav {
			bottom: 140px;
			text-align: left;
		}

		p {
			@include fontSize(siteSetting($aFeature, fontSize, 52px));
			position: relative;
			top: 25%;
			bottom: initial;
			left: initial;
			color: $primary_2;
		}
	}
}
