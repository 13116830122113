/**
*
* Join form - dependants block overrides
*
**/

//Top level parent
.dependants {
	.dependant-section {
		padding: 20px 40px;

		.header-row {
			@include clearfix-micro();
		}
		h1 {
			float: left;
		}

		.btn-remove-txt {
			margin-top: 6px;
			float: right;

			svg.icon {
				padding-right: 0;
				height: 9px;
				width: 9px;
			}

			&:hover {
				svg.icon {
					fill: $primary_2;
				}
			}
		}
	}

	.controls-row {
		width: 100%;
		padding: 20px;
		padding-top: 16px;
		margin-top: 30px;
		margin-bottom: 30px;
		background-color: $neutral_4;
		text-align: center;
	}
}
