/**
*
* Quote Panel - sticky
*
**/

$QPheight_desktop: 70px;
$QPheight_mobile: 55px;

#quote-panel {
    /**
	*
	* State based styles; fixed, or not
	*
	**/
    display: none; //Hidden until data is rendered from Cookie
    //Default positioning
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px;

    .btn-close {
        text-align: center;
        width: 20px;
        height: 20px;
        color: $neutral_white;
        background: darken($primary_4, 10);

        position: absolute;
        bottom: -20px;
        right: 0;

        svg.icon {
            width: 12px;
            height: 12px;
            position: relative;
            top: -1px;
            left: 0;
        }
    }

    /**
	*
	* Fixed positioning
	*
	**/
    &.affix {
        position: fixed;
        z-index: 200;
        padding: 0;
    }
    .inner-wrap {
        width: 100%;
        height: 100%;
        position: relative;
    }
    /**
	*
	* Structure + sections
	*
	**/

    .column {

    }

    .section-wrapper {
        height: 100%;
        position: relative;
        background-color: $neutral_white;
        box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.35);
    }

    .section-columns {
        display: flex;
        justify-content: space-between;

        .section-col {
            text-align: center;
            border-right: 1px solid $neutral_5;
            padding: 4px;
            flex-grow: 1;

            display: flex;
            align-items: center;
            justify-content: center;

            &:last-child {
                border-right: none;
            }
        }

        .your-cover {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .tier-pill {
                @include fontSize(8px);
                @include lineHeight(10px);
                position: relative;
                top: -2px;

            }

            .value-name {
                display: block;
            }

            .ampersand {
                display: block;
                margin-left: 5px;
                margin-right: 5px;

                svg.icon {
                    width: 12px;
                    height: 12px;
                    color: $primary_4;
                }

            }
        }

        .payment {
            display: none;
        }

        .amount {
            border-right: 0;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .join-cta {
            padding: 0;
            width: 66px;
            flex-grow: 0;

            a {
                white-space: nowrap;
                border-radius: 0;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                .inner {

                }
            }
        }

        .close-button {
            width: 0;
            padding: 0;

        }

        .btn-accordion-trigger {
            display: block;
            height: 100%;
            padding: 0 5px;

            svg.icon {
                display: block;
                color: $neutral_2;
                width: 15px;
                height: 15px;
            }

            svg.icon-closed {
                display: none;
            }

            &.active {
                svg.icon-closed {
                    display: block;
                }

                svg.icon-opened {
                    display: none;
                }
            }
        }
    }



    .message-block {
        display: none; //Hidden by default
        clear: both;
        width: 100%;

        .rebates-disclaimer {
            padding: 20px;
            border-top: solid 1px $neutral_5;

            @extend %font-secondary;
            @include fontSize(12px);
            color: $neutral_2;
        }
    }
    /**
	*
	* Common text + btn styles
	*
	**/
    .mobile-only {
        display: block;
        visibility: visible;
    }

    .desktop-only {
        display: none;
        visibility: hidden;
    }

    .label {
        @extend %font-secondary-bold;
        @include fontSize(10px);
        margin-bottom: 2px;
        color: $neutral_3;
        text-transform: uppercase;
    }

    .value {
        @extend %font-primary;
        @include fontSize(14px);
        @include lineHeight(20px);
        display: inline-block;
        margin-bottom: 0;
        color: $primary_2;

        &.price {
            @include fontSize(19px);
            padding-top: 2px;
            white-space: nowrap;

            sup {
                @include fontSize(14px);
            }
        }
    }

    .btn-edit {
        display: none;
        @extend %font-secondary-bold;
    }

    .btn-change {
        @extend %font-primary;
        @include fontSize(18px);
        display: block;
        width: 100%;
        padding: 15px;
        background-color: white;
        color: $neutral_2;
        border: solid 1px $neutral_5;
        text-align: center;
        text-decoration: none;

        &:hover {
            color: $primary_4;
        }
    }

    .tooltip {
        display: none;
    }

    .mobile-asterix {
        @include fontSize(18px);
        color: $neutral_2;
    }
    /**
	*
	* Join form version
	*
	**/
    &.join-form-quote-panel {
        .your-cover {
            @include make-xs-column(6);
            @include make-sm-column(5);
        }

        .payment {
            @include make-sm-column(3);

            .value {
                width: 80%;
            }
        }

        .amount {
            @include make-xs-column(6);
            @include make-sm-column(4);
        }
        //Dropddown overrides
        .bootstrap-select,
        .bootstrap-select.btn-group {
            > select {
                z-index: -1;
            }

            &.open {
                .dropdown-toggle {
                    background: transparent;
                }
            }

            .dropdown-menu {
                top: 20px;
                background: $lqt-colour-dropdown-options-bg;
                border: none;
                padding: 0;
                box-shadow: 0 0 20px 0 rgba(32, 32, 32, 0.2);

                li {
                    padding: 0;
                    border-bottom: 1px solid $lqt-colour-dropdown-options-selected-border;

                    &:last-child {
                        border: none;
                    }

                    &:hover {
                        background: $lqt-colour-dropdown-options-selected-bg;
                        color: white;
                    }
                }

                a {
                    display: block;
                    @include fontSize(12px);
                    font-weight: bold;
                    text-transform: uppercase;
                    color: inherit;
                    padding: 14px 20px 10px;
                }
            }

            .dropdown-toggle {
                padding: 2px 20px 0px;
                border: none;
                height: 20px;
                background: none;

                .filter-option {
                    @extend %font-primary;
                    @include fontSize(16px);
                    font-weight: bold;
                    text-align: center;
                    width: 100%;
                }

                .caret {
                    top: 4px;
                    right: 15px;
                    z-index: 6;

                    &:before {
                        color: $lqt-colour-quote-panel-labels;
                    }
                }
            }

            &.dropup {
                .dropdown-menu {
                    top: auto;
                    bottom: 20px;
                    border-bottom: none;
                    border-top-width: 1px;
                }
            }
        }
    }

    @media #{$sm} {

        &.affix {
            .inner-wrap {
                background-color: $neutral_white;
                box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.35);
            }

            .section-wrapper {
                background-color: transparent;
                box-shadow: none;
            }
        }


        .mobile-only {
            display: none;
            visibility: hidden;
        }

        .desktop-only {
            display: block;
            visibility: visible;
        }

        .column {
            @include make-xs-column(12);
        }

        .section-columns {
            float: initial;

            .section-col {
                padding: 6px 20px;
            }

            .your-cover {
                .value {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    .value-name, .ampersand {
                        display: inline-block;
                    }

                    .ampersand {
                        padding: 0 10px;
                        svg.icon {
                            width: 14px;
                            height: 14px;
                        }

                    }
                }

                .btn-edit {
                    display: block;
                    text-transform: uppercase;
                    @include fontSize(12px);
                    @include lineHeight(12px);
                    margin-top: 3px;
                    margin-left: 10px;

                    .icon {
                        position: relative;
                        top: 2px;

                    }
                }
            }

            .join-cta {
                width: auto;
                padding: 0;
                a {
                    padding-left: 40px;
                    padding-right: 40px;
                }
            }

            .payment {
                display: flex;
            }
        }

        .value {
            @include fontSize(18px);

            &.price {
                @include fontSize(25px);
                line-height: 1;
                padding-top: 0;

                sup {
                    @include fontSize(20px);
                }
            }
        }
    }
}
