.hh-article-banner {
	color: $neutral_white;

	h1 {
		color: $neutral_white;
		margin: 0 0 45px 0;
	}
}

.hhub-article-nav {
	border: 1px solid $neutral_1;
	border-radius: 5px;

	ul {
		padding: 15px 10px;
        margin-bottom: 0px;
	}

	ul li {
		position: relative;
		padding: 18px 15px;
		list-style: none;
	}

    ul li::before {
        content : "";
        position: absolute;
        left    : 0;
        bottom  : 0;
        margin: 0px 15px;
        width   : 90%;  /* or 100px */
        border-bottom: 1px solid $primary_2;
    }

	ul li a {
		color: inherit;
	}

	ul li:last-child::before {
        margin: 0px;
		border: none;
	}

    ul li:hover {
        background-color: #c9d8ea;// this color code is not being used anywhere else,
        border-radius: 5px;
        a {
            color: $primary_2;
            font-weight: 600;
        }
    }

    .active {
        background-color: #c9d8ea;// this color code is not being used anywhere else,
        border-radius: 5px;
        a {
            color: $primary_2;
            font-weight: 600;
        }
    }
}

.article-rating {
	padding-top: 1rem;
	padding-bottom: 2rem;
}

.hh-article-banner {
	color: $neutral_white;

	h1 {
		color: $neutral_white;
		margin: 0 0 45px 0;
	}
}

.hh-article-body {
	margin-bottom: 50px;
	
	.help-hub-article-content {
		margin-top: 45px;
		h2 {
			@extend %font-primary;
			@include fontSize(24px);
			margin-top: 0;
			margin-bottom: 25px;
			padding: 0;
			color: $neutral_2;
		}

		p {
			line-height: 2;
		}

		ul, ol {
			padding: 0 20px;

			li {
				padding: 6px 0;
				width: 90%;
				

				ul, ol {
					li {
						width: 100%;
					}
				}
			}
		}

		ul {
			> li {
				list-style: none;
				margin-left: 20px;

				&:before {
					content: "• ";
					color: $neutral_1;
					padding-right: 5px;
					margin-left: -16px;
				}


				ul {
					> li {
						width: 100%;
					}
				}
			}
		}
		
	}
	
	
}