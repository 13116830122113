/**
*
* Hero image
*
**/
.hero-image {

	position: relative;

	.image-block {
		width: 100%;

		img {
			width: 100%;
		}
	}

	.content-block {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		&.darken {
			background-color: rgba(0,0,0,0.15);
		}
	}

	.content-wrap {
		display: table;
		width: 100%;
		height: 100%;
	}

	.inner {
		display: table-cell;
		width: 100%;
		height: 100%;
		vertical-align: middle;
		text-align: left;
	}

	h1 {
		@extend %font-primary;
		@include fontSize(30px);
		line-height: 2rem;
		color: white;

		&.white, &.secondary {
			color: white;
		}
	}

	h2 {
		display: none;
		@extend %font-primary-light;
	}

	//Desktop
	@media #{$md} {
		.inner {
			text-align: center;
		}

		h1 {
			@include fontSize(68px);
			line-height: 4rem;
		}

		h2 {
			@include fontSize(24px);
			display: block;
			width: 70%;
			margin: auto;
			color: $primary_2;
			line-height: 1.2;

			&.white, &.secondary {
				color: white;
			}
		}
	}
}
