/**
*
* Join form styles when user resumes a previously saved application
*
**/

//Top level join form parent
.join-form-resume-container {

	h1 {
		@extend %font-primary;
    	@include fontSize(18px);
		color: $primary_2;
		margin-top: 38px;
    	margin-bottom: 3px;
	}

	section {
		margin-top: 10px;
	}

	.buttons {
	    @include clearfix();
	    button {
	    	width: 100%;
	    	float: none;
	    }
	}

	//Desktop
	@media #{$sm} {

		h1 {
			@include fontSize(32px);
		}

		.buttons {
		    button {
		    	width: auto;
		    	float: right;
		    }
		}
	}
}
