/**
*
* Health Hub - Category navigation
*
**/
.category-navigation {
    $moduleHeight: 60px;
    border-top: 2px solid white;
    width: 100%;
    position: relative;
    background-color: $primary_2;

    .col-xs-12 {
        padding-left: 0;
        padding-right: 0;
    }

   .child-items{
             .icon {
                width: 25px;
                height: 25px;
                margin-right: 10px;

             }
    }

    button,
    a,
    li {
        @include fontSize(16px);
        @include lineHeight(25px);
        text-transform: uppercase;
        font-weight: 700;
    }

    .HH-logo {
        width: 25px;
        height: 25px;
    }

    .icon.icon-home {
        margin: -3px 7px 0 0;
        height: 1.2em;
        width: 1.2em;
    }

    .btn-dropdown-trigger {
        @include btnReset();
        display: flex;
        height: 60px;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: white;
        border-style: solid;
        border-color: #828ea2;
        border-width: 0 0 1px 0;

        .txt {
            display: flex;
            margin-right: 15px;
            color: $primary_4;
        }

        .icon {
            @include animate-custom(#{'transform'}, 350ms);
        }

        .icon.icon-home {
            margin: -3px 7px 0 0;
            height: 1.2em;
            width: 1.2em;
            color:$primary_4;
        }

        &.active {
            .icon {
                transform: scaleY(-1);
            }

            .icon.icon-home {
                transform: none;
                margin: -3px 7px 0 0;
                height: 1.2em;
                width: 1.2em;
                color: $primary_4;
            }
        }
    }

    .child-items {
        overflow: hidden;
        display: none;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        color: $primary_4;

        .HH-logo {
            display: none;
        }
        //Hide item if its child URL matches current page
        &.current {
            display: none;
        }
    }

    a {
        @include animate-custom(#{'color'}, 350ms);
        display: flex;
        height: $moduleHeight;
        align-items: center;
        justify-content: center;
        color: white;
        border-style: solid;
        border-color: #828ea2;
        border-width: 1px 0;

        &:hover {
            color: $primary_4;
        }
    }
    //Desktop
    @media #{$md} {

        .col-xs-12 {
            padding-left: $grid-gutter-width / 2;
            padding-right: $grid-gutter-width / 2;
        }

        .btn-dropdown-trigger {
            display: none;
        }

        .child-items {
            width: 100%;
            height: $moduleHeight;
            display: flex !important;
            align-items: center;
            justify-content: center;
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        li {
            display: flex;
            padding: 0 25px;

            &.current {
                display: flex;

                a {
                    color: $primary_4;
                }
            }

            .HH-logo {
                margin-right: 10px;
                align-self: center;
                display: flex;
            }
        }

        a {
            border: none;
        }
    }
}
