/**
*
* Health Hub - Homepage feature
*
**/
.homepage-feature {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    min-height: 294px;
    height: 294px;
    
    &.tall {
        min-height: 500px;
        height: 500px;
    }

    picture,
    img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }

    .img-layer {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    //Desktop
    @media #{$md} {
        min-height: 294px;
        height: 294px;

        img {
            position: relative;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 100%;
            width: 100%;
            min-width: 1440px;
        }
    }
}

