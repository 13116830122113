.callback {
    .ui-datepicker-trigger {
        left: 0;
        right: 0;
        width: 100%;
    }

    .datepicker {
        .input {
            z-index: 10001;
        }
    }

    .bootstrap-select,
    .bootstrap-select.btn-group {
        .dropdown-menu {
            z-index: 100002;
        }
    }

    .callback-modal {
        .loading-panel {
            display: none;
            @include loading-panel();
            position: fixed;

            .content {
                .copy {
                    @include fontSize(31px);
                    @include font-primary();
                    color: $primary_4;
                    text-align: center;
                }

                .loader {
                    bottom: -80px;
                }
            }
        }
    }
}
.callback,
.callback-success,
.callback-error,
.callback-error-try-again {
    .ngdialog.ngdialog-theme-plain .ngdialog-content .buttons button {
        width: auto;

        &.btn-primary {
            padding: 18px 20px;
            padding-top: 20px;
            padding-bottom: 16px;
        }
    }
}
.callback-success,
.callback-error,
.callback-error-try-again {
    .intro-icon {
        text-align: center;
    }
    .intro-text {
        padding-bottom: 50px;
    }
    .ngdialog.ngdialog-theme-plain .ngdialog-content .buttons button.btn-homepage {
        width: 145px;
    }
}

@media #{$sm} {
    .callback {
        .ui-datepicker-trigger {
            left: auto;
            width: 50px;
        }
    }
    .callback-success,
    .callback-error,
    .callback-error-try-again {
        .ngdialog.ngdialog-theme-plain .ngdialog-content .buttons button.btn-homepage {
            width: 235px;
        }
    }
}