/**
*
* Members Dash / General use 'teaser' CTA component
*
**/
.module-teaser-cta {
    margin: 0;

    background-color: $neutral_6;
    padding: 54px 0;
    text-align: center;

    .module-title {
        text-align: center;
        margin-top: 0;
        margin-bottom: 21px;
    }

    .desc {
        margin-bottom: 26px;
    }

    a {
        @extend %font-primary;
        @include fontSize(20px);
        @include lineHeight(20px);
        color: $primary_4;

        .icon {
            position: relative;
            top: 1px;
        }
    }
}
