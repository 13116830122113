/**
*
* Full width dual promo module - reforms phase 2, 2019
*
**/

.module-dual-promo {
    margin: 0;
    overflow: hidden;

    .bg-primary1 .tile { background-color: $primary_1}
    .bg-primary2 .tile { background-color: $primary_2}
    .bg-primary3 .tile { background-color: $primary_3}
    .bg-primary4 .tile { background-color: $primary_4}
    .bg-primary5 .tile { background-color: $primary_5}
    .bg-primary6 .tile { background-color: $primary_6}

    .left-column, .right-column {
        &.subtitle-primary1 .sub-title,
        &.title-primary1 .title {
            color: $primary_1;
        }

        &.subtitle-primary2 .sub-title,
        &.title-primary2 .title {
            color: $primary_2;
        }

        &.subtitle-primary3 .sub-title,
        &.title-primary3 .title {
            color: $primary_3;
        }

        &.subtitle-primary4 .sub-title,
        &.title-primary4 .title {
            color: $primary_4;
        }

        &.subtitle-primary5 .sub-title,
        &.title-primary5 .title {
            color: $primary_5;
        }

        &.subtitle-primary6 .sub-title,
        &.title-primary6 .title {
            color: $primary_6;
        }
    }

    .tile {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 30px 15px;
        z-index: 10;
    }
    .tile-wrap {
        display: flex;
    }

    .img-block {
        display: flex;
        align-items: center;
        flex: 1;
        max-width: 30%;

        img {
            width: 100%;
        }
    }

    .content-block {
        flex: 1;
        padding: 0 0 0 30px;

        .title {
            @include fontSize(26px);
            @include lineHeight(32px);
            margin-top: 0;
            margin-bottom: 6px;
            color: white;
            word-break: break-word;
        }

        .sub-title {
            @include fontSize(13px);
            @include lineHeight(20px);
            margin-bottom: 22px;
            color: white;
        }
    }

    .sg-btn,
    .sg-btn:visited {
        white-space: nowrap;
        color: $primary_2;
    }

    &.split-layout {
        margin: 64px $grid-gutter-width / 2;

        .tile {
            margin-bottom: $grid-gutter-width / 2;
        }
    }

    //Tablet
    @media #{$sm} {
        display: flex;
        .left-column, .right-column {
            flex: 1;
            display: flex;

            .tile {
                flex: 1;

                margin-bottom: 0;
            }
        }

        .left-column .tile {
            justify-content: flex-end;
        }
        .right-column .tile {
            justify-content: flex-start;
        }

        .img-block {
            display: flex;
            max-width: 30%;
            align-items: center;
            justify-content: center;
        }

        &.split-layout {

            .left-column {
                justify-content: flex-end;
                margin-right: $grid-gutter-width / 4;
            }
            .right-column {
                justify-content: flex-start;
                margin-left: $grid-gutter-width / 4;
            }

            .left-column, .right-column {
                .tile {
                    flex: auto;
                    justify-content: center;
                    max-width: ($container-desktop / 2) - ($grid-gutter-width / 2);

                    .tile-wrap {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    //Desktop
    @media #{$md} {
        .content-col:last-child {
            .tile-wrap {
                padding-left: 38px;
            }

            .content-block {
                padding-right: 12px;
            }
        }

        .img-block {
            max-width: 150px;
        }

        .content-block {
            padding: 0 50px;
        }
    }
}