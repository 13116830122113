/**
*
* Extras Cover comparison table
* See also:  components/comparison-tables/comparison-table.scss
*
**/
.extras-cover-table {
    /**
	*
	* Common text styles
	*
	**/
    table {
        @include fontSize(12px);

        p {
            @include fontSize(12px);
            margin: 0;
            padding: 0;
        }
    }


    p.service-name {
        @extend %font-secondary;
        @include fontSize(14px);
        margin: 0;
        padding: 0;
        color: $neutral_2;
    }

    p.service-benefits {
        @extend %font-secondary;
        @include fontSize(12px);
        margin: 0;
        padding: 0;
        color: $neutral_3;
    }
    svg.icon {
        fill: $neutral_3;
        width: 10px;
        height: 10px;

    }

    /**
	*
	* Initial table - key
	*
	**/
    .key {
        width: 100%;
        border-style: solid;
        border-color: $neutral_5;
        border-width: 0px 0px 1px 0px;

        td {
            padding: 14px 0px;
            vertical-align: middle;
            text-align: center;
            line-height: 0.8;
        }

        span {
            @include fontSize(12px);
            @extend %font-primary;
            color: $neutral_2;
            font-weight: bold;
            text-transform: uppercase;
        }
        //Column structure
        .service {
            width: 60%;
        }

        .benefit {
            width: 10%;
        }

        .blank {
            width: 30%;
        }
    }
    /**
	*
	* Core content table
	*
	**/
    .content-table {
        width: 100%;
        //Column structure
        .col-1 {
            width: 60%;
        }

        .col-2 {
            width: 10%;
        }

        .col-3 {
            background-color: $coverTableHighlight;
            width: 30%;
        }

        td {
            vertical-align: middle;
        }

        td.service-details {
            padding: 10px 20px;
            border-style: solid;
            border-color: $neutral_5;
            border-width: 0px 1px 1px 0px;
        }

        td.service-benefits {
            padding: 10px 20px;
            text-align: center;
            border-style: solid;
            border-color: $neutral_5;
            border-width: 0px 1px 1px 0px;
        }

        td.cover-types {
            padding: 10px 20px;
            text-align: center;
            border-style: solid;
            border-color: $neutral_5;
            border-width: 0px 0px 1px 0px;
        }

        tr:last-child {
            td {
                border-bottom: none !important;
            }
        }
    }
    /**
	*
	* Yearly limits table
	*
	**/
    .yearly-limits-table {
        width: 100%;
        text-align: center;

        td {
            vertical-align: middle;
        }

        p {
            @extend %font-secondary;
            color: $primary_6;
        }
    }
    /**
	*
	* Yearly limits table
	*
	**/
    .increasing-limits-table {
        width: 100%;
        text-align: center;


        td {
            vertical-align: middle;
        }

        p {
            @extend %font-secondary;
            @include fontSize(12px);
            color: $primary_6;
        }

        .col-1 {
            width: 60%;
            padding: 10px;
        }

        .col-2 {
            width: 10%;
            padding: 10px;
        }

        .col-3 {
            width: 30%;
            background-color: transparent;
            padding: 10px;
        }
    }

    .increase {
        /*
        TODO:  These background colours can result in pure white, depending on palette.
        We may need to find another way to handle These
        */
        background-color: lighten(desaturate(adjust-hue($primary_4, -0.5993), 47.0588), 58.4314);
    }
    //Desktop
    @media #{$sm} {
        svg.icon {
            width: 15px;
            height: 15px;

        }

        table {
            @include fontSize(14px);

            p {
                @include fontSize(14px);
            }
        }

        p.service-name {
            @include fontSize(16px);
        }

        p.service-benefits {
            @include fontSize(14px);
        }
        /**
		*
		* Initial table - key
		*
		**/
        .key {

            span {
                @include fontSize(14px);
            }
        }
        /**
		*
		* Yearly limits table
		*
		**/
        .yearly-limits-table {
            p {
                @include fontSize(14px);
            }
        }
    }
}
