/**
*
* Join Form - messaging block
* Full width component, primary_4 row with variable icons and text
* copy block with generic copy
*
**/

.messaging-block {
	margin-bottom: 30px;

	.header-row {
		padding: 0 15px;
		background-color: $primary_2;
		color: white;
		height: 45px;

		svg.icon {
			width: 18px;
			height: 18px;
			@include centre('false','true');
			display: block;
			float: left;
            margin-left: 5px;
			margin-right: 12px;
		}

		p {
			@extend %font-primary-light;
			@include fontSize(14px);
			@include centre('false','true');
			margin: 0;
			display: block;
			float: left;
		}
	}

	.content-row {
		@extend %font-secondary;
		padding: 15px 15px;
		background-color: $neutral_6;
		color: $neutral_2;


		p {
			@include fontSize(12px);
		}

		ul, ol {
			@include fontSize(12px);
			padding: 0 15px;
			margin: 0;

			li {
				margin-bottom: 10px;
			}
		}
	}



	//Desktop
	@media #{$sm} {
		margin-bottom: 40px;

		.header-row {
			padding: 0px 40px;
			height: 60px;

			svg.icon {
				width: 21px;
				height: 21px;
				margin-right: 18px;
				margin-left: 6px;
			}

			p {
				@include fontSize(20px);
			}
		}

		.content-row {
			padding: 20px 40px;

			p {
				@include fontSize(16px);
			}

			ul, ol {
				@include fontSize(14px);
				padding: 0 20px;
			}
		}
	}
}
