/**
*
* Form specific mixins and functions
*
**/
@mixin inputIcon($type) {
    position: absolute;
    opacity: 0;

    & ~ label {
        display: inline;
    }

    & ~ svg {
        display: inline;
    }

    @if ($type == 'radio') {
        width: 200px;
        height: 200px;

        & ~ svg.icon {
            position: absolute;
            top: 5px;
            left: 2px;

            &.icon-radial-default {
                display: block;
            }
            &.icon-radial-checked {
                display: none;
                color: $primary_2;
            }
        }

        &:checked ~ svg.icon {
            &.icon-radial-default {
                display: none;
            }
            &.icon-radial-checked {
                display: inline-block;
            }
        }
    }

    @if ($type == 'checkbox') {

        & ~ svg.icon {
            position: absolute;
            top: 5px;
            left: 2px;

            &.icon-checkbox-default {
                display: inline-block;
            }
            &.icon-checkbox-checked {
                display: none;
                color: $primary_2;
            }
        }

        &:checked ~ svg.icon {
            &.icon-checkbox-default {
                display: none;
            }
            &.icon-checkbox-checked {
                display: inline-block;
            }
        }
    }

    //Focus / active
    &:focus,
    &:active {
        outline: 0 !important;
    }

    &:focus {
        & ~ label {
            color: $primary_4;
        }
        & ~ svg.icon {
            color: $primary_4;
        }
    }

    &:disabled {
        & ~ label {
            color: $neutral_1;
        }
        & ~ svg.icon {
            color: $neutral_1;
        }
    }
}

@mixin errorState() {
    border-color: $error;
    background-color: $errorLight;
}

/**
*
* Common elements
*
**/
fieldset {
    margin: 0;
    padding: 0;
    border: none;
}

label {
    @extend %font-secondary-bold;
    @include fontSize(12px);
    display: inline-block;
    position: relative;
    padding-bottom: 10px;
    text-transform: uppercase;
    cursor: pointer;

    &.alternate {
        @extend %font-primary;
        @include fontSize(20px);
        font-weight: normal;
        margin: 0;
        padding: 0;
        color: $primary_2;
        text-transform: none;
    }
}

/**
*
* Radio + checkbox styles
*
**/
input[type='radio'] {
    @include inputIcon('radio');
}

input[type='checkbox'] {
    @include inputIcon('checkbox');
}

input[type='radio'],
input[type='checkbox'] {
    margin: 0;
    padding: 0;
    width: 15px;
    height: 15px;
    margin-top: 5px;
    z-index:2;
    
    & + label {
        padding-left: 0px;
    }
}

/**
*
* Text inputs
*
**/
input::-ms-clear {
    width: 0;
    height: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'],
textarea {
    @extend %font-secondary;
    @include fontSize(14px);
    color: $neutral_2;
    width: 100%;
    max-width: 100%;
    background-color: white;
    padding: 0 20px;
    border: 1px solid $neutral_5; //Important required to override Contour
    height: 50px;
    line-height: 50px;

    //Placeholder text TODO: This isn't right. Placeholder has to be separate.
    &::placeholder {
        @extend %font-secondary;
        font-style: italic;
        color: $neutral_3;
    }

    //Focus / active
    &:focus,
    &:active {
        outline: 0 !important;
        background-color: $neutral_6;
        border-style: solid;
        border-color: $neutral_3;
        border-width: 1px 1px 1px 1px;
    }

    //Error
    &.input-validation-error,
    &.ng-invalid-pattern {
        @include errorState();
        scroll-margin-top: 250px;
    }
}


.input-validation-error {
    input[type='text'],
    input[type='number'],
    input[type='password'],
    input[type='email'],
    textarea,
    .bootstrap-select .dropdown-toggle, 
    .bootstrap-select.btn-group .dropdown-toggle {
        border-color: $error;
        background-color: $errorLight;    
    }
}

textarea {
    @extend %font-secondary;
    @include fontSize(14px);
    color: $neutral_2;
    width: 100%;
    min-height: 200px;
    background-color: white;
    padding: 10px 20px;
    border: 1px solid $neutral_5;
    line-height: 25px;

    &.input-validation-error {
        @include errorState();
    }
}

/**
*
* Dropdown styles
*
**/
@import 'form-styles/dropdown';

@import 'form-styles/form-message';

@import 'form-styles/small-message';

/**
*
* Date Picker styles
*
**/
//Form elements
.daterange,
.datepicker,
.date {
    .input {
        position: relative;
        z-index: 50;

        &:after {
            @include icon();
            @include fontSize(23px);
            position: absolute;
            right: 10px;
            top: 12px;
            z-index: 1;
            content: "\6d";
            color: $neutral_2;
        }
    }
}

@import 'datepicker-jquery-ui'; // for Join Form
@import 'datepicker-pikaday'; // for Contour Forms

/**
*
* Form structure
*
**/
.form-section {
    padding: 15px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border: solid 2px $neutral_5;

    h1 {
        @extend %font-primary;
        @include fontSize(20px);
        margin: 0;
        padding: 0;
        color: $primary_2;
    }

    //Desktop
    @media #{$md} {
        padding: 40px;
    }
}

.field-wrap {
    @include clearfix-micro();
    padding-bottom: 30px;

    &.select{
        scroll-margin-top: 100px;
    }

    &.checkboxlist input{
        scroll-margin-top: 150px;
    }
}

.field-validation-error{
    scroll-margin-top: 250px;
}

//Radio lists
.radiobuttonlist .radiobuttonlist,
.checkboxlist .checkboxlist,
.umbraco-forms-form .checkboxlist .checkboxlist {
    label {
        @include fontSize(14px);
        font-weight: normal;
        text-transform: none;
        position: relative;
        padding-left: 20px;
        // margin-left: -20px;
        display: inline-block;
        z-index: 49;
        float: none; // Fixing issues with Umbraco Forms Compatibility
    }
    
    .item {
        position: relative;
        text-align: left;
        z-index: 48;
    }
}

div.umbraco-forms-form span.checkboxlist {  // Fixing issues with Umbraco Forms Compatibility
    padding: 0;
}

/**
*
* Validation states and elements
*
**/
//Asterix element that marks forms as mandatory
.contourIndicator {
    color: $error;
}

//Error messaging
.field-validation-error {
    @extend %font-secondary-bold;
    @include fontSize(12px);
    display: inline-block;
    margin-top: 5px;
    color: $error;
}

// Prevent overrides from breaking recapture elements.
#recaptcha_response_field {
    height: 15px !important;
    line-height: 13.3333px !important;
}

/**
*
* Contour fixes and overrides
FAIR WARNING:
These styles are deliberately in place to override some of the contour default styling
There are importants. It isn't pretty. I have no choice.
*
**/
.umbraco-forms-field {
    @include clearfix-micro();
    padding-bottom: 30px;
}

/**
*
* Umbraco Form
*
**/
input[type='submit'].umbraco-forms-hidden,
.umbraco-forms-hidden {
    display: none
}
.umbraco-forms-form {
    .hiddenfield {
        display: none
    }
    
    
    legend {
        @extend %font-primary;
        @include fontSize(24px);
        @include lineHeight(34px); 
        padding-bottom: 20px;
    }
    div.umbraco-forms-container {
        padding-left: 0;
        padding-right: 0;
    }

    .umbraco-forms-field.recaptcha.recaptcha3 {
        padding-bottom: 0;
    }
    
    .text-intro {
        h1, h2, h3, h4, h5 {
            color: $neutral_2;
            margin-top: 0;
        }
    }
    
    .umbraco-forms-navigation div.col-md-12 {
        padding-left: 0;
        padding-right: 0;
    }

    @media #{$md} {
        .umbraco-forms-fieldset{
            .row-fluid{
                > .col-md-6:first-child{
                    padding-right: 10px;
                }
    
                > .col-md-6:last-child{
                    padding-left: 10px;
                }
            }
        }
    }
    
}


