.content-tile {
    width: 100%;
    margin-bottom: 40px;

    .media-block {
        width: 100%;
    }

    /*
    With image
     */
    .img-block {
        margin-bottom: 15px;
    }

    picture,
    img {
        display: block;
        width: 100%;
    }

    /*
    With icon
     */
    .icon-block {
        margin-bottom: 20px;
    }

    .icon {
        width: 70px;
        height: 70px;
    }

    .tile-title {
        @include fontSize(20px);
        @include lineHeight(26px);
        margin-bottom: 14px;
        font-family: $fontPrimary;
        color: $primary_2;
    }

    .tile-desc {
        @include fontSize(14px);
        @include lineHeight(19px);
        margin-bottom: 0;
        color: #374A59;
    }

    .sg-btn-text {
        display: inline-block;
        margin-top: 14px;
    }


    //Tablet
    @media #{$sm} {
        &.grid-layout-columns-3,
        &.grid-layout-columns-4 {
            img {
                height: 170px;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    //Desktop
    @media #{$md} {

        .tile-title {
            @include fontSize(26px);
            @include lineHeight(32px);
        }

        &.grid-layout-columns-3 {
            img {
                height: auto;
            }
        }

        &.grid-layout-columns-4 {
            img {
                height: 170px;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}