/**
*
* Members dashboard
*
**/
.module-members-dash {
    margin: 0;
    $tileHoverColour: $primary_2;
    padding: 20px 0 54px 0;

    h1 {
        @include fontSize(35px);
        @include lineHeight(47px);
        margin-bottom: 27px;
        margin-top: 0;
    }
    //Common tile structure
    .tile {
        background-color: #FFFFFF;
        border: 1px solid $neutral_6;
        border-radius: 5px;


        a:not(.sg-btn) {
            @include animate-custom(#{'color, border'}, $defaultAnimationTime);
            display: flex;
            width: 100%;
            height: 100%;
            text-decoration: underline;
        }


        p {
            @include fontSize(14px);
            @include lineHeight(21px);
            margin: 0;
            color: inherit;
            font-weight: 700;
        }

        .txt-col {
            padding: 0 9px;
        }
    }
    // Remove the margin from the last row tiles.
    .members-dash-row {
        &:last-child {
            .tile {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }


    .left-column {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .tile {
            width: 48%;
        }
    }

    .middle-column {
        display: flex;
        flex-direction: column;
        margin-bottom: 28px;
    }

    .tile-small {
        @include aspect-ratio(155, 130);
        height: 130px;
        margin-bottom: 28px;
    }

    .tile-large {
        @include aspect-ratio(284, 269);
        margin-bottom: 28px;
        overflow: hidden;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        opacity: 0.8;
    }

    .text {
        position: absolute;
        bottom: 32px;
        color: white;
        @include fontSize(14px);
        @include lineHeight(19px);
        font-weight: 600;
        padding: 0 20px;
    }
    /*
    Individual tile types
     */
    .tile.txt-icon {
        box-shadow: 0px 3px 5px 0px $neutral_5;

        a {
            color: $primary_4;
            padding: 0 20px;

            &:hover {
                color: $tileHoverColour;
            }
        }

        .flex-wrap {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        .icon-col {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 20px;
        }

        .txt-col {
            display: flex;
            flex: 1;
            justify-content: left;
            align-items: center;
        }
        
        .arr-col {
            display: flex;
            justify-content: left;
            align-items: center;


            .icon {
                width: 14px;
                height: 10px;
            }
        }

        .icon {
            width: 50px;
            height: 50px;
        }
    }



    .tile.txt-img-sm {
        a {
            color: $primary_2;

            &:hover {
                color: $tileHoverColour;
            }
        }

        .img-col {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            img {
                max-width: 120px;
                height: 100%;
            }
        }

        .txt-col {
            display: flex;
            flex: 1;
            justify-content: left;
            align-items: center;
            padding: 0 16px;
        }
    }

    .tile.txt-img-lg {

        a {
            flex-direction: column;
            color: $primary_2;
            text-decoration: none;
            

            &:hover {
                .txt-row {
                    color: $tileHoverColour;
                }
            }
        }

        .img-row {
            width: 100%;
            position: relative;

            img {
                width: 100%;
            }
        }

        .txt-row {
            display: flex;
            flex: 1;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px;
            background: $neutral_5;
        }

        p {
            @include animate-custom(#{'color'}, $defaultAnimationTime);
        }
    }

    .tile.large-promo-tile {
        background: $primary_4;
        color: white;
        text-align: center;

        .content {
            padding: 0 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        p {
            @include fontSize(23px);
            @include lineHeight(30px);
            margin-bottom: 36px;
            width: 100%;
        }

        a {
            display: inline-block;
            width: auto;
            background: white;
            border: none;

            &:hover {
                background-color: $neutral_4;
            }
        }
    }

    .tile.tile-small.txt-icon {
        .icon-col, .txt-col {
            // flex: none;
        }

        .flex-wrap {
            justify-content: space-evenly;
        }
    }

    .tile.tile-large.txt-icon {
        @include aspect-ratio(335, 100);
    }
    //768px +
    @media #{$sm} {
        padding: 30px 0 54px 0;

        .tile {
            p {
                @include fontSize(16px);
            }

            .txt-col {
                padding: 0;
            }
        }

        .tile-small {
            max-height: none;
        }


        .left-column {
            .tile {
                width: 100%;
            }
        }

        .tile.txt-icon {
            .flex-wrap {
                flex-direction: row;
            }
        }

        .tile.tile-large.txt-icon {
            @include aspect-ratio(284, 269);
        }

        .middle-column{
            .tile .txt-col p{
                max-width: 170px;
            }
        }
    }
}
