
/**
*
* Health Hub - Intro section, text only
*
**/
.intro-section {
    text-align: center;
    margin: 33px 0 40px 0;

    h2 {
        @include fontSize(45px);
        @include lineHeight(46px);
        margin-top: 0;
        margin-bottom: 24px;
    }

    //Desktop
    @media #{$md} {
        margin: 45px 0 60px 0;

        h2 {
            @include lineHeight(60px);

            margin-bottom: 11px;
        }
    }
}
