.module-image {
  img {
    width: 100%;
    height: auto;
  }

  .caption {
    @include fontSize(12px);
    @include lineHeight(16px);   
    // color: $neutral_3;
    @include fontSize(12px);
    @include lineHeight(16px);
    color: $neutral_1;
    padding-top: 16px;
  }

  .image-block {
    position: relative;
    &::after {
      content: "";
      background: #000000;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    /*
        CMS controlled darkening options
    */
    $darkenSteps: 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;

    @each $step in $darkenSteps {
      &.darken-#{$step} {
        &:after {
          opacity: $step / 100;
        }
      }
    }
  }  
}
