/**
*
* Category article list listing
* Note: actual child tile styles are NOT part of this file
*
**/
.category-article-list {
    margin-top: 43px;
    margin-bottom: 50px;

    .pagination {
        margin-top: 50px;
        margin-bottom: 21px;
        text-align: center;
        position: relative;

        p {
            @include fontSize(16px);
            @include lineHeight(24px);
            margin-bottom: 20px;
        }

        .pagination-process-bar {
            height: 3px;
            width: 243px;
            position: relative;
            overflow: hidden;
            margin: auto;
            background-color: $neutral_5;

            .inner {
                position: absolute;
                left: 0;
                top: 0;
                height: 2px;
                width: 0;
                background-color: $primary_4;
            }
        }
    }

    .error-state {
        display: none; //by default
        width: 100%;
        height: 100%;
        background-color: $neutral_white;
        z-index: 2;
        position: absolute;
        left: 0;
        top: 0;
        justify-content: center;
        align-items: center;

        p {
            @include fontSize(18px);
            margin: 0;
            color: $error;
        }
    }

    &.error-occurred {
        .error-state {
            display: flex;
        }
    }

    .btn-load-more {
        @extend %font-primary;
        @include animate-custom(#{'background-color, color'}, 250ms);
        @include btnReset();
        @include fontSize(16px);
        @include lineHeight(22px);
        display: block;
        margin: auto;
        padding: 9px 20px;
        color: $primary_2;
        border: 2px solid $primary_2;
        border-radius: 7px;
        text-transform: uppercase;

        &:hover {
            color: white;
            background-color: $primary_2;
        }

        &.disabled {
            opacity: 0;
            pointer-events: none;
        }
    }
    //Desktop
    @media #{$sm} {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}
